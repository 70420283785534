import { Order } from "../Order";
import React, { useRef } from "react";
import styles from "./OrderDetail.module.scss";
import { OrderStatusAffix } from "./OrderStatusAffix";

import { useParams } from "react-router-dom";
import { OrderMetaDetails } from "./OrderMetaDetails";
import { OrderItemToItemDetailMapper } from "../OrderItem/OrderItemToItemDetailMapper";
import { OrderDetailItemsRenderer } from "../OrderDetailItemsRenderer";

type PropTypes = {
  order: Order;
  disableClick?: boolean;
};

export function OrderDetail(props: PropTypes) {
  const { tableName } = useParams<{
    tableName?: string;
  }>();
  const customerPhone = props.order.virtualTable?.getCustomerPhone();

  const { current: oiToDetailItemMapper } = useRef(
    new OrderItemToItemDetailMapper(),
  );

  const detailItems = props.order.orderItems.map((oi) =>
    oiToDetailItemMapper.toItemDetail(oi),
  );

  return (
    <div className={styles.wrapper}>
      <OrderDetailItemsRenderer
        disableClick={props.disableClick}
        items={detailItems}
      />
      <div className={"p-4 pb-0"}>
        <OrderMetaDetails
          friendlyOrderId={props.order.friendlyOrderId}
          deliveryCost={
            props.order.isDelivery() ? props.order.getDeliveryCost() : undefined
          }
          deliveryDate={
            props.order.isDelivery() ? props.order.deliveryDate : undefined
          }
          address={
            props.order.isDelivery() ? props.order.deliveryAddress : undefined
          }
          total={props.order.getTotal()}
          customerNotes={props.order.customerNotes}
          tableName={props.order.isInStructure() ? tableName : undefined}
          customerName={props.order.virtualTable?.customerName}
          customerPhone={customerPhone}
          dueDate={props.order.isTakeaway() ? props.order.dueDate : undefined}
        />
      </div>

      <OrderStatusAffix order={props.order} />
    </div>
  );
}
