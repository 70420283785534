import React, { useCallback, useEffect, useState } from "react";
import { useRedirectUrlController } from "./useRedirectUrlController";
import { Button, Input } from "antd";
import { MainLayout } from "../MainLayout/MainLayout";
import { ScreenDescription } from "../ui/ScreenDescription";
import { SwippyPhoneInput } from "../ui/SwippyPhoneInput";
import {
  ConfirmationResult,
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { nanoid } from "nanoid";
import { useTriggerToast } from "../ui/useTriggerToast";
import { ErrorToast } from "../ui/ErrorToast";

function GoogleRecaptcha({
  onRecaptchaLoaded,
}: {
  onRecaptchaLoaded: (verifier: RecaptchaVerifier) => void;
}) {
  useEffect(() => {
    const auth = getAuth();
    const verifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
    });
    onRecaptchaLoaded(verifier);
    return () => {
      verifier.clear();
    };
  }, [onRecaptchaLoaded]);
  return <div id={"recaptcha-container"} />;
}

function InsertCode({
  confirmationResult,
  onError,
  onSuccess,
}: {
  confirmationResult: ConfirmationResult;
  onError: (e: Error) => void;
  onSuccess: () => void;
}) {
  const [code, setCode] = useState("");
  const [confirming, setConfirming] = useState(false);
  const { triggerValue: triggerWrongLength, trigger: triggerWrongLengthToast } =
    useTriggerToast();

  const confirm = useCallback(
    async (code: string) => {
      if (code.length < 6) return triggerWrongLengthToast();
      try {
        setConfirming(true);
        await confirmationResult.confirm(code);
        onSuccess();
      } catch (e) {
        onError(e as Error);
      } finally {
        setConfirming(false);
      }
    },
    [confirmationResult, onError, onSuccess, triggerWrongLengthToast],
  );

  useEffect(() => {
    if (code.length === 6)
      confirm(code).catch((e) => {
        throw e;
      });
  }, [code, confirm]);
  return (
    <MainLayout screenTitle={"Inserisci il codice"}>
      <div className={"p-3 flex flex-col flex-1"}>
        <ErrorToast
          trigger={triggerWrongLength}
          copy={"Il codice di verifica deve essere lungo 6 caratteri"}
          timeoutInSeconds={4}
        />
        <Input
          type={"number"}
          autoFocus
          placeholder={"Il codice che hai ricevuto"}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          className={"rounded-full"}
        />
        <Button
          loading={confirming}
          onClick={async () => {
            await confirm(code);
          }}
          id={"send-code-button"}
          className={
            "bg-swippy-orange w-full mt-2 hover:border-swippy-dark-orange hover:bg-swippy-dark-orange min-h-[50px] h-auto p-2 font-bold text-white rounded-full"
          }
        >
          Accedi
        </Button>
      </div>
    </MainLayout>
  );
}
export function AuthWithPhoneNumberScreen() {
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult>();
  const { redirect } = useRedirectUrlController();
  const [phone, setPhone] = useState("");
  const [signingIn, setSigningIn] = useState(false);
  const [gRecaptchaKey, setGRecaptchaKey] = useState(nanoid());
  const [verifier, setVerifier] = useState<RecaptchaVerifier>();
  const { trigger: triggerPhoneAuthErrorToast, triggerValue: phoneAuthError } =
    useTriggerToast();
  const signIn = useCallback(
    async (verifier: RecaptchaVerifier) => {
      console.log("signing in with phone number", phone);
      const auth = getAuth();
      try {
        setSigningIn(true);
        const result = await signInWithPhoneNumber(auth, phone, verifier);
        setConfirmationResult(result);
      } catch (e) {
        triggerPhoneAuthErrorToast();
        setGRecaptchaKey(nanoid());
        throw e;
      } finally {
        setSigningIn(false);
      }
    },
    [phone, triggerPhoneAuthErrorToast],
  );

  const onRecaptchaLoaded = useCallback((verifier: RecaptchaVerifier) => {
    setVerifier(verifier);
  }, []);

  const insertCodeOnSuccess = useCallback(() => {
    window.location.href = redirect ?? "/";
  }, [redirect]);

  const insertCodeOnError = useCallback(() => {
    setConfirmationResult(undefined);
    triggerPhoneAuthErrorToast();
  }, [triggerPhoneAuthErrorToast]);

  if (confirmationResult)
    return (
      <InsertCode
        onSuccess={insertCodeOnSuccess}
        onError={insertCodeOnError}
        confirmationResult={confirmationResult}
      />
    );

  return (
    <MainLayout screenTitle={"Il tuo numero di cellulare"}>
      <div className={"flex-1 flex flex-col p-3"}>
        <ErrorToast
          trigger={phoneAuthError}
          copy={"Ops! Qualcosa è andato storto. Ti chiediamo di riprovare 😔"}
          timeoutInSeconds={4}
        />
        <ScreenDescription
          description={"Lo usiamo per contattarti in caso di necessità"}
        />
        <div className={"mt-2 mb-2"}>
          <SwippyPhoneInput
            autoFocus
            placeholder={"Il tuo numero"}
            onChange={(v) => setPhone(v)}
            value={phone}
          />
        </div>
        <GoogleRecaptcha
          key={gRecaptchaKey}
          onRecaptchaLoaded={onRecaptchaLoaded}
        />
        <Button
          loading={signingIn}
          onClick={async () => {
            if (!verifier) throw new Error("verifier not set");
            await signIn(verifier);
          }}
          id={"send-code-button"}
          className={
            "bg-swippy-orange w-full mt-2 min-h-[50px] hover:border-swippy-dark-orange hover:bg-swippy-dark-orange h-auto p-2 font-bold text-white rounded-full"
          }
        >
          Invia codice
        </Button>
      </div>
    </MainLayout>
  );
}
