import { preserveInPlaceQueryParamIfSet } from "../RistopubMenu/preserveInPlaceQueryParamIfSet";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "../useQueryParams/useQueryParams";

export function useToOrderDetail() {
  const history = useHistory();
  const query = useQueryParams();

  function toOrderDetail(
    cartId: string,
    ristopubId: string,
    replace: boolean = false,
    fromOrdersList: boolean = false,
  ) {
    const path = preserveInPlaceQueryParamIfSet(
      `/ristopub/${ristopubId}/order/${cartId}${
        fromOrdersList ? "?fromOrdersList=1" : ""
      }`,
      query,
    );
    if (replace) history.replace(path);
    else history.push(path);
  }

  return {
    toOrderDetail,
  };
}
