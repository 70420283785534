import React, { PropsWithChildren } from "react";
import { MobileTouchFeedbackWrapper } from "./MobileTouchFeedbackWrapper/MobileTouchFeedbackWrapper";

type Props = PropsWithChildren<{
  onClick?: () => void;
  className?: string;
}>;
export function OutlinedButton(props: Props) {
  return (
    <MobileTouchFeedbackWrapper className={"w-full flex"}>
      {({ touchHover }) => {
        return (
          <button
            style={{
              minHeight: "50px",
            }}
            className={[
              "px-6 py-2 w-full flex items-center justify-center bg-white font-bold border-2 text-sm border-swippy-orange rounded-full  cursor-pointer",
              props.className ?? "",
              touchHover ? "text-white bg-swippy-orange" : "text-swippy-orange",
            ].join(" ")}
            onClick={props.onClick}
          >
            {props.children || null}
          </button>
        );
      }}
    </MobileTouchFeedbackWrapper>
  );
}
