import { OrderDetailItem } from "./OrderDetailItem";
import React from "react";
import { formatPrice } from "../formatPrice/formatPrice";
import { ItemQtyControlButton } from "../RistopubMenu/ProductsList/ItemQtyControlButton";
import { MinusOutlined } from "@ant-design/icons";
import { DeleteIcon } from "../RistopubMenu/ProductsList/DeleteIcon";
import { MobileTouchFeedbackWrapper } from "../ui/MobileTouchFeedbackWrapper/MobileTouchFeedbackWrapper";

type Props = {
  items: OrderDetailItem[];
  showModifyQtyButtons?: boolean;
  onDecreaseQtyClicked?: (cartItemId: string) => void;
  onIncreaseQtyClicked?: (cartItemId: string) => void;
  disableClick?: boolean;
};

export function OrderDetailItemsRenderer(props: Props) {
  return (
    <div>
      {props.items.map((i, index) => {
        return (
          <MobileTouchFeedbackWrapper
            disableClick={props.disableClick}
            key={index.toString()}
          >
            {({ touchHover }) => {
              return (
                <div
                  onClick={() => {
                    if (props.onIncreaseQtyClicked)
                      props.onIncreaseQtyClicked(i.cartItemId as string);
                  }}
                  className={[
                    "border-b border-gray-100 p-3",
                    touchHover ? "bg-gray-200" : "",
                  ].join(" ")}
                >
                  <div className={"flex items-center justify-between"}>
                    <div className={""}>
                      <div className={"flex items-center justify-between"}>
                        <h3 className={"font-bold mb-0 mr-2"}>
                          <span className={"text-swippy-orange mr-1"}>
                            x{i.itemQty}
                          </span>
                          <span>{i.itemName}</span>
                        </h3>
                      </div>

                      <div className={"flex flex-col text-gray-600 text-sm"}>
                        {i.getChoicesList().map((c, index) => {
                          return (
                            <span key={index.toString()}>
                              {c.choiceQty > 1 ? (
                                <span className={"mr-1"}>x{c.choiceQty}</span>
                              ) : null}
                              <span>{c.choiceName}</span>
                            </span>
                          );
                        })}
                      </div>
                      <p className={"mt-2 mb-0"}>
                        <span className={"text-gray-600"}>
                          {formatPrice(i.getTotal())} €
                        </span>
                      </p>
                    </div>
                    {props.showModifyQtyButtons ? (
                      <div className={"flex items-center justify-between ml-2"}>
                        <ItemQtyControlButton
                          stopPropagation
                          sizeClasses={"h-10 w-10"}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (props.onDecreaseQtyClicked)
                              props.onDecreaseQtyClicked(
                                i.cartItemId as string,
                              );
                          }}
                        >
                          <MinusOutlined />
                        </ItemQtyControlButton>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            }}
          </MobileTouchFeedbackWrapper>
        );
      })}
    </div>
  );
}
