import React from "react";
import styles from "./OrderButtonItemQtyBadge.module.scss";

type PropTypes = {
  qty: number;
};

export function OrderButtonItemQtyBadge(props: PropTypes) {
  const qty = props.qty;

  return (
    <div
      style={{
        visibility: qty <= 0 ? "hidden" : "visible",
      }}
      className={styles.wrapper}
    >
      <span className={styles.qty}>{qty}</span>
    </div>
  );
}
