import { RistopubDataMapper } from "../Ristopub/RistopubDataMapper";
import { VirtualTableDTO } from "./VirtualTableDTO";
import { VirtualTable } from "./VirtualTable";

export class VirtualTableDataMapper {
  constructor(private ristopubDataMapper: RistopubDataMapper) {}

  toEntity(d: VirtualTableDTO): VirtualTable {
    return VirtualTable.create({
      id: d.id,
      ristopub: d.ristopub
        ? this.ristopubDataMapper.toEntity(d.ristopub)
        : undefined,
      customerName: d.customerName,
      customerPhone: d.customerPhone,
    });
  }
}
