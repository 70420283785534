import PlacesAutocomplete from "react-places-autocomplete";
import React, { useRef, useState } from "react";
import styles from "./AddressAutocompleteInput.module.scss";

import { Spin } from "antd";
import { AddressStringToAddressConverter } from "./AddressStringToAddressConverter";
import { Address } from "../Cart/classes/Address/Address";
import { LoadingIcon } from "../ui/LoadingIcong";
import { Warning } from "../ui/Warning";

type PropTypes = {
  placeHolder?: string;
  selectedAddress?: Address;
  onChange?: (newAddress?: Address) => void;
  loading?: boolean;
};

export function AddressAutocompleteInput(props: PropTypes) {
  const [loading, setLoading] = useState(false);

  const [searchedAddress, setSearchedAddress] = useState<string | undefined>(
    props.selectedAddress?.toString(),
  );
  const { current: addressConverter } = useRef(
    new AddressStringToAddressConverter(),
  );

  function onChangedAddress(address: string | undefined) {
    setSearchedAddress(address);
  }

  async function onSelect(
    address: string,
    //placeId: string
  ) {
    setLoading(true);
    setSearchedAddress(address);
    try {
      const addressObj = await addressConverter.getAddress(address);
      if (props.onChange) props.onChange(addressObj);
    } finally {
      setLoading(false);
    }
  }

  function missingCity() {
    return !props.selectedAddress?.hasCity();
  }
  function missingProvince() {
    return !props.selectedAddress?.hasProvince();
  }
  function missingPostalCode() {
    return !props.selectedAddress?.hasPostalCode();
  }
  function missingCountry() {
    return !props.selectedAddress?.hasCountry();
  }
  function missingAddress() {
    return !props.selectedAddress?.hasAddress();
  }
  function missingStreetNumber() {
    return !props.selectedAddress?.hasStreetNumber();
  }

  function missingSomeAddressInfo() {
    const address = props.selectedAddress;
    if (!address || address.isEmpty()) return false;

    if (!address.hasCountry()) return true;
    return (
      missingAddress() ||
      missingCity() ||
      missingCountry() ||
      missingPostalCode() ||
      missingProvince() ||
      missingStreetNumber()
    );
  }

  //console.log("selAddres")

  const missingInfos: string[] = [];
  if (missingProvince()) {
    missingInfos.push("Provincia");
  }
  if (missingCity()) {
    missingInfos.push("Città");
  }
  if (missingCountry()) {
    missingInfos.push("Paese");
  }
  if (missingAddress()) {
    missingInfos.push("Via");
  }
  if (missingStreetNumber()) {
    missingInfos.push("Numero civico");
  }
  if (missingPostalCode()) {
    missingInfos.push("Codice postale");
  }

  return (
    <Spin spinning={props.loading || loading} indicator={<LoadingIcon />}>
      <PlacesAutocomplete
        value={searchedAddress}
        onChange={onChangedAddress}
        onSelect={onSelect}
        debounce={500}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <div className={styles.searchInputWrapper}>
              <input
                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                value={searchedAddress}
                {...getInputProps({
                  placeholder: props.placeHolder,
                  className: [
                    styles.searchInput,
                    "rounded-full border-gray-300 border-2 px-2 hover:border-swippy-orange focus:border-swippy-orange",
                  ].join(" "),
                })}
              />
            </div>

            {(suggestions && suggestions.length) || loading ? (
              <div className={styles.dropdownWrapper}>
                {loading ? (
                  <div className={styles.loadingWrapper}>
                    <Spin indicator={<LoadingIcon />} />
                  </div>
                ) : null}

                {!loading
                  ? suggestions.map((suggestion, index) => {
                      const className = suggestion.active
                        ? [styles.suggestion, styles.suggestionActive].join(" ")
                        : [styles.suggestion].join(" ");
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          key={index.toString()}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })
                  : null}
              </div>
            ) : null}

            {missingSomeAddressInfo() ? (
              <div className={"mt-2"}>
                <Warning
                  title={"Indirizzo non completo"}
                  copy={
                    <span>
                      Il tuo indirizzo deve contenere anche{" "}
                      {missingInfos.join(", ")}
                    </span>
                  }
                />
              </div>
            ) : // <div className={styles.missingInfoWrapper}>
            //     <p className={styles.missiInfoTitle}>
            //         L'indirizzo da te fornito non contiene le seguenti informazioni:<br/>
            //     </p>
            //
            //     <ul className={styles.missingInfoList}>
            //         {missingProvince() || true ? <li>Provincia</li> : null}
            //         {missingCity() || true ? <li>Città</li> : null}
            //         {missingCountry() || true ? <li>Paese</li> : null}
            //         {missingAddress() || true ? <li>Via</li> : null}
            //         {missingStreetNumber() || true ? <li>Numero civico</li> : null}
            //         {missingPostalCode() || true ? <li>Codice postale</li> : null}
            //     </ul>
            // </div>

            null}
          </div>
        )}
      </PlacesAutocomplete>
    </Spin>
  );
}
