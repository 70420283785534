import { RistopubAvailabilityDTO } from "../RistopubDTO";
import { RistopubAvailability } from "./RistopubAvailability";
import { Time } from "../Time";
import { Moment } from "moment-timezone";

export class AvailabilityDataMapper {
  toEntity(d: RistopubAvailabilityDTO, date: Moment): RistopubAvailability {
    const startTime = Time.fromUtcTimeInLocalTime(d.start.time, date);
    const endTime = Time.fromUtcTimeInLocalTime(d.end.time, date);

    return new RistopubAvailability(startTime, endTime);
  }
}
