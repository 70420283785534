import { ProductModifier } from "../Product/ProductModifier";
import ReactDOM from "react-dom";
import React, { useEffect, useMemo, useState } from "react";

import { ItemQtyControlButton } from "./ItemQtyControlButton";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { formatPrice } from "../../formatPrice/formatPrice";
import { SwippyButton } from "../../ui/SwippyButton/SwippyButton";
import { CartItem } from "../../Cart/classes/CartItem";
import { Product } from "../Product/Product";
import { nanoid } from "nanoid";
import { CheckIconSolid } from "../../ui/CheckIconSolid";
import { ProductModifierChoice } from "../Product/ProductModifierChoice";
import { useCurrentCart } from "../../Cart/useCurrentCart";
import _ from "lodash";
import { CloseIcon } from "../../ui/CloseIcon";
import { MobileTouchFeedbackWrapper } from "../../ui/MobileTouchFeedbackWrapper/MobileTouchFeedbackWrapper";
import { ErrorToast } from "../../ui/ErrorToast";
import { ProductModifierType } from "../Product/ProductModifierType";
import { DeleteIcon } from "./DeleteIcon";
import { ErrorState } from "../../ErrorState";

class ModifierRefsMap {
  private refMap: Record<string, React.RefObject<HTMLDivElement>> = {};

  public setRef(modifierId: string, ref: React.RefObject<HTMLDivElement>) {
    this.refMap[modifierId] = ref;
  }

  public get(modifierId: string): React.RefObject<HTMLDivElement> {
    return this.refMap[modifierId];
  }
}

type AddVariationModalComponentProps = {
  cartItem: CartItem;
  modifiers: ProductModifier[];
  onSave: (cartItem: CartItem) => void;
  onCancel?: () => void;
  visible?: boolean;
};

function AddVariationModalComponent(props: AddVariationModalComponentProps) {
  const [cartItem, setCartItem] = useState<CartItem>(props.cartItem);

  const [showMaxErrorOfMap, setShowMaxErrorOfMap] = useState<
    Record<string, boolean>
  >({});

  const [modifierRefsMap, setModifierRefsMap] = useState<ModifierRefsMap>(
    new ModifierRefsMap(),
  );
  const [requiredErrorState, setRequiredErrorState] = useState<ErrorState>(
    new ErrorState(),
  );

  const [maxOptionsToShowInError, setMaxOptionsToShowInError] =
    useState<number>(0);
  const [triggerMaxErrorToast, setTriggerMaxErrorToast] = useState(false);

  const requiredModifiers = useMemo(
    () => props.modifiers.filter((m) => !!m.required),
    [props.modifiers],
  );

  function isSelected(m: ProductModifier, c: ProductModifierChoice) {
    return cartItem.hasOption(m.id, c.id);
  }

  const modifiersLength = props.modifiers.length;

  function buildModifierRefsMap() {
    const map = _.cloneDeep(modifierRefsMap);
    props.modifiers.forEach((m) => {
      map.setRef(m.id, React.createRef<HTMLDivElement>());
    });
    setModifierRefsMap(map);
  }

  useEffect(
    buildModifierRefsMap,
    // eslint-disable-next-line
    [modifiersLength],
  );

  function increaseOptionQty(m: ProductModifier, c: ProductModifierChoice) {
    const currentQty = cartItem.getOptionQty(m.id, c.id);
    if (currentQty >= c.getMaxQty()) return;

    if (
      cartItem.getNumberOfOptionsOfModifier(m.id) >= m.getMaxSelectableOptions()
    ) {
      showMaxErrorOf(m);
      return;
    }

    const ci = _.cloneDeep(cartItem);
    ci.increaseOptionQty(m.id, c.id, m.name || "", c.name || "", c.price);
    setCartItem(ci);
  }

  function decreaseOptionQty(m: ProductModifier, c: ProductModifierChoice) {
    hideMaxErrorOf(m.id);
    const ci = _.cloneDeep(cartItem);
    ci.decreaseOptionQty(m.id, c.id);
    setCartItem(ci);
  }

  function toggleOption(m: ProductModifier, c: ProductModifierChoice) {
    const hasOption = cartItem.hasOption(m.id, c.id);
    const maxSelectableOptions = m.getMaxSelectableOptions();
    const ci = _.cloneDeep(cartItem);
    if (maxSelectableOptions === 1) {
      if (hasOption) return;
      ci.clearModifier(m.id);
      ci.toggleOption(m.id, c.id, m.name || "", c.name || "", c.price);
      setCartItem(ci);
      return;
    }

    if (
      !hasOption &&
      cartItem.getNumberOfOptionsOfModifier(m.id) >= maxSelectableOptions
    ) {
      showMaxErrorOf(m);
      return;
    }

    if (hasOption) {
      hideMaxErrorOf(m.id);
    }

    ci.toggleOption(m.id, c.id, m.name || "", c.name || "", c.price);
    setCartItem(ci);
  }

  function validate(): ErrorState {
    const errorState = new ErrorState();
    _.forEach(requiredModifiers, (m) => {
      if (!hasAtLeastOneOptionOfModifier(m.id)) errorState.enableError(m.id);
    });
    return errorState;
  }

  function hasAtLeastOneOptionOfModifier(modifierId: string) {
    return cartItem.hasAtLeastOneOptionOfModifier(modifierId);
  }

  function hasToShowMaxErrorOf(modifierId: string): boolean {
    return showMaxErrorOfMap[modifierId];
  }

  function showMaxErrorOf(m: ProductModifier) {
    const map = _.cloneDeep(showMaxErrorOfMap);
    setMaxOptionsToShowInError(m.getMaxSelectableOptions());
    setTriggerMaxErrorToast(!triggerMaxErrorToast);
    map[m.id] = true;
    setShowMaxErrorOfMap(map);
  }
  function hideMaxErrorOf(modifierId: string) {
    const map = _.cloneDeep(showMaxErrorOfMap);
    map[modifierId] = false;
    setShowMaxErrorOfMap(map);
  }

  function decreaseCartItemQty() {
    const ci = _.cloneDeep(cartItem);
    if (ci.getQty() <= 1) return;
    ci.decreaseQty();
    setCartItem(ci);
  }

  function increaseCartItemQty() {
    const ci = _.cloneDeep(cartItem);
    ci.increaseQty();
    setCartItem(ci);
  }

  function scrollIntoModifier(modifierId?: string) {
    if (!modifierId) {
      return console.error(`Missing modifierId`);
    }

    const modifierRef = modifierRefsMap.get(modifierId);
    if (!modifierRef || !modifierRef.current) {
      return console.error(`Missing modifier ref of ${modifierId}`);
    }

    modifierRef.current.scrollIntoView();
  }

  function confirmItem() {
    const errorState = validate();
    if (errorState.hasSomeError()) {
      setRequiredErrorState(errorState);
      const firstErroredModifier = errorState.getFirstErroredKey();
      scrollIntoModifier(firstErroredModifier);
      return;
    }

    props.onSave(cartItem);
  }

  function clearRequiredErrorIfNeeded(modifierId: string) {
    const newState = _.cloneDeep(requiredErrorState);
    newState.clearError(modifierId);
    setRequiredErrorState(newState);
  }

  return props.visible
    ? ReactDOM.createPortal(
        <div
          className={
            "bg-black overflow-y-scroll max-w-md ml-auto mr-auto fixed z-40 inset-0 bg-opacity-70 pt-20 "
          }
        >
          <div
            className={
              "bg-swippy-gray pb-48 overflow-hidden min-h-full h-auto rounded-t-3xl w-full  flex flex-col"
            }
          >
            <ErrorToast
              trigger={triggerMaxErrorToast}
              copy={`Puoi selezionare massimo ${maxOptionsToShowInError} opzioni`}
              timeoutInSeconds={3}
            />
            <div
              className={
                "flex justify-between p-3 border-b border-gray-100 mb-4 bg-white rounded-t-2xl"
              }
            >
              <h3 className={"font-bold mb-0 text-xl"}>
                {props.cartItem.getProduct().name}
              </h3>
              <CloseIcon
                onClick={props.onCancel}
                className={"cursor-pointer w-4 h-4 text-gray-400"}
              />
            </div>
            <div>
              {props.modifiers.map((m, index) => {
                return (
                  <div
                    ref={modifierRefsMap.get(m.id)}
                    className={
                      index < props.modifiers.length - 1 ? "mb-6" : undefined
                    }
                    key={m.id}
                  >
                    <div className={"px-6"}>
                      <h4 className={"font-bold mb-0 text-lg "}>
                        {m.name}
                        {m.hasMax() ? (
                          <span
                            className={
                              hasToShowMaxErrorOf(m.id)
                                ? "text-red-600"
                                : undefined
                            }
                          >
                            {" "}
                            (massimo {m.maxSelectableChoices})
                          </span>
                        ) : null}
                      </h4>
                      {requiredErrorState.hasError(m.id) ? (
                        <span className={"font-bold text-red-600 block"}>
                          Scegli almeno un'opzione per {m.name}
                        </span>
                      ) : null}
                      {!m.required ? (
                        <span className={"text-gray-600 text-md"}>
                          Facoltativo
                        </span>
                      ) : null}
                      {m.description ? (
                        <p className={"mb-0 text-md"}>{m.description}</p>
                      ) : null}
                    </div>

                    <div className={"mt-3"}>
                      {m.choices.map((c, index) => {
                        const isSelectedChoice = isSelected(m, c);
                        const choiceQty = cartItem.getOptionQty(m.id, c.id);

                        return (
                          <MobileTouchFeedbackWrapper key={c.id}>
                            {({ touchHover }) => {
                              return (
                                <div
                                  className={[
                                    "flex relative border-b border-gray-100 bg-white",
                                    index === 0 ? "border-t" : "",
                                  ].join(" ")}
                                >
                                  {isSelectedChoice ? (
                                    <div
                                      className={
                                        "w-1.5 absolute top-0 left-0 bottom-0 bg-swippy-orange h-full"
                                      }
                                    />
                                  ) : null}
                                  <div
                                    style={{
                                      minHeight: 60,
                                    }}
                                    className={[
                                      "py-2 px-8 flex-1  flex justify-between items-center cursor-pointer",
                                      touchHover ? "bg-gray-200" : "",
                                    ].join(" ")}
                                    onClick={() => {
                                      clearRequiredErrorIfNeeded(m.id);
                                      switch (m.type) {
                                        case ProductModifierType.MULTIPLE_CHOICE:
                                          toggleOption(m, c);
                                          break;
                                        case ProductModifierType.MULTIPLE_CHOICE_WITH_QTY:
                                          increaseOptionQty(m, c);
                                          break;
                                      }
                                    }}
                                  >
                                    <div>
                                      <h4 className={"mb-0 text-md"}>
                                        {m.type ===
                                          ProductModifierType.MULTIPLE_CHOICE_WITH_QTY &&
                                        choiceQty > 0 ? (
                                          <span
                                            className={
                                              "text-swippy-orange font-bold mr-1"
                                            }
                                          >
                                            x{choiceQty}
                                          </span>
                                        ) : null}
                                        <span>{c.name}</span>
                                      </h4>
                                      {c.price ? (
                                        <p className={"mb-0 mt-1 text-md"}>
                                          +{formatPrice(c.price)} €
                                        </p>
                                      ) : (
                                        <p
                                          className={
                                            "mb-0 text-md text-green-600"
                                          }
                                        />
                                      )}
                                    </div>
                                    {isSelectedChoice &&
                                    m.type ===
                                      ProductModifierType.MULTIPLE_CHOICE ? (
                                      <CheckIconSolid
                                        className={"w-6 h-6 text-swippy-orange"}
                                      />
                                    ) : null}

                                    {m.type ===
                                      ProductModifierType.MULTIPLE_CHOICE_WITH_QTY &&
                                    choiceQty > 0 ? (
                                      <DeleteIcon
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                          decreaseOptionQty(m, c);
                                        }}
                                        className={
                                          "text-red-600 hover:text-red-800 w-6 h-6"
                                        }
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              );
                            }}
                          </MobileTouchFeedbackWrapper>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>

            <div
              className={
                "fixed max-w-md ml-auto mr-auto bottom-0 right-0 left-0 bg-white pb-2"
              }
            >
              <div
                className={
                  "flex px-3 py-2 border-t border-b border-gray-100 bg-white flex-row justify-between items-center"
                }
              >
                <ItemQtyControlButton
                  onClick={() => {
                    decreaseCartItemQty();
                  }}
                >
                  <MinusOutlined />
                </ItemQtyControlButton>
                <span className={"text-swippy-orange text-xl font-bold"}>
                  x{cartItem.getQty()}
                </span>
                <ItemQtyControlButton
                  onClick={() => {
                    increaseCartItemQty();
                  }}
                >
                  <PlusOutlined />
                </ItemQtyControlButton>
              </div>

              <div className={"flex  justify-center mt-2 px-3"}>
                <SwippyButton
                  onClick={confirmItem}
                  // disabled={!hasAllRequiredModifiers}
                  className={[
                    "text-center  w-full h-12 flex items-center font-bold justify-center",
                    // hasAllRequiredModifiers ? "shadow-xl" : ""
                  ].join(" ")}
                  type={"primary"}
                >
                  Aggiungi al carrello
                </SwippyButton>
              </div>
            </div>
          </div>
        </div>,
        document.body,
      )
    : null;
}

type Props = {
  product: Product;
  qty: number;
  modifiers: ProductModifier[];
  visible?: boolean;
  onCancel?: () => void;
};
export function AddNewVariationModal(props: Props) {
  const { addToCartByCartItem } = useCurrentCart();

  function onSave(ci: CartItem) {
    addToCartByCartItem(ci);
    if (props.onCancel) props.onCancel();
  }

  return (
    <AddVariationModalComponent
      key={props.visible ? 1 : 2}
      visible={props.visible}
      cartItem={CartItem.create({
        id: nanoid(),
        product: props.product,
        qty: 1,
      })}
      modifiers={props.modifiers}
      onSave={onSave}
      onCancel={props.onCancel}
    />
  );
}
