import React from "react";
import styles from "./SwippyLogotype.module.scss";
import { LogotypeIcon } from "./LogotypeIcon";

export function SwippyLogotype() {
  return (
    <div className={styles.logotypeWrapper}>
      <LogotypeIcon className={"h-8"} />
    </div>
  );
}
