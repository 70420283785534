import { initializeApp } from "firebase/app";

function initFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyAGMeSTAjOJCwyAYj4lQ9Tlk2NW-Ui1JaA",
    authDomain: "swippy-food.firebaseapp.com",
    databaseURL: "https://swippy-food.firebaseio.com",
    projectId: "swippy-food",
    storageBucket: "swippy-food.appspot.com",
    messagingSenderId: "837505791992",
    appId: "1:837505791992:web:fa1242e3a457b2e0a0080d",
  };

  initializeApp(firebaseConfig);
}

export { initFirebase };
