import React from "react";
import { Order } from "../Order";

export type OrderLastSeenCtxType = {
  updateLastSeenOf: (order: Order) => void;
  isChangedSinceLastSeen: (order: Order) => boolean;
};

const OrderLastSeenCtx = React.createContext<OrderLastSeenCtxType>({
  updateLastSeenOf: () => {},
  isChangedSinceLastSeen: () => false,
});

export { OrderLastSeenCtx };
