import { useParams } from "react-router-dom";

export function useCurrentTableName() {
  const { tableName } = useParams<{
    tableName: string;
  }>();

  return {
    tableName,
  };
}
