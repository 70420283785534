import { useHistory } from "react-router-dom";

export function useToInStructureSelfPickupSummaryScreen() {
  const history = useHistory();

  function toInStructureSelfPickupSummaryScreen(
    ristopubId: string,
    qrCodeName: string,
  ) {
    history.push(`/ristopub/${ristopubId}/table/${qrCodeName}/selfsummary`);
  }

  return {
    toInStructureSelfPickupSummaryScreen,
  };
}
