import React from "react";
import { SuccessIcon } from "./SuccessIcon";

type PropTypes = {
  title: string;
  copy?: React.ReactNode;
};
export function Success(props: PropTypes) {
  return (
    <div
      className={[
        "flex rounded flex-row bg-green-100 p-3",
        !props.copy ? "items-center" : "",
      ].join(" ")}
    >
      <div
        className={
          "rounded-full text-green-600 bg-green-200 mb-auto p-1.5 mr-2 items-center justify-center"
        }
      >
        <SuccessIcon />
      </div>
      <div className={""}>
        <h3
          className={[
            "text-green-600 font-bold",

            !props.copy ? "m-0" : "",
          ].join(" ")}
        >
          {props.title}
        </h3>
        {props.copy ? (
          <p className={"text-green-600 m-0"}>{props.copy}</p>
        ) : null}
      </div>
    </div>
  );
}
