import { useHistory } from "react-router-dom";

export function useToTakeawayMenuScreenWithQrCode() {
  const history = useHistory();

  function toTakeawayMenuScreenWithQrCode(
    ristopubId: string,
    qrCodeId: string,
  ) {
    history.push(`/ristopub/${ristopubId}/qrcode/${qrCodeId}/takeaway/menu`);
  }

  return {
    toTakeawayMenuScreenWithQrCode,
  };
}
