import { useCallback, useState } from "react";

export function useTriggerToast() {
  const [triggerValue, setTriggerValue] = useState(false);

  const trigger = useCallback(() => {
    setTriggerValue((v) => !v);
  }, []);

  return {
    trigger,
    triggerValue,
  };
}
