import React from "react";
import { SwippyButton } from "../ui/SwippyButton/SwippyButton";

import styles from "./OrderButton.module.scss";
import { OrderButtonPrice } from "./OrderButtonPrice/OrderButtonPrice";
import { OrderButtonItemQtyBadge } from "./OrderButtonItemQtyBadge/OrderButtonItemQtyBadge";
import { Loading } from "../Loading/Loading";

type PropTypes = {
  label: string;
  onClick?: () => void;
  price: number;
  qty: number;
  disabled?: boolean;
  loading?: boolean;
  gaAction?: string;
  gaLabel?: string;
  gaCategory?: string;
  shippingCost?: number;
};

export function OrderButton(props: PropTypes) {
  const innerBtnWrapperClasses = [styles.buttonElementsWrapper, "font-bold "];
  if (props.disabled) innerBtnWrapperClasses.push(styles.disabled);
  const loading = props.loading;

  if (loading) {
    innerBtnWrapperClasses.push("flex items-center justify-center");
  }

  return (
    <SwippyButton
      gaAction={props.gaAction}
      gaCategory={props.gaCategory}
      gaLabel={props.gaLabel}
      disabled={props.disabled || loading}
      className={[
        styles.button,
        !props.disabled && !props.loading ? "shadow-xl" : "",
      ].join(" ")}
      onClick={props.onClick}
      type={"primary"}
    >
      <div className={innerBtnWrapperClasses.join(" ")}>
        {!loading ? (
          <div className={"mr-2"}>
            <OrderButtonItemQtyBadge qty={props.qty} />
          </div>
        ) : null}
        {loading ? <Loading size={20} /> : <span>{props.label}</span>}
        {!loading ? (
          <div className={"ml-2"}>
            <OrderButtonPrice
              shippingCost={props.shippingCost}
              price={props.price}
            />
          </div>
        ) : null}
      </div>
    </SwippyButton>
  );
}
