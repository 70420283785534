import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { useCurrentCart } from "../Cart/useCurrentCart";
import React, { useState } from "react";
import { MainLayout } from "../MainLayout/MainLayout";
import { EmptyCart } from "../OrderSummaryScreen/EmptyCart";
import { CantOrderForPastDeliveryDateModal } from "../Order/CantOrderForPastDeliveryDateModal";
import { OrderSummary } from "../OrderSummaryScreen/OrderSummary";
import { AddOrderNotes } from "../OrderSummaryScreen/AddOrderNotes";
import { OrderMetaDetails } from "../Order/OrderDetailScreen/OrderMetaDetails";
import { MinimumOrderAdvice } from "../Order/MinimumOrderAdvice";
import { NextStepButton } from "../OrderButton/NextStepButton/NextStepButton";
import { DeliveryFreeShippingAdvice } from "./DeliveryFreeShippingAdvice";

type Props = {
  toCheckoutScreen: () => void;
  onBack: () => void;
  toMenuScreen: () => void;
};

export function DeliveryOrderSummaryComponent(props: Props) {
  const { ristopub } = useCurrentRistopub();
  const {
    getTotalItemQty,
    getCustomerName,
    getCustomerPhone,
    getTotalPrice,
    getDeliveryAddress,
    getDeliveryCost,
    getSelectedDeliveryDate,
    getTotalItemPrice,
    getCustomerEmail,
    isRefreshingShippingCost,
    getCustomerNotes,
  } = useCurrentCart();

  const [showPastDateModal, setShowPastDateModal] = useState(false);

  function openPastDateModalIfNeeded() {
    if (ristopub?.isBefore(getSelectedDeliveryDate())) {
      setShowPastDateModal(true);
      return true;
    } else {
      return false;
    }
  }

  const totalItemQty = getTotalItemQty();
  if (totalItemQty <= 0)
    return (
      <MainLayout withHeader={true} goBack={props.toMenuScreen}>
        <EmptyCart onClick={props.toMenuScreen} />
      </MainLayout>
    );

  function toDeliveryCheckout() {
    if (openPastDateModalIfNeeded()) return;
    props.toCheckoutScreen();
  }
  const orderLessThanMinimum =
    getTotalItemPrice() < (ristopub?.minimumDeliveryOrderValue || 0);

  return (
    <>
      <CantOrderForPastDeliveryDateModal
        visible={showPastDateModal}
        onClose={() => setShowPastDateModal(false)}
      />
      <MainLayout
        screenTitle={"Riepilogo ordine"}
        goBack={props.onBack}
        withHeader={true}
      >
        <div className={"p-1"}>
          <OrderSummary />
        </div>
        <div className={["bg-white rounded-t-lg px-4 pb-20"].join(" ")}>
          <div className={"mb-2 mt-3"}>
            <AddOrderNotes />
          </div>

          <OrderMetaDetails
            customerEmail={getCustomerEmail()}
            customerNotes={getCustomerNotes()}
            refreshingShippingCost={isRefreshingShippingCost()}
            deliveryCost={getDeliveryCost()}
            address={getDeliveryAddress()}
            total={getTotalPrice()}
            customerName={getCustomerName()}
            customerPhone={getCustomerPhone()}
            deliveryDate={getSelectedDeliveryDate()}
          />

          <div
            className={
              "fixed bottom-0 left-0 right-0 px-4 py-2 text-center ml-auto mr-auto max-w-md"
            }
          >
            {orderLessThanMinimum ? (
              <div className={"mb-1 flex justify-center"}>
                <MinimumOrderAdvice
                  minimumOrderAmount={
                    ristopub?.minimumDeliveryOrderValue as number
                  }
                />
              </div>
            ) : null}

            <NextStepButton
              gaProps={{
                gaCategory: "delivery_order_flow",
                gaLabel: "delivery_order_flow_summary_to_checkout",
              }}
              disabled={orderLessThanMinimum}
              onClick={toDeliveryCheckout}
              label={"Vai alla cassa"}
            />
            <div className={"mt-1"}>
              <DeliveryFreeShippingAdvice />
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
}
