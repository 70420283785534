import React from "react";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useQrCodeId } from "../QrCode/useQrCodeId";
import { DeliveryInsertAddressComponent } from "./DeliveryInsertAddressComponent";
import { useToChooseShippingMethodWithQrCode } from "../ChooseShippingMethodScreen/useToChooseShippingMethodWithQrCode";
import { useToDeliveryMenuScreenWithQrCode } from "./useToDeliveryMenuScreenWithQrCode";

export function DeliveryInsertAddressScreenWithQrCode() {
  const { ristopubId } = useCurrentRistopubId();
  const { qrcodeId } = useQrCodeId();

  const { toChooseShippingMethodWithQrCode } =
    useToChooseShippingMethodWithQrCode();

  const { toDeliveryMenuScreenWithQrCode } =
    useToDeliveryMenuScreenWithQrCode();

  function toChooseShippingMethod() {
    toChooseShippingMethodWithQrCode(ristopubId, qrcodeId);
  }

  function toMenuScreen() {
    toDeliveryMenuScreenWithQrCode(ristopubId, qrcodeId);
  }

  return (
    <DeliveryInsertAddressComponent
      onBack={toChooseShippingMethod}
      toMenuScreen={toMenuScreen}
    />
  );
}
