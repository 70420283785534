import React from "react";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useToDeliveryInsertAddressScreen } from "./useToDeliveryInsertAddressScreen";
import { useToChooseDeliveryTimeScreen } from "./useToChooseDeliveryTimeScreen";
import { DeliveryMenuComponent } from "./DeliveryMenuComponent";

export function DeliveryMenuScreen() {
  const { toDeliveryInsertAddressScreen } = useToDeliveryInsertAddressScreen();

  const { toChooseDeliveryTimeScreen } = useToChooseDeliveryTimeScreen();

  const { ristopubId } = useCurrentRistopubId();

  function toInsertAddress() {
    toDeliveryInsertAddressScreen(ristopubId);
  }

  function toChooseTime() {
    toChooseDeliveryTimeScreen(ristopubId);
  }

  return (
    <DeliveryMenuComponent
      onBack={toInsertAddress}
      toChooseTime={toChooseTime}
    />
  );
}
