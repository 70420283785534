import { useHistory } from "react-router-dom";

export function useToTakeawayChooseTimeScreenWithQrCode() {
  const history = useHistory();

  function toTakeawayChooseTimeScreenWithQrCode(
    ristopubId: string,
    qrCodeId: string,
  ) {
    history.push(
      `/ristopub/${ristopubId}/qrcode/${qrCodeId}/takeaway/choosetime`,
    );
  }

  return {
    toTakeawayChooseTimeScreenWithQrCode,
  };
}
