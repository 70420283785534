import { useHistory } from "react-router-dom";

export function useToDeliveryMenuScreen() {
  const history = useHistory();

  function toDeliveryMenu(ristopubId: string, replace = false) {
    if (replace) {
      history.replace(`/ristopub/${ristopubId}/delivery/menu`);
    } else {
      history.push(`/ristopub/${ristopubId}/delivery/menu`);
    }
  }

  return {
    toDeliveryMenu,
  };
}
