import { gql, useQuery } from "@apollo/client";
import { GuestUserDTO } from "./GuestUserDTO";

const GET_GUEST_INFO = gql`
  query getGuestInfo {
    getGuestInfo {
      id
      phoneNumber {
        number
      }
    }
  }
`;

export function useGuestInfo({ skip }: { skip: boolean }) {
  const { data, loading } = useQuery<{
    getGuestInfo: GuestUserDTO;
  }>(GET_GUEST_INFO, {
    skip: skip,
  });

  return {
    guestInfo: data?.getGuestInfo,
    loading,
  };
}
