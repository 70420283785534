import React from "react";

import styles from "./InStructureOrderSummaryScreen.module.scss";
import { MainLayout } from "../MainLayout/MainLayout";
import { OrderSummary } from "../OrderSummaryScreen/OrderSummary";
import { AddOrderNotes } from "../OrderSummaryScreen/AddOrderNotes";

import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { useToInStructureOrderMenuScreen } from "./useToInStructureOrderMenuScreen";
import { EmptyCart } from "../OrderSummaryScreen/EmptyCart";
import { useCurrentCart } from "../Cart/useCurrentCart";
import { OrderMetaDetails } from "../Order/OrderDetailScreen/OrderMetaDetails";
import { NextStepButton } from "../OrderButton/NextStepButton/NextStepButton";
import { useToInStructureCheckoutScreen } from "./useToInStructureCheckoutScreen";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useCompleteInStructureOrderWithCashPaymentFlow } from "./useCompleteInStructureOrderWithCashPaymentFlow";
import { RistopubTopImage } from "../Ristopub/RistopubTopImage";
import { useCurrentTableName } from "./useCurrentTableName";

export function InStructureOrderSummaryScreen() {
  const { toInStructureOrderMenuScreen } = useToInStructureOrderMenuScreen();
  const { ristopub } = useCurrentRistopub();
  const { ristopubId } = useCurrentRistopubId();
  const { getTotalItemQty, getTotalPrice, getCartId } = useCurrentCart();
  const { toInStructureCheckoutScreen } = useToInStructureCheckoutScreen();
  const { tableName } = useCurrentTableName();

  const { completingOrder, completeInStructureOrderWithCashPaymentFlow } =
    useCompleteInStructureOrderWithCashPaymentFlow();

  function goBack() {
    toInStructureOrderMenuScreen(ristopubId, tableName);
  }

  function toInStructureCheckout() {
    const cartId = getCartId();
    if (!cartId) throw new Error("Missing cartid");
    toInStructureCheckoutScreen(cartId, ristopubId, tableName);
  }

  const totalItemQty = getTotalItemQty();
  if (totalItemQty <= 0)
    return (
      <MainLayout withHeader={true} goBack={goBack}>
        <EmptyCart onClick={goBack} />
      </MainLayout>
    );

  return (
    <MainLayout
      screenTitle={"Riepilogo ordine"}
      goBack={goBack}
      withHeader={true}
    >
      <div className={["pb-20"].join(" ")}>
        <div className={"p-1"}>
          <OrderSummary />
        </div>

        <div className={"flex flex-col px-4 pb-20"}>
          <div className={"mb-2 mt-3"}>
            <AddOrderNotes />
          </div>

          <OrderMetaDetails total={getTotalPrice()} tableName={tableName} />
          <div
            className={
              "fixed bottom-0 right-0 left-0 px-4 py-2 flex flex-col ml-auto mr-auto max-w-md"
            }
          >
            <NextStepButton
              onClick={toInStructureCheckout}
              gaProps={{
                gaCategory: "order_flow_in_structure",
                gaLabel: "order_flow_in_structure_to_checkout",
              }}
              label={"Vai alla cassa"}
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
