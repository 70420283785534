import { useHistory } from "react-router-dom";

export function useToInStructureSelfPickupMenuScreen() {
  const history = useHistory();

  function toInStructureSelfPickupMenuScreen(
    ristopubId: string,
    qrCodeName: string,
  ) {
    history.push(`/ristopub/${ristopubId}/table/${qrCodeName}/selfmenu`);
  }

  return {
    toInStructureSelfPickupMenuScreen,
  };
}
