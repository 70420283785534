import React from "react";
import { Order } from "../Order";

export type Last10DaysOrdersCtxType = {
  orders: Order[];
  loading: boolean;
  refetch: () => void | Promise<void>;
};

const Last10DaysCtx = React.createContext<Last10DaysOrdersCtxType>({
  orders: [],
  loading: false,
  refetch: () => {
    //void
  },
});

export { Last10DaysCtx };
