import { Product } from "../Product/Product";
import React, { useEffect, useMemo, useState } from "react";
import { formatPrice } from "../../formatPrice/formatPrice";
import { AddNewVariationModal } from "./AddNewVariationModal";
import { useCurrentCart } from "../../Cart/useCurrentCart";
import { EditVariationsModal } from "./EditVariationsModal";
import { CartItemToVariationDataMapper } from "./CartItemToVariationDataMapper";
import { MobileTouchFeedbackWrapper } from "../../ui/MobileTouchFeedbackWrapper/MobileTouchFeedbackWrapper";
import { ItemQtyControlButton } from "./ItemQtyControlButton";
import { MinusIcon } from "./MinusIcon";
import { FullScreenProductImage } from "./FullScreenProductImage";
import ReactDOM from "react-dom";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { CloseIcon } from "../../ui/CloseIcon";
import { SwippyButton } from "../../ui/SwippyButton/SwippyButton";
type AddToCartModalProps = {
  onCancel: () => void;
  visible?: boolean;
  product: Product;
};

function AddToCartModal(props: AddToCartModalProps) {
  const [qty, setQty] = useState(1);

  const { addToCart } = useCurrentCart();

  useEffect(() => {
    setQty(1);
  }, [props.visible]);

  function decreaseQty() {
    if (qty > 1) setQty(qty - 1);
  }
  function increaseQty() {
    setQty(qty + 1);
  }

  function add() {
    addToCart(props.product, qty);
    props.onCancel();
  }

  return props.visible
    ? ReactDOM.createPortal(
        <div
          className={
            "bg-black bg-opacity-70 inset-0 fixed z-40 flex items-end max-w-md ml-auto mr-auto"
          }
        >
          <div
            className={"bg-swippy-gray w-full rounded-t-3xl overflow-hidden"}
          >
            <div className={"p-3 flex items-center justify-between"}>
              <h3 className={"mb-0 font-bold text-xl"}>{props.product.name}</h3>
              <CloseIcon
                onClick={props.onCancel}
                className={"cursor-pointer w-4 h-4 text-gray-400"}
              />
            </div>
            <div
              className={
                "flex px-3 py-2 border-t border-b border-gray-100 bg-white flex-row justify-between items-center"
              }
            >
              <ItemQtyControlButton
                onClick={() => {
                  decreaseQty();
                }}
              >
                <MinusOutlined />
              </ItemQtyControlButton>
              <span className={"text-swippy-orange text-xl font-bold"}>
                x{qty}
              </span>
              <ItemQtyControlButton
                onClick={() => {
                  increaseQty();
                }}
              >
                <PlusOutlined />
              </ItemQtyControlButton>
            </div>
            <div className={"p-3"}>
              <SwippyButton
                onClick={add}
                className={[
                  "text-center  w-full h-12 flex items-center font-bold justify-center",
                ].join(" ")}
                type={"primary"}
              >
                Aggiungi al carrello
              </SwippyButton>
            </div>
          </div>
        </div>,
        document.body,
      )
    : null;
}

type NewProductItemProps = {
  product: Product;
  index: number;
};

function NewProductItem(props: NewProductItemProps) {
  const [showAddVariationModal, setShowAddVariationModal] = useState(false);
  const [showEditVariationsModal, setShowEditVariationsModal] = useState(false);
  const [imgOpened, setImgOpened] = useState(false);

  const [showAddToCartModal, setShowAddToCartModal] = useState(false);

  const { getQtyOf, getCartItemsOfProduct, addToCart, removeFromCart } =
    useCurrentCart();

  const cartItemToVariationDataMapper = useMemo(
    () => new CartItemToVariationDataMapper(),
    [],
  );

  const index = props.index;
  const imgSrc = props.product.getImageSrc();
  const p = props.product;

  function onClick() {
    if (p.hasModifiers()) {
      setShowAddVariationModal(true);
    } else {
      setShowAddToCartModal(true);
    }
  }

  function openAddToCartModal() {
    if (p.hasModifiers())
      throw new Error(`Can't add add to cart modal of product with modifiers`);

    setShowAddToCartModal(true);
  }

  function closeAddToCartModal() {
    setShowAddToCartModal(false);
  }

  const qty = getQtyOf(p);

  return (
    <>
      <AddToCartModal
        onCancel={closeAddToCartModal}
        product={props.product}
        visible={showAddToCartModal}
      />
      <AddNewVariationModal
        onCancel={() => setShowAddVariationModal(false)}
        product={props.product}
        qty={qty}
        visible={showAddVariationModal}
        modifiers={p.modifiers || []}
      />

      <EditVariationsModal
        product={props.product}
        onCancel={() => setShowEditVariationsModal(false)}
        visible={showEditVariationsModal}
        variations={getCartItemsOfProduct(props.product).map((ci) =>
          cartItemToVariationDataMapper.toVariation(ci),
        )}
      />
      <FullScreenProductImage
        imgSrc={imgSrc}
        opened={imgOpened}
        onClose={() => setImgOpened(false)}
      />
      <MobileTouchFeedbackWrapper>
        {({ touchHover }) => {
          return (
            <div
              onClick={onClick}
              className={[
                touchHover ? "bg-gray-200" : "",
                "border-b border-gray-100 flex flex-row items-stretch",
                index === 0 ? "border-t" : "",
              ].join(" ")}
            >
              {qty > 0 ? (
                <div className={"w-1 flex-shrink-0 bg-swippy-orange"} />
              ) : null}
              <div className={"flex p-4  flex-row items-stretch flex-1"}>
                {imgSrc ? (
                  <div className={"flex-shrink-0"}>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setImgOpened(true);
                      }}
                      className={
                        "bg-center mr-2 flex-shrink-0 bg-no-repeat bg-cover h-14 w-14 rounded-lg"
                      }
                      style={{
                        backgroundImage: `url("${imgSrc}")`,
                      }}
                    />
                  </div>
                ) : null}
                <div className={"flex-1"}>
                  <h3 className={"font-bold text-sm "}>
                    {qty > 0 ? (
                      <span className={"text-swippy-orange mr-1"}>x{qty}</span>
                    ) : null}
                    {p.name}
                  </h3>
                  {p.subTitle ? (
                    <h4 className={"text-sm "}>{p.subTitle}</h4>
                  ) : null}
                  {p.description ? (
                    <p className={"text-sm"}>{p.description}</p>
                  ) : null}
                  <p className={"mb-0 text-sm"}>{formatPrice(p.price)} €</p>
                </div>
                {qty > 0 && !p.hasModifiers() ? (
                  <div className={"flex flex-shrink-0 items-center ml-2"}>
                    <ItemQtyControlButton
                      stopPropagation={true}
                      onClick={(e) => {
                        e.stopPropagation();
                        removeFromCart(p, 1);
                      }}
                      sizeClasses={"h-10 w-10"}
                    >
                      <MinusIcon className={"w-5 h-5"} />
                    </ItemQtyControlButton>
                  </div>
                ) : null}
                {qty > 0 && p.hasModifiers() ? (
                  <div className={"flex flex-shrink-0 items-center ml-2"}>
                    <ItemQtyControlButton
                      stopPropagation={true}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowEditVariationsModal(true);
                      }}
                      sizeClasses={"h-10 w-10"}
                    >
                      <MinusIcon className={"w-5 h-5"} />
                    </ItemQtyControlButton>
                  </div>
                ) : null}
              </div>
            </div>
          );
        }}
      </MobileTouchFeedbackWrapper>
    </>
  );
}

type Props = {
  products: Product[];
  hideControlButtons?: boolean;
  hideDescription?: boolean;
};
export function NewProductsList(props: Props) {
  return (
    <div>
      {props.products.map((p, index) => {
        return <NewProductItem key={p.id} product={p} index={index} />;
      })}
    </div>
  );
}
