import { Loading } from "./Loading";
import React from "react";

export function ListLoading() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
      }}
    >
      <Loading />
    </div>
  );
}
