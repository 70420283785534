import React from "react";
import { DeliveryChooseTimeComponent } from "./DeliveryChooseTimeComponent";
import { useToDeliveryOrderSummaryScreenWithQrCode } from "./useToDeliveryOrderSummaryScreenWithQrCode";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useQrCodeId } from "../QrCode/useQrCodeId";
import { useToDeliveryMenuScreenWithQrCode } from "./useToDeliveryMenuScreenWithQrCode";

export function DeliveryChooseTimeScreenWithQrCode() {
  const { toDeliveryOrderSummaryScreenWithQrCode } =
    useToDeliveryOrderSummaryScreenWithQrCode();
  const { toDeliveryMenuScreenWithQrCode } =
    useToDeliveryMenuScreenWithQrCode();

  const { ristopubId } = useCurrentRistopubId();
  const { qrcodeId } = useQrCodeId();

  function toOrderSummary() {
    toDeliveryOrderSummaryScreenWithQrCode(ristopubId, qrcodeId);
  }

  function toMenuScreen() {
    toDeliveryMenuScreenWithQrCode(ristopubId, qrcodeId);
  }

  return (
    <DeliveryChooseTimeComponent
      toOrderSummary={toOrderSummary}
      onBack={toMenuScreen}
    />
  );
}
