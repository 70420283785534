import { useHistory } from "react-router-dom";
export function useToInStructureChoosePickupMethodScreen() {
  const history = useHistory();

  function toInStructurePickupMethodScreen(
    ristopubId: string,
    qrCodeName: string,
  ) {
    history.push(
      `/ristopub/${ristopubId}/table/${qrCodeName}/choosepickupmethod`,
    );
  }

  return {
    toInStructurePickupMethodScreen,
  };
}
