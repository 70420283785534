import moment, { Moment } from "moment-timezone";

type CreateTimeInput = {
  time: string;
};

export class Time {
  private readonly hours: number;
  private readonly minutes: number;
  private readonly seconds: number;

  private constructor(public time: string) {
    const { hours, minutes, seconds } = Time.getNumericTimeParts(time);
    this.hours = hours;
    this.minutes = minutes;
    this.seconds = seconds;
  }

  public formatTime() {
    return `${Time.pad(this.hours)}:${Time.pad(this.minutes)}`;
  }

  private static pad(n: number) {
    return n < 10 ? `0${n}` : n.toString();
  }
  public static create({ time }: CreateTimeInput) {
    return new Time(time);
  }

  public isAfter(time: Time) {
    const currTime = this.getMoment();

    const otherTime = time.getMoment();

    return currTime.isAfter(otherTime);
  }
  public isBefore(time: Time) {
    const currTime = this.getMoment();

    const otherTime = time.getMoment();

    return currTime.isBefore(otherTime);
  }

  public static fromUtcTimeInLocalTime(utcTime: string, date: Moment): Time {
    const parts = this.getNumericTimeParts(utcTime);
    const m = moment(date)
      .utc()
      .startOf("day")
      .hours(parts.hours)
      .minutes(parts.minutes)
      .seconds(parts.seconds);

    return new Time(moment(m).tz("Europe/Rome").format("HH:mm:ss"));
  }
  public getMoment(date: Moment = moment()) {
    return moment(date)
      .startOf("day")
      .hours(this.getHours())
      .minutes(this.getMinutes())
      .seconds(this.getSeconds());
  }

  public getHours() {
    return this.hours;
  }

  public getMinutes() {
    return this.minutes;
  }

  public getSeconds() {
    return this.seconds;
  }

  private static getNumericTimeParts(time: string) {
    const timeParts = time.split(":");
    if (timeParts.length <= 0) throw new Error("Time not valid " + time);

    const [hoursString, minutesString, secondsString] = timeParts;

    const hours = parseInt(hoursString || "0") || 0;
    const minutes = parseInt(minutesString || "0") || 0;
    const seconds = parseInt(secondsString || "0") || 0;

    return {
      hours,
      minutes,
      seconds,
    };
  }

  public toJSON() {
    return this.time;
  }
}
