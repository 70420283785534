import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { RistopubDTO } from "./RistopubDTO";
import { RistopubDataMapper } from "./RistopubDataMapper";
import { RistopubTakeawaySlotDataMapper } from "./RistopubTakeawaySlotDataMapper";

const GET_RISTOPUB_QUERY = gql`
  query getRistopub($input: GetRistopubInput!) {
    getRistopub(input: $input) {
      id
      name
      featuredImageSrc
      enableDelivery
      enableTakeaway
      ownerStripeAccountId
      cutoffInMinutes
      enableInAppPayments
      enableCashPayments
      minimumDeliveryOrderValue
      minimumTakeawayOrderValue

      minimumOrderValueForFreeShipping
      takeawaySlots {
        start {
          time
        }
        end {
          time
        }
      }

      __typename
    }
  }
`;

export function useFetchCurrentRistopub() {
  const { ristopubId } = useParams<{
    ristopubId: string;
  }>();

  const { data, loading } = useQuery<{
    getRistopub: RistopubDTO | undefined;
  }>(GET_RISTOPUB_QUERY, {
    variables: {
      input: {
        ristopubId,
      },
    },
    skip: !ristopubId,
  });

  const ristopubDataMapper = new RistopubDataMapper(
    new RistopubTakeawaySlotDataMapper(),
  );

  const ristopubDto = data?.getRistopub;
  const ristopub = ristopubDto
    ? ristopubDataMapper.toEntity(ristopubDto)
    : undefined;

  return {
    ristopub,
    loadingCurrentRistopub: loading,
  };
}
