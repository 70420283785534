import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { IApolloCredentials } from "./credentials/IApolloCredentials";
import { AnonymousCredentials } from "./credentials/AnonymousCredentials";
import { createUploadLink } from "apollo-upload-client";
import fetch from "isomorphic-fetch";

const clients: Record<string, ApolloClient<NormalizedCacheObject>> = {};

export function getApolloClientSingleton(
  credentials: IApolloCredentials = new AnonymousCredentials(),
) {
  const key = credentials.getHash();
  if (clients[key]) return clients[key];

  let link = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
    fetch,
  });
  link = credentials.injectAuth(link);

  clients[key] = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
  });

  return clients[key];
}
