import { Loading } from "./Loading";
import { MainLayout } from "../MainLayout/MainLayout";
import React from "react";

type PropTypes = {
  withHeader?: boolean;
};

export function FullPageLoading(props: PropTypes) {
  return (
    <MainLayout withHeader={props.withHeader}>
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading />
      </div>
    </MainLayout>
  );
}
