import React, { useState } from "react";
import { Layout } from "antd";
import styles from "./MainLayout.module.scss";
import { SwippyHeader } from "./SwippyHeader/SwippyHeader";
import { SwippyMenu } from "./SwippyMenu/SwippyMenu";
import { PendingOrders } from "./PendingOrders/PendingOrders";
import { useOpenedOrders } from "./PendingOrders/useOpenedOrders";

const { Content } = Layout;

type PropTypes = {
  children?: React.ReactNode;
  withHeader?: boolean;
  goBack?: () => void;
  hideBackButton?: boolean;
  headerClassName?: string;
  screenTitle?: string;
};

export function MainLayout(props: PropTypes) {
  const [menuOpened, setMenuOpened] = useState(false);

  const { openedOrders } = useOpenedOrders();
  function openMenu() {
    setMenuOpened(true);
  }

  function closeMenu() {
    setMenuOpened(false);
  }

  return (
    <Layout
      className={[styles.mainLayout, "max-w-md ml-auto mr-auto"].join(" ")}
      style={{}}
    >
      <Layout>
        <SwippyMenu onClose={closeMenu} opened={menuOpened} />

        <PendingOrders />
        {props.withHeader ? (
          <SwippyHeader
            className={props.headerClassName}
            onMenuClicked={openMenu}
            goBack={props.goBack}
            hideBackButton={props.hideBackButton}
          />
        ) : null}
        {props.screenTitle ? (
          <div
            className={"p-2 border-b border-gray-100 relative z-10 bg-white"}
          >
            <h1 className="font-bold mb-0 text-xl">{props.screenTitle}</h1>
          </div>
        ) : null}
        <Content
          style={{
            minHeight: `calc(100vh - ${openedOrders.length ? 100 : 65}px)`,
          }}
          className={styles.content}
        >
          {props.children || null}
        </Content>
      </Layout>
    </Layout>
  );
}
