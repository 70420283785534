import React from "react";
import { formatPrice } from "../../formatPrice/formatPrice";

type PropTypes = {
  price: number;
  shippingCost?: number;
};

export function OrderButtonPrice(props: PropTypes) {
  const price = props.price;

  return (
    <div className={"flex flex-col items-end"}>
      <span className={"text-white"}>{formatPrice(price)} €</span>
      {props.shippingCost ? (
        <span className={"text-white text-xs"}>
          + {formatPrice(props.shippingCost)} €
        </span>
      ) : null}
    </div>
  );
}
