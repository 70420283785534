import * as H from "history";
import { matchPath } from "react-router";

type RouteId =
  | "ristopub-menu"
  | "choose-takeaway-time"
  | "order-summary-page"
  | "insert-name"
  | "insert-phone"
  | "orders"
  | "order-detail-page"
  | "in-structure-order-menu-page"
  | "in-structure-order-insert-phone-page"
  | "in-structure-order-summary-page"
  | "in-structure-order-detail-page"
  | "in-structure-checkout-page"
  | "delivery-checkout-page"
  | "takeaway-checkout-page"
  | "home-page"
  | "choose-shipping-method"
  | "choose-delivery-time"
  | "delivery-insert-name"
  | "delivery-insert-phone"
  | "delivery-insert-address"
  | "delivery-order-summary"
  | "delivery-menu-screen"
  | "delivery-insert-email"
  | "takeaway-insert-email"
  | "choose-instructure-pickup-method"
  | "instructure-self-pickup-menu"
  | "self-pickup-summary";

export class ActiveRouteIdFactory {
  constructor(private location: H.Location) {}

  private isActiveTakeawayMenuPage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/takeaway/menu",
      exact: true,
    });
  }
  private isActiveDeliveryMenuPage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/delivery/menu",
      exact: true,
    });
  }
  private isActiveOrderDetailPage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/order/:cartId",
      exact: true,
    });
  }
  private isActiveChooseInStructurePickupMethodPage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/table/:tableName/choosepickupmethod",
      exact: true,
    });
  }

  private isActiveInStructureOrderDetailPage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/table/:tableName/order/:cartId",
      exact: true,
    });
  }

  private isActiveInStructureSelfPickupMenuPage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/table/:tableName/selfmenu",
      exact: true,
    });
  }

  private isActiveHomePage() {
    return !!matchPath(this.location.pathname, {
      path: "/",
      exact: true,
    });
  }
  private isActiveInStructureOrderMenuPage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/table/:tableName/menu",
      exact: true,
    });
  }
  private isActiveInStructureOrderInsertPhonePage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/table/:tableName/insertphone",
      exact: true,
    });
  }
  private isActiveInStructureCheckoutPage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/table/:tableName/checkout",
      exact: true,
    });
  }
  private isActiveInStructureOrderSummaryPage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/table/:tableName/ordersummary",
      exact: true,
    });
  }
  private isDeliveryInsertAddressPage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/delivery/insertaddress",
      exact: true,
    });
  }
  private isDeliveryOrderSummaryPage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/delivery/ordersummary",
      exact: true,
    });
  }

  private isActiveOrdersPage() {
    return !!matchPath(this.location.pathname, {
      path: "/orders",
      exact: true,
    });
  }

  private isActiveChooseTakeawayTimePage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/takeawaytime",
      exact: true,
    });
  }

  private isActiveOrderSummaryPage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/ordersummary",
      exact: true,
    });
  }
  private isActiveTakeawayCheckoutPage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/checkout",
      exact: true,
    });
  }
  private isInsertNamePage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/insertname",
      exact: true,
    });
  }
  private isDeliveryInsertNamePage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/delivery/insertname",
      exact: true,
    });
  }
  private isDeliveryCheckoutPage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/delivery/checkout",
      exact: true,
    });
  }
  private isDeliveryInsertPhonePage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/delivery/insertphone",
      exact: true,
    });
  }

  private isInsertPhonePage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/insertphone",
      exact: true,
    });
  }

  private isChooseDeliveryTimePage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/delivery/choosetime",
      exact: true,
    });
  }

  private isChooseShippingMethodPage() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/chooseshippingmethod",
      exact: true,
    });
  }

  private isDeliveryInsertEmail() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/delivery/insertemail",
      exact: true,
    });
  }
  private isTakeawayInsertEmail() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/takeaway/insertemail",
      exact: true,
    });
  }
  private isSelfPickupSummary() {
    return !!matchPath(this.location.pathname, {
      path: "/ristopub/:ristopubId/table/:tableName/selfsummary",
      exact: true,
    });
  }

  create(): RouteId | undefined {
    if (this.isActiveTakeawayMenuPage()) {
      return "ristopub-menu";
    } else if (this.isActiveChooseTakeawayTimePage()) {
      return "choose-takeaway-time";
    } else if (this.isActiveOrderSummaryPage()) {
      return "order-summary-page";
    } else if (this.isInsertNamePage()) {
      return "insert-name";
    } else if (this.isInsertPhonePage()) {
      return "insert-phone";
    } else if (this.isActiveOrdersPage()) {
      return "orders";
    } else if (this.isActiveOrderDetailPage()) {
      return "order-detail-page";
    } else if (this.isActiveInStructureOrderDetailPage()) {
      return "in-structure-order-detail-page";
    } else if (this.isActiveInStructureOrderMenuPage()) {
      return "in-structure-order-menu-page";
    } else if (this.isActiveInStructureOrderInsertPhonePage()) {
      return "in-structure-order-insert-phone-page";
    } else if (this.isActiveInStructureOrderSummaryPage()) {
      return "in-structure-order-summary-page";
    } else if (this.isActiveInStructureCheckoutPage()) {
      return "in-structure-checkout-page";
    } else if (this.isActiveTakeawayCheckoutPage()) {
      return "takeaway-checkout-page";
    } else if (this.isActiveHomePage()) {
      return "home-page";
    } else if (this.isChooseShippingMethodPage()) {
      return "choose-shipping-method";
    } else if (this.isChooseDeliveryTimePage()) {
      return "choose-delivery-time";
    } else if (this.isDeliveryInsertNamePage()) {
      return "delivery-insert-name";
    } else if (this.isDeliveryInsertPhonePage()) {
      return "delivery-insert-phone";
    } else if (this.isDeliveryInsertAddressPage()) {
      return "delivery-insert-address";
    } else if (this.isDeliveryOrderSummaryPage()) {
      return "delivery-order-summary";
    } else if (this.isDeliveryCheckoutPage()) {
      return "delivery-checkout-page";
    } else if (this.isActiveDeliveryMenuPage()) {
      return "delivery-menu-screen";
    } else if (this.isDeliveryInsertEmail()) {
      return "delivery-insert-email";
    } else if (this.isTakeawayInsertEmail()) {
      return "takeaway-insert-email";
    } else if (this.isActiveChooseInStructurePickupMethodPage()) {
      return "choose-instructure-pickup-method";
    } else if (this.isActiveInStructureSelfPickupMenuPage()) {
      return "instructure-self-pickup-menu";
    } else if (this.isSelfPickupSummary()) {
      return "self-pickup-summary";
    }
  }
}
