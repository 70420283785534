import { useGuestUserId } from "../OrderSummaryScreen/useGuestUserId";
import { gql, useMutation } from "@apollo/client";

const FORGET_STRIPE_PAYMENT_METHODS_MUTATION = gql`
  mutation forgetStripePaymentMethods(
    $input: ForgetStripePaymentMethodsInput!
  ) {
    forgetStripePaymentMethods(input: $input)
  }
`;

export function useForgetStripePaymentMethods() {
  const { guestUserId } = useGuestUserId();

  const [forgetStripePaymentMethodsMutation, { loading }] = useMutation(
    FORGET_STRIPE_PAYMENT_METHODS_MUTATION,
  );
  async function forgetStripePaymentMethods() {
    await forgetStripePaymentMethodsMutation({
      variables: {
        input: {
          guestUserId,
        },
      },
    });
  }

  return {
    forgetStripePaymentMethods,
    forgettingStripePaymentMethods: loading,
  };
}
