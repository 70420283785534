import { useHistory } from "react-router-dom";

export function useToDeliveryChooseTimeScreenWithQrCode() {
  const history = useHistory();

  function toDeliveryChooseTimeScreenWithQrCode(
    ristopubId: string,
    qrCodeId: string,
  ) {
    return history.push(
      `/ristopub/${ristopubId}/qrcode/${qrCodeId}/delivery/choosetime`,
    );
  }

  return {
    toDeliveryChooseTimeScreenWithQrCode,
  };
}
