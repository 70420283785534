import { AuthCtx } from "./AuthCtx";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { useGuestInfo } from "./useGuestInfo";
import { FullPageLoading } from "../Loading/FullPageLoading";

type Props = PropsWithChildren<unknown>;
export function AuthCtxProvider({ children }: Props) {
  const [initialLoading, setInitialLoading] = useState(true);
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null);
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      setFirebaseUser(user);
      setInitialLoading(false);
    });
  }, []);

  const { guestInfo, loading } = useGuestInfo({
    skip: !firebaseUser,
  });

  if (initialLoading || loading) return <FullPageLoading />;

  return (
    <AuthCtx.Provider
      value={{
        user: guestInfo,
      }}
    >
      {children ?? null}
    </AuthCtx.Provider>
  );
}
