import React, { createRef, RefObject, useEffect, useState } from "react";
import _ from "lodash";
import { Category } from "./Category/Category";
import { useCurrentCart } from "../Cart/useCurrentCart";

type Props = {
  categories: Category[];
  onClick: (c: Category) => void;
  selectedCategory?: Category;
};

export function CategoriesFilter(props: Props) {
  const { itemsInCategoryCount } = useCurrentCart();

  const [categoriesRef, setCategoriesRef] = useState<
    Record<string, RefObject<HTMLButtonElement>>
  >({});

  const catLength = props.categories.length;

  useEffect(() => {
    const newRef = _.cloneDeep(categoriesRef);
    props.categories.forEach((c) => {
      newRef[c.id] = newRef[c.id] || createRef<HTMLDivElement>();
    });
    setCategoriesRef(newRef);
  }, [catLength]);

  return (
    <div className={"flex items-center py-3 px-2 overflow-x-auto"}>
      {props.categories.map((c, index) => {
        const itemsInCat = itemsInCategoryCount(c.id);

        return (
          <button
            key={c.id}
            ref={categoriesRef[c.id]}
            onClick={() => {
              props.onClick(c);
            }}
            className={[
              props.selectedCategory?.id === c.id
                ? "bg-swippy-orange text-white font-bold"
                : "text-swippy-orange",

              "flex-shrink-0 whitespace-nowrap outline-none rounded-full text-sm py-1 px-2 cursor-pointer relative",
              index < props.categories.length - 1 ? "mr-1" : "mr-1",
            ].join(" ")}
          >
            <span className={""}>
              {itemsInCat > 0 ? (
                <span className="mr-1">(x{itemsInCat})</span>
              ) : null}
              <span>{c.name}</span>
            </span>
          </button>
        );
      })}
    </div>
  );
}
