import { gql, useQuery } from "@apollo/client";
import { RistopubDTO } from "./RistopubDTO";
import { useRef } from "react";
import { RistopubDataMapper } from "./RistopubDataMapper";
import { RistopubTakeawaySlotDataMapper } from "./RistopubTakeawaySlotDataMapper";

const GET_RISTOPUB_BY_SLUG_QUERY = gql`
  query getRistopubBySlug($input: GetRistopubBySlugInput!) {
    getRistopubBySlug(input: $input) {
      id
    }
  }
`;

export function useRistopubBySlug(slug: string) {
  const { data, loading } = useQuery<
    {
      getRistopubBySlug: RistopubDTO;
    },
    {
      input: {
        slug: string;
      };
    }
  >(GET_RISTOPUB_BY_SLUG_QUERY, {
    variables: {
      input: {
        slug,
      },
    },
  });

  const { current: dataMapper } = useRef(
    new RistopubDataMapper(new RistopubTakeawaySlotDataMapper()),
  );

  const dto = data?.getRistopubBySlug;

  return {
    ristopub: dto ? dataMapper.toEntity(dto) : undefined,
    loading,
  };
}
