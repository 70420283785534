import { OrderItem } from "./OrderItem";
import { OrderDetailItem } from "../OrderDetailItem";
import { OrderDetailItemVariation } from "../OrderDetailItemVariation";
import { OrderDetailItemVariationChoice } from "../OrderDetailItemVariationChoice";

export class OrderItemToItemDetailMapper {
  toItemDetail(oi: OrderItem): OrderDetailItem {
    return new OrderDetailItem(
      oi.product.name,
      oi.qty,
      oi.getUnitPrice(),
      oi.variations.map((v) => {
        return new OrderDetailItemVariation(
          v.modifierName,
          v.choices.map((c) => {
            return new OrderDetailItemVariationChoice(
              c.choiceName,
              c.choicePrice,
              c.choiceQty,
            );
          }),
        );
      }),
    );
  }
}
