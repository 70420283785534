import { useCurrentCart } from "./Cart/useCurrentCart";
import moment, { Moment } from "moment-timezone";
import DatePicker from "react-datepicker";
import { TimeSelector } from "./TimeSelector";
import React, { useState } from "react";
import { AvailabilityType } from "./Ristopub/Availability/AvailabilityType";
import { useClosedDates } from "./Ristopub/Availability/useClosedDates";
import { ChevronLeftIcon } from "./ui/ChevronLeftIcon";

import { ChevronRightIcon } from "./ui/ChevronRightIcon";
import _ from "lodash";
import { LoadingIcon } from "./ui/LoadingIcong";
type PropTypes = {
  type: AvailabilityType;
  dateLabel: string;
  timeLabel: string;
  onTimeSelected: (time: string) => void;
  selectedTime?: string;
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomInput = (props: any) => {
  return (
    <input
      className={[
        "w-full hover:border-swippy-orange focus:border-swippy-orange flex items-center outline-none border-gray-300 rounded-full border-2 p-2",
      ].join(" ")}
      onClick={props.onClick}
      value={props.value}
      type="text"
      readOnly={true}
    />
  );
};

export function ChooseAvailability(props: PropTypes) {
  const { setSelectedDate, getSelectedDate } = useCurrentCart();

  const [currentMonthDate, setCurrentMonthDate] = useState<Moment>(
    moment().date(15),
  );

  const { closedDatesDecorator, loading } = useClosedDates(
    props.type,
    currentMonthDate,
  );

  function changeDate(newDate: Date) {
    setSelectedDate(moment(newDate));
  }

  return (
    <>
      <div className={"flex flex-col"}>
        <label className={"mb-2 font-bold"}>{props.dateLabel}</label>
        <DatePicker
          customInput={<CustomInput />}
          popperPlacement={"top"}
          filterDate={() => !loading}
          renderCustomHeader={({
            date,
            nextMonthButtonDisabled,
            increaseMonth,
            prevMonthButtonDisabled,
            decreaseMonth,
          }) => (
            <div className={"flex justify-between items-center"}>
              <ChevronLeftIcon
                onClick={() => {
                  if (prevMonthButtonDisabled) return;
                  decreaseMonth();
                }}
                className={"h-5 w-5 text-gray-600 cursor-pointer"}
              />
              <div className={"flex items-center"}>
                <h5 className={"font-bold mb-0"}>
                  {_.capitalize(moment(date).format("MMMM YYYY"))}
                </h5>
                {loading ? (
                  <span className={"ml-2"}>
                    <LoadingIcon size={18} />
                  </span>
                ) : null}
              </div>
              <ChevronRightIcon
                onClick={() => {
                  if (nextMonthButtonDisabled) return;
                  increaseMonth();
                }}
                className={"h-5 w-5 text-gray-600 cursor-pointer"}
              />
            </div>
          )}
          disabledKeyboardNavigation
          onCalendarOpen={() =>
            setCurrentMonthDate(moment(getSelectedDate()).date(15))
          }
          wrapperClassName={"w-full"}
          excludeDates={closedDatesDecorator.closedDates.map((d) => d.toDate())}
          onMonthChange={(date) => {
            setCurrentMonthDate(moment(date));
          }}
          dateFormat={"dd MMMM yyyy"}
          locale={"it"}
          className={
            "w-full hover:border-swippy-orange focus:border-swippy-orange flex items-center outline-none border-gray-300 rounded-full border-2 p-2"
          }
          onChange={changeDate}
          selected={getSelectedDate().toDate()}
        />
      </div>
      <div className={"flex flex-col mt-4 flex-1"}>
        <label className={"mb-2 font-bold"}>{props.timeLabel}</label>
        <TimeSelector
          selectedTime={props.selectedTime}
          onTimeSelected={props.onTimeSelected}
          type={props.type}
          date={getSelectedDate()}
        />
      </div>
    </>
  );
}
