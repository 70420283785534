import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { useRistopubCanDeliverToAddress } from "./useRistopubCanDeliverToAddress";
import React, { useEffect, useRef, useState } from "react";
import { useCurrentCart } from "../Cart/useCurrentCart";
import { Debouncer } from "../Debouncer";
import { MainLayout } from "../MainLayout/MainLayout";
import { RistopubTopImage } from "../Ristopub/RistopubTopImage";
import { AddressAutocompleteInput } from "./AddressAutocompleteInput";
import { Warning } from "../ui/Warning";
import { Spin } from "antd";
import { LoadingIcon } from "../ui/LoadingIcong";
import { formatPrice } from "../formatPrice/formatPrice";
import { NextStepButton } from "../OrderButton/NextStepButton/NextStepButton";
import { DeliveryFreeShippingAdvice } from "./DeliveryFreeShippingAdvice";
import { Address } from "../Cart/classes/Address/Address";
import { AddressDataMapper } from "../Cart/classes/Address/AddressDataMapper";

function saveToLocalStorage(address: Address) {
  const addressDataMapper = new AddressDataMapper();
  window.localStorage.setItem(
    "address",
    JSON.stringify(addressDataMapper.toDto(address)),
  );
}

type Props = {
  onBack: () => void;
  toMenuScreen: () => void;
};

export function DeliveryInsertAddressComponent(props: Props) {
  const { ristopub } = useCurrentRistopub();

  const { ristopubCanDeliverToAddress } = useRistopubCanDeliverToAddress();
  const [showCantDeliverMsg, setShowCantDeliverMsg] = useState(false);
  const {
    getDeliveryAddress,
    setCartAddress,
    getDeliveryCost,
    getTotalItemPrice,
    isRefreshingShippingCost,
  } = useCurrentCart();
  const [loading, setLoading] = useState(false);

  const [refreshingCantDeliver, setRefreshingCantDeliver] = useState(false);

  const { current: refreshCanDeliverDebouncer } = useRef(new Debouncer(500));

  const cartAddress = getDeliveryAddress();

  async function updateCantDeliver() {
    if (!ristopub) return setRefreshingCantDeliver(false);

    if (!cartAddress.isCompleteAddress()) {
      return setRefreshingCantDeliver(false);
    }

    try {
      const canDeliverToAddress = await ristopubCanDeliverToAddress(
        ristopub,
        cartAddress,
        getTotalItemPrice(),
      );

      if (!canDeliverToAddress.canDeliver) {
        setShowCantDeliverMsg(true);
        return;
      }
    } finally {
      setRefreshingCantDeliver(false);
    }
  }

  const cartAddressString = cartAddress.toString();
  const ristopubIdEffectKey = ristopub ? ristopub.id : undefined;

  useEffect(
    () => {
      setShowCantDeliverMsg(false);
      setRefreshingCantDeliver(true);
      refreshCanDeliverDebouncer.debounce(updateCantDeliver);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartAddressString, ristopubIdEffectKey],
  );

  async function toDeliveryMenuScreen() {
    if (!ristopub) return;

    if (!cartAddress.isCompleteAddress()) {
      return;
    }

    try {
      setLoading(true);
      const canDeliverToAddress = await ristopubCanDeliverToAddress(
        ristopub,
        cartAddress,
        getTotalItemPrice(),
      );
      if (!canDeliverToAddress.canDeliver) {
        setShowCantDeliverMsg(true);
        return;
      }

      props.toMenuScreen();
    } finally {
      setLoading(false);
    }
  }

  const deliveryCost = getDeliveryCost();

  return (
    <MainLayout goBack={props.onBack} withHeader={true}>
      <RistopubTopImage>
        <div
          className={["bg-white rounded-t-3xl p-4 flex-1 flex flex-col"].join(
            " ",
          )}
        >
          <div className={"mb-4"}>
            <label
              className={"text-base font-bold block text-center w-full mb-2"}
            >
              Dove vuoi ricevere il tuo ordine?
            </label>
            <AddressAutocompleteInput
              placeHolder={"Il tuo indirizzo"}
              loading={refreshingCantDeliver}
              selectedAddress={cartAddress}
              onChange={(newAddress) => {
                if (newAddress) {
                  setRefreshingCantDeliver(true);
                  setCartAddress(newAddress);
                  saveToLocalStorage(newAddress);
                }
              }}
            />
          </div>

          {showCantDeliverMsg ? (
            <div className={"mb-2"}>
              <Warning
                title={"Area non coperta"}
                copy={
                  <span>
                    Purtroppo {ristopub?.name} non consegna all&apos;indirizzo
                    da te fornito ({cartAddress.toString()})
                  </span>
                }
              />
            </div>
          ) : null}
          {cartAddress.isCompleteAddress() &&
          !showCantDeliverMsg &&
          !refreshingCantDeliver ? (
            <div className={"flex justify-between items-center mb-2"}>
              <label className={"font-bold"}>Costi di consegna</label>
              <Spin
                indicator={<LoadingIcon />}
                spinning={isRefreshingShippingCost()}
              >
                <span>
                  {deliveryCost ? `${formatPrice(deliveryCost)} €` : "Gratis"}
                </span>
              </Spin>
            </div>
          ) : null}
          <div
            className={
              "fixed max-w-md ml-auto mr-auto bottom-0 left-0 right-0 p-4"
            }
          >
            <NextStepButton
              disabled={
                !cartAddress ||
                !cartAddress.isCompleteAddress() ||
                refreshingCantDeliver
              }
              loading={loading}
              label={"Vai al menu"}
              onClick={toDeliveryMenuScreen}
              gaProps={{
                gaCategory: "delivery_order_flow",
                gaLabel: "delivery_order_flow_insert_address_to_menu",
              }}
            />
            <div className={"mt-1"}>
              <DeliveryFreeShippingAdvice />
            </div>
          </div>
        </div>
      </RistopubTopImage>
    </MainLayout>
  );
}
