import { IDataMapper } from "../../interfaces/IDataMapper";
import { CartItemDTO } from "./CartItemDTO";
import { CartItem } from "./CartItem";
import { ProductDataMapper } from "../../RistopubMenu/Product/ProductDataMapper";
import { CartItemSelectedOption } from "./CartItemSelectedOption";

export class CartItemDataMapper implements IDataMapper<CartItemDTO, CartItem> {
  constructor(private productDataMapper: ProductDataMapper) {}

  toDto(e: CartItem): CartItemDTO {
    return {
      qty: e.getQty(),
      product: this.productDataMapper.toDto(e.getProduct()),
      id: e.id,
      options: e.getOptions().map((o) => {
        return {
          modifierId: o.modifierId,
          choiceId: o.choiceId,
          modifierName: o.modifierName,
          choiceName: o.choiceName,
          choicePrice: o.choicePrice,
          choiceQty: o.choiceQty,
        };
      }),
    };
  }

  toEntity(d: CartItemDTO): CartItem {
    return CartItem.create({
      qty: d.qty,
      product: this.productDataMapper.toEntity(d.product),
      id: d.id,
      options: d.options
        ? d.options.map(
            (o) =>
              new CartItemSelectedOption(
                o.modifierId,
                o.choiceId,
                o.modifierName,
                o.choiceName,
                o.choicePrice,
                o.choiceQty,
              ),
          )
        : undefined,
    });
  }
}
