import React from "react";
import { InfoIcon } from "./InfoIcon";

type PropTypes = {
  title: string;
  copy?: React.ReactNode;
};

export function Info(props: PropTypes) {
  return (
    <div
      className={[
        "flex rounded flex-row bg-blue-100 p-3",
        !props.copy ? "items-center" : "",
      ].join(" ")}
    >
      <div
        className={
          "rounded-full text-blue-600 bg-blue-200 mb-auto p-1.5 mr-2 items-center justify-center"
        }
      >
        <InfoIcon />
      </div>
      <div className={""}>
        <h3
          className={["text-blue-600 font-bold", !props.copy ? "m-0" : ""].join(
            " ",
          )}
        >
          {props.title}
        </h3>
        {props.copy ? (
          <p className={"text-blue-600 m-0"}>{props.copy}</p>
        ) : null}
      </div>
    </div>
  );
}
