import { useEffect } from "react";
import { DataSnapshot } from "@firebase/database";
import { getDatabase, off, onValue, ref } from "firebase/database";
export function useOrderChangedListener(
  orderId: string | undefined,
  handler: (a: DataSnapshot, b?: string | null) => unknown,
) {
  useEffect(() => {
    if (!orderId) return;
    const db = getDatabase();
    const dbRef = ref(db, `orders/${orderId}/lastUpdatedDate`);

    onValue(dbRef, handler);

    return () => {
      off(dbRef, "value", handler);
    };
  }, [orderId]);
}
