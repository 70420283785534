import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { GeocodeAddressResponseDTO } from "./GeocodeAddressResponseDTO";

const GEOCODE_ADDRESS_MUTATION = gql`
  mutation geocodeAddress($input: GeocodeAddressInput!) {
    geocodeAddress(input: $input) {
      latitude
      longitude
      addressComponents {
        types
        long_name
        short_name
      }
    }
  }
`;

export function useGeocodeAddress() {
  const [geocodeAddressMutation, { loading }] = useMutation<
    {
      geocodeAddress?: GeocodeAddressResponseDTO;
    },
    {
      input: {
        address: string;
      };
    }
  >(GEOCODE_ADDRESS_MUTATION);

  async function geocodeAddress(
    address: string,
  ): Promise<GeocodeAddressResponseDTO | undefined> {
    const res = await geocodeAddressMutation({
      variables: {
        input: {
          address,
        },
      },
    });

    return res.data?.geocodeAddress;
  }

  return {
    geocodeAddress,
    loading,
  };
}
