import React from "react";
import styles from "./OrdersListComponent.module.scss";
import { Badge } from "antd";
import { MobileTouchFeedbackWrapper } from "../../ui/MobileTouchFeedbackWrapper/MobileTouchFeedbackWrapper";
import { ChevronRightIcon } from "../../ui/ChevronRightIcon";

type PropTypes = {
  ristopubName: string;
  status: React.ReactNode;

  onClick?: () => void;
  isChanged?: boolean;
  isInProgress?: boolean;
  icon?: React.ReactNode;
};

export function OrdersListComponent(props: PropTypes) {
  return (
    <MobileTouchFeedbackWrapper>
      {({ touchHover }) => {
        const classes = [styles.wrapper, "h-24 px-4 py-2"];
        if (touchHover) classes.push(styles.hover);

        return (
          <div onClick={props.onClick} className={classes.join(" ")}>
            <div>
              <h3 className={[styles.ristopubName, "pl-1"].join(" ")}>
                {props.ristopubName}
              </h3>
              {props.isInProgress ? (
                <p className={"pl-1 text-gray-600 mb-0 mt-0"}>
                  Ordine in corso
                </p>
              ) : null}

              <div className={styles.statusWrapper}>
                {props.icon || null}

                <p className={"ml-1 mb-0"}>{props.status || null}</p>

                {/*<p style={{*/}
                {/*    color: props.statusColor*/}
                {/*}} className={styles.status}>{props.status} {props.isInProgress ? <span className={styles.inProgress}>- Ordine in corso</span> : null}</p>*/}
              </div>
            </div>
            <div className={styles.rightWrapper}>
              {props.isChanged ? (
                <Badge offset={[0, 5]} className={styles.badge} dot={true} />
              ) : null}
              <ChevronRightIcon className={"h-6 w-6"} />
              {/*<RightOutlined className={styles.rightChevron}/>*/}
            </div>
          </div>
        );
      }}
    </MobileTouchFeedbackWrapper>
  );
}
