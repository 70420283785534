import { CartItemDTO } from "./CartItemDTO";
import { AddressDTO } from "./Address/AddressDTO";

export type CartDTO = {
  items: Record<string, CartItemDTO>;
  selectedTime?: string;
  deliverySelectedTime?: string;
  customerNotes?: string;
  customerName?: string;
  customerPhone?: string;
  customerEmail?: string;
  virtualTableId?: string;
  deliveryCost?: number;
  id?: string;
  address?: AddressDTO;
  shippingMethod?: CartShippingMethod;
  selectedDate?: string;
};

export enum CartShippingMethod {
  DELIVERY = "DELIVERY",
  TAKEAWAY = "TAKEAWAY",
}
