import { useContext } from "react";
import { OrderLastSeenCtx } from "./OrderLastSeenCtx";

export function useOrderLastSeen() {
  const { updateLastSeenOf, isChangedSinceLastSeen } =
    useContext(OrderLastSeenCtx);
  return {
    updateLastSeenOf,
    isChangedSinceLastSeen,
  };
}
