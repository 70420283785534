import React from "react";

type Props = {
  className?: string;
};

export function LogotypeIcon(props: Props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 840.98 306.367"
      xmlSpace="preserve"
    >
      <g
        fillRule="evenodd"
        clipRule="evenodd"
        transform="translate(-227.51 -710.817)"
      >
        <path d="M438.778 909.001c.578 26.155-7.67 48.458-22.903 67.946-16.583 21.215-38.85 34.271-65.275 38.598-30.573 5.005-59.062-1.351-84.026-20.479-8.336-6.388-9.249-15.305-1.395-23.171 42.86-42.921 85.775-85.786 128.649-128.693 6.296-6.301 16.245-9.325 24.635 2.971 13.143 19.262 21.132 40.158 20.315 62.828zm-118.23 96.839c2.401 1.025 2.757 1.007 4.098.921 8.528-.546 17.188-.502 25.544-2.053 30.573-5.671 53.021-22.907 67.49-50.176 9.43-17.771 13.008-36.791 10.173-57.042-2.414-17.24-8.741-32.555-19.183-46.404-2.664-3.534-4.52-3.227-7.219-.509-10.563 10.635-21.141 21.257-31.811 31.786-2.068 2.042-2.087 3.278-.271 5.762 13.195 18.04 17.323 38.182 12.64 59.976-3.583 16.677-11.962 30.535-25.347 41.348-10.554 8.527-22.525 13.574-36.114 16.391z" />
        <path d="M442.584 771.722c-5.58-13.235-14.523-20.982-27.611-23.972-15.424-3.522-31.277 3.812-39.416 17.415-9.047 15.123-5.453 34.184 6.538 45.591 8.351 7.943 8.196 13.011.072 21.135-10.841 10.843-21.696 21.671-32.505 32.546-1.894 1.904-3.074 2.807-6.164.949-21.945-13.204-35.652-32.097-39.723-57.38-4.09-25.403 1.464-48.357 18.49-68.362 12.9-15.158 29.293-24.268 48.387-27.506 23.93-4.059 46.137 1.169 65.57 16.564 8.386 6.643 15.324 14.395 20.426 23.729.713 1.305.596 4.046-.316 5.087-4.147 4.73-8.716 9.088-13.748 14.204z" />
        <path d="M611.851 866.536l-15.814 1.443c-2.436-5.109-4.115-9.901-6.822-14.017-4.216-6.411-11.31-8.608-18.359-8.916-6.964-.304-14.284.147-20.951 2.028-12.269 3.461-17.101 16.605-9.896 25.965 2.099 2.727 5.991 4.602 9.433 5.67 13.811 4.289 27.892 7.731 41.638 12.207 13.683 4.455 25.17 16.933 23.609 33.457-1.302 13.781-9.466 25.881-24.077 31.347-17.346 6.488-34.872 5.873-51.695-1.465-12.604-5.498-20.043-15.989-23.141-29.401-.621-2.688-.843-5.468-1.316-8.663l16.527-1.636c1.191 4.041 2.007 7.832 3.418 11.388 3.746 9.443 11.074 14.521 20.746 17.052 12.317 3.223 23.963 2.768 34.699-4.751 6.01-4.207 8.66-10.115 7.83-17.343-.81-7.054-5.258-11.811-11.599-13.957-10.294-3.484-20.9-6.037-31.324-9.151-9.2-2.749-18.336-5.334-25.854-12.138-15.259-13.808-10.596-40.064 7.481-49.03 21.527-10.678 42.835-9.877 62.742 3.587 8.617 5.829 11.66 15.724 12.725 26.324z" />
        <path d="M686.221 887.139c-5.922 22.487-11.82 44.389-17.354 66.382-.885 3.521-2.27 4.555-5.604 4.35-3.637-.224-7.297-.051-11.191-.051l-27.837-91.977c5.016 0 9.41-.203 13.759.152.997.081 2.324 1.942 2.68 3.213 6.173 22.075 12.211 44.187 18.321 66.278.162.581.791 1.032 1.225 1.572l19.035-71.066h15.006l17.963 70.4c6.592-22.851 13.021-45.263 19.607-67.628.348-1.18 1.965-2.712 3.094-2.805 4.117-.338 8.277-.133 13.287-.133-9.424 30.951-18.676 61.335-27.963 91.842h-16.262c-5.882-23.349-11.749-46.638-17.766-70.529z" />
        <path d="M814.734 948.448v44.082h-14.598V866.16h14.434v10.779c2.592-2.381 4.627-4.057 6.439-5.946 9.73-10.151 30.398-8.821 41.266-.239 9.043 7.143 13.287 16.857 15.295 27.548 2.363 12.585 1.158 24.847-3.502 37.165-8.306 21.962-39.621 34.413-59.334 12.981zm49.079-37.513c.111-7.989-1.002-15.628-4.977-22.746-8.381-15.007-26.566-17.092-36.207-3.348-3.844 5.48-6.266 12.592-7.289 19.274-1.719 11.23-1.4 22.709 5.43 32.687 8.852 12.93 25.502 13.714 35.637.969 6.376-8.016 6.988-17.336 7.406-26.836z" />
        <path d="M912.787 948.708v43.813h-14.641V866.168h14.402v10.764c2.594-2.37 4.605-4.059 6.445-5.918 11.537-11.653 36.08-8.804 46.715 5.196 10.064 13.253 12.252 28.1 10.289 44.153-1.379 11.271-5.18 21.307-13.521 29.135-12.398 11.634-33.479 15.622-47.664.911-.408-.423-.902-.763-2.025-1.701zm49.026-37.765c.111-7.988-1-15.627-4.975-22.746-8.385-15.018-26.549-17.11-36.203-3.362-3.846 5.477-6.264 12.589-7.291 19.271-1.727 11.23-1.4 22.707 5.424 32.688 8.857 12.95 25.516 13.705 35.633.982 6.374-8.014 6.988-17.334 7.412-26.833z" />
        <path d="M989.166 865.982c5.102 0 11.02-1.7 14.422.497 3.145 2.032 3.725 8.193 5.207 12.612 6.564 19.576 13.047 39.18 19.373 58.213 5.037-14.731 10.254-30.034 15.5-45.327 2.643-7.699 5.209-15.43 8.123-23.025.518-1.348 2.506-2.84 3.947-2.99 3.953-.41 7.977-.142 12.752-.142-2.816 8.204-5.465 16.086-8.225 23.93-9.506 27.017-18.822 54.102-28.723 80.973-2.775 7.536-6.082 15.22-13.303 20.097-6.766 4.569-14.143 5.061-21.568 2.676-1.48-.476-2.275-3.979-2.826-6.223-.615-2.504-.59-5.164-.91-8.457 1.391.229 2.738.372 4.047.682 8.375 1.977 15.023-1.027 18.746-8.86 3.031-6.379 5.439-12.359 2.563-20.175-9.781-26.568-18.631-53.48-27.826-80.264-.465-1.355-.846-2.738-1.299-4.217z" />
        <path
          fill="#f84004"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="6"
          d="M769.84 853.842c-10.83 1.381-18.883-9.613-18.691-18.292.234-10.719 8.334-18.703 18.674-18.701 10.037.001 19.297 7.494 18.605 19.742-.565 10.006-7.852 17.251-18.588 17.251z"
        />
        <path d="M776.381 957.599H762v-91.543h14.381v91.543z" />
        <path
          fill="#f84004"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="6"
          d="M465.662 772.069c8.582 12.794 12.778 26.345 13.975 42.05-5.692-1.915-10.542-3.526-15.377-5.181-3.259-1.115-6.483-2.329-9.744-3.435-8.278-2.81-10.684-11.16-4.831-17.552 4.065-4.44 8.484-8.557 12.754-12.811.823-.821 1.684-1.607 3.223-3.071z"
        />
        <path d="M243.557 970.439c-10.059-14.22-14.101-29.692-16.047-47.066 3.607.97 6.533 1.573 9.33 2.545 7.349 2.554 14.738 5.034 21.928 7.989 6.457 2.654 8.641 12.203 3.902 17.329-5.953 6.443-12.363 12.463-19.113 19.203z" />
      </g>
      <path d="M194.642 61.858c-1.219-1.729-2.667-3.156-4.343-4.245a17.987 17.987 0 00-9.141-3.213 18.481 18.481 0 00-9.95 2.125l-.006-.009c-1.946 1.02-3.07 1.984-3.07 1.984l.004.007a18.41 18.41 0 00-2.965 2.891 18.641 18.641 0 00-2.945 5.057 17.998 17.998 0 00-.956 9.642 18.602 18.602 0 002.009 5.731 20.573 20.573 0 002.093 2.992c1.141 1.366 4.625 2.257 6.11 1.517 2.409-1.198 2.907-3.58.763-6.52-4.153-5.695-3.488-9.95 1.288-14.143a10.07 10.07 0 012.006-1.305c5.888-2.629 10.06-1.456 13.486 4.762 1.757 3.187 4.127 3.744 6.231 2.068 1.3-1.033 1.966-4.566 1.211-6.178a20.514 20.514 0 00-1.825-3.163z" />
    </svg>
  );
}
