import { gql, useMutation } from "@apollo/client";

const SAVE_STRIPE_PAYMENT_METHOD_MUTATION = gql`
  mutation saveStripePaymentMethodId($input: SaveStripePaymentMethodIdInput!) {
    saveStripePaymentMethodId(input: $input)
  }
`;

export function useSaveStripePaymentMethodId() {
  const [saveStripePaymentMethodIdMutation] = useMutation(
    SAVE_STRIPE_PAYMENT_METHOD_MUTATION,
  );

  async function saveStripePaymentMethodId(
    customerEmail: string,
    ristopubId: string,
    guestUserId: string,
    stripePaymentMethodId: string,
  ) {
    await saveStripePaymentMethodIdMutation({
      variables: {
        input: {
          guestUserId: guestUserId,
          stripePaymentMethodId: stripePaymentMethodId,
          ristopubId: ristopubId,
          customerEmail: customerEmail,
        },
      },
    });
  }

  return {
    saveStripePaymentMethodId,
  };
}
