import { useEffect } from "react";
import { useGuestUserId } from "../../OrderSummaryScreen/useGuestUserId";
import { getDatabase, off, onValue, ref } from "firebase/database";
import { DataSnapshot } from "@firebase/database";
export function useGuestUpdateListener(
  handler: (a: DataSnapshot, b?: string | null) => unknown,
) {
  const { guestUserId } = useGuestUserId();

  useEffect(() => {
    if (!guestUserId) return;
    const db = getDatabase();
    const dbRef = ref(db, `guests/${guestUserId}/lastUpdatedDate`);

    onValue(dbRef, handler);

    return () => {
      off(dbRef, "value", handler);
    };
  }, [guestUserId]);
}
