import React from "react";
import { MobileTouchFeedbackWrapper } from "../../ui/MobileTouchFeedbackWrapper/MobileTouchFeedbackWrapper";

type Props = {
  onClick?: (e: React.MouseEvent) => void;
  children?: React.ReactNode;
  sizeClasses?: string;
  stopPropagation?: boolean;
  colorClasses?: string;
  hoverColorClasses?: string;
};

export function ItemQtyControlButton(props: Props) {
  return (
    <MobileTouchFeedbackWrapper
      stopPropagation={props.stopPropagation}
      style={{
        flexShrink: 0,
      }}
    >
      {({ touchHover }) => {
        const classes = [
          "text-lg font-bold rounded-full outline-none border-2 border-full flex justify-center items-center",
          props.sizeClasses ?? "h-10 w-10",
        ];
        if (touchHover)
          classes.push(
            props.hoverColorClasses ?? "border-gray-500 bg-gray-500 text-white",
          );
        else
          classes.push(props.colorClasses ?? "border-gray-500 text-gray-500");
        return (
          <button
            style={{
              cursor: "pointer",
            }}
            onClick={props.onClick}
            className={classes.join(" ")}
          >
            {props.children || null}
          </button>
        );
      }}
    </MobileTouchFeedbackWrapper>
  );
}
