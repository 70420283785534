import { OrderItemVariationChoice } from "./OrderItemVariationChoice";
import _ from "lodash";

export class OrderItemVariation {
  constructor(
    public readonly modifierName: string,
    public readonly choices: OrderItemVariationChoice[],
  ) {}

  public getTotalPrice(): number {
    return _.reduce(
      this.choices,
      (sum, c) => {
        return sum + c.getTotal();
      },
      0,
    );
  }
}
