import { VariationOption } from "./VariationOption";
import { Product } from "../Product/Product";

export class Variation {
  constructor(
    public readonly options: VariationOption[],
    public readonly product: Product,
    public readonly qty: number,
    public readonly cartItemId?: string,
  ) {}
}
