import React from "react";
import styles from "./FullScreenProductImage.module.scss";
import ReactDOM from "react-dom";
import { CloseIcon } from "../../ui/CloseIcon";

type PropTypes = {
  imgSrc?: string;
  opened?: boolean;
  onClose?: () => void;
};
export function FullScreenProductImage(props: PropTypes) {
  if (!props.imgSrc) return null;
  const classes = [styles.wrapper, "z-30"];
  if (props.opened) classes.push(styles.opened);
  return ReactDOM.createPortal(
    <div className={classes.join(" ")}>
      <span className={"absolute right-2 top-2"}>
        <CloseIcon onClick={props.onClose} />
      </span>
      {props.opened ? <img alt={props.imgSrc} src={props.imgSrc} /> : null}
    </div>,
    document.body,
  );
}
