import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { useCurrentCart } from "../Cart/useCurrentCart";
import { CartShippingMethod } from "../Cart/classes/CartDTO";
import { MainLayout } from "../MainLayout/MainLayout";
import { ListLoading } from "../Loading/ListLoading";
import { RistopubTopImage } from "../Ristopub/RistopubTopImage";
import { OutlinedButton } from "../ui/OutlinedButton";
import React from "react";

type Props = {
  toDelivery: () => void;
  toTakeaway: () => void;
};

export function ChooseShippingMethodComponent(props: Props) {
  const { ristopub, loadingCurrentRistopub } = useCurrentRistopub();

  const { emptyCart, getShippingMethod, setShippingMethod } = useCurrentCart();

  function toTakeaway() {
    const shippingMethod = getShippingMethod();
    if (shippingMethod !== CartShippingMethod.TAKEAWAY) {
      emptyCart();
      setShippingMethod(CartShippingMethod.TAKEAWAY);
    }
    props.toTakeaway();
  }

  function toDelivery() {
    const shippingMethod = getShippingMethod();
    if (shippingMethod !== CartShippingMethod.DELIVERY) {
      emptyCart();
      setShippingMethod(CartShippingMethod.DELIVERY);
    }
    props.toDelivery();
  }

  if (loadingCurrentRistopub)
    return (
      <MainLayout hideBackButton withHeader={true}>
        <ListLoading />
      </MainLayout>
    );

  if (!ristopub?.enableDelivery && ristopub?.enableTakeaway) {
    toTakeaway();
    return null;
  }

  if (!ristopub?.enableTakeaway && ristopub?.enableDelivery) {
    toDelivery();
    return null;
  }

  if (!ristopub?.enableDelivery && !ristopub?.enableTakeaway) return null;

  return (
    <MainLayout hideBackButton withHeader={true}>
      <RistopubTopImage>
        <div
          style={{
            backgroundColor: "white",
          }}
          className={"p-4 rounded-t-lg flex flex-1 flex-col"}
        >
          <h3 className={"text-gray-900 text-center mt-4 font-bold mb-8"}>
            Quale metodo di consegna preferisci?
          </h3>
          <div className={"px-6 flex flex-col"}>
            <OutlinedButton onClick={toDelivery}>
              <span>Consegna a domicilio</span>
            </OutlinedButton>

            <OutlinedButton className={"mt-6"} onClick={toTakeaway}>
              <span>Da asporto</span>
            </OutlinedButton>
          </div>
        </div>
      </RistopubTopImage>
    </MainLayout>
  );
}
