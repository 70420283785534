import { useHistory } from "react-router-dom";

export function useToDeliveryMenuScreenWithQrCode() {
  const history = useHistory();

  function toDeliveryMenuScreenWithQrCode(
    ristopubId: string,
    qrCodeId: string,
  ) {
    return history.push(
      `/ristopub/${ristopubId}/qrcode/${qrCodeId}/delivery/menu`,
    );
  }

  return {
    toDeliveryMenuScreenWithQrCode,
  };
}
