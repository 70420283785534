import { VirtualTableDataMapper } from "./VirtualTableDataMapper";
import { Order } from "./Order";
import { OrderDTO } from "./OrderDTO";
import { OrderItemDataMapper } from "./OrderItem/OrderItemDataMapper";
import { ProductDataMapper } from "../RistopubMenu/Product/ProductDataMapper";
import _ from "lodash";
import moment from "moment-timezone";
import { AddressDataMapper } from "../Cart/classes/Address/AddressDataMapper";

export class OrderDataMapper {
  constructor(
    private virtualTableDataMapper: VirtualTableDataMapper,
    private orderItemDataMapper: OrderItemDataMapper = new OrderItemDataMapper(
      new ProductDataMapper(),
    ),
    private addressDataMapper = new AddressDataMapper(),
  ) {}

  toEntity(d: OrderDTO): Order {
    return Order.create({
      selfPickup: d.selfPickup,
      cartId: d.cartId,
      id: d.id,
      deliveryCost: d.deliveryCost,
      type: d.type,
      friendlyOrderId: d.friendlyOrderId,
      status: d.orderStatus,
      customerNotes: d.customerNotes,
      dueDate: d.dueDate ? moment(d.dueDate) : undefined,
      virtualTable: d.virtualTable
        ? this.virtualTableDataMapper.toEntity(d.virtualTable)
        : undefined,
      orderItems: _.map(d.orderItems, (i) => {
        return this.orderItemDataMapper.toEntity(i);
      }),
      updatedAt: d.updatedAt ? moment(d.updatedAt) : undefined,
      statusChangedAt: d.statusChangedAt
        ? moment(d.statusChangedAt)
        : undefined,
      deliveryAddress: d.deliveryAddress
        ? this.addressDataMapper.toEntity(d.deliveryAddress)
        : undefined,
      deliveryDate: d.deliveryDate ? moment(d.deliveryDate) : undefined,
    });
  }
}
