import moment, { Moment } from "moment-timezone";
import { Order } from "../Order";

const LOCAL_STORAGE_KEY = "last-seen-orders";

export class OrderSeenTracker {
  private constructor(private lastSeenState: Record<string, string> = {}) {}

  private static getStateFromLocalStorage():
    | Record<string, string>
    | undefined {
    const data = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    try {
      if (!data) return undefined;
      return JSON.parse(data) as Record<string, string>;
    } catch (e) {
      return undefined;
    }
  }

  public static create(): OrderSeenTracker {
    const state = this.getStateFromLocalStorage();
    return new OrderSeenTracker(state);
  }

  private getLastSeenAt(orderId: string): Moment {
    const date = this.lastSeenState[orderId];
    return date ? moment(date) : moment(0);
  }

  private saveToLocalStorage() {
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify(this.lastSeenState),
    );
  }

  private updateLastTimeOrderSeenInLocalStorage(orderId: string) {
    this.lastSeenState[orderId] = moment().toISOString();
    this.saveToLocalStorage();
  }

  public updateLastSeenOf(order: Order) {
    this.updateLastTimeOrderSeenInLocalStorage(order.id);
  }

  public isStatusChangedSinceLastSeen(order: Order): boolean {
    const statusChangedAt = order.statusChangedAt;
    if (!statusChangedAt) return false;
    const lastSeenAt = this.getLastSeenAt(order.id);
    return lastSeenAt.isBefore(statusChangedAt);
  }
}
