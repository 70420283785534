import _ from "lodash";
import React from "react";
import { Badge } from "antd";
import { useOrderLastSeen } from "../OrderLastSeenCtx/useOrderLastSeen";
import { useLast10DaysOrdersCtx } from "../Last10DaysOrdersCtx/useLast10DaysOrdersCtx";

type PropTypes = {
  children?: React.ReactNode;
  className?: string;
};

export function ChangedOrdersStatusBadge(props: PropTypes) {
  const { orders } = useLast10DaysOrdersCtx();

  const { isChangedSinceLastSeen } = useOrderLastSeen();

  const classes: string[] = [];

  if (props.className) classes.push(props.className);
  const ordersWithStatusChanged = _.filter(orders, (o) => {
    return isChangedSinceLastSeen(o);
  });

  if (ordersWithStatusChanged.length <= 0)
    return <div className={classes.join(" ")}>{props.children || null}</div>;

  return (
    <Badge
      offset={[-22, 0]}
      className={classes.join(" ")}
      count={ordersWithStatusChanged.length}
    >
      {props.children || null}
    </Badge>
  );
}
