import React from "react";
import styles from "./ChooseAvailabilityList.module.scss";
import _ from "lodash";
import { TimeSelectorListItem } from "./ChooseAvailabilityItem/TimeSelectorListItem";
import { useCurrentCart } from "../Cart/useCurrentCart";
import { SelectedTime } from "../Takeaway/SelectedTime";
import { Time } from "../Ristopub/Time";

type PropTypes = {
  availabilities: { start: Time; end: Time }[];
  onTimeSelected: (selectedTime: string) => void;
  selectedTime?: string;
};

export function TimeSelectorList(props: PropTypes) {
  return (
    <div className={"flex flex-1"}>
      <div className={"overflow-x-scroll flex items-start"}>
        {_.map(props.availabilities, (a) => {
          return (
            <div key={a.start.formatTime()} className={"mr-1 flex-shrink-0"}>
              <TimeSelectorListItem
                selected={a.start.formatTime() === props.selectedTime}
                onClick={() => {
                  console.log("CLICK", a.start.formatTime());
                  props.onTimeSelected(a.start.formatTime());
                }}
                time={a.start.formatTime() + " - " + a.end.formatTime()}
              />
            </div>
          );
        })}
        {props.availabilities.length <= 0 ? (
          <p className={styles.emptyList}>
            Nessun orario disponibile nel giorno selezionato
          </p>
        ) : null}
      </div>
    </div>
  );
}
