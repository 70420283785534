import { ProductModifier } from "./ProductModifier";
import { ProductModifierDTO } from "./ProductModifierDTO";
import _ from "lodash";
import { ProductModifierChoice } from "./ProductModifierChoice";

export class ProductModifierDataMapper {
  toEntity(d: ProductModifierDTO): ProductModifier {
    return new ProductModifier(
      d.id,
      d.type,
      d.name,
      d.description,
      _.map(
        d.choices,
        (c) => new ProductModifierChoice(c.id, c.name, c.price, c.maxQty),
      ),
      d.maxSelectableChoices,
      d.required,
    );
  }
}
