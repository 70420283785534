import { Ristopub } from "./Ristopub";
import React from "react";

type RistopubCtxType = {
  ristopub?: Ristopub;
  loadingCurrentRistopub: boolean;
};

const RistopubCtx = React.createContext<RistopubCtxType>({
  loadingCurrentRistopub: false,
});

export { RistopubCtx };
