import { useHistory } from "react-router-dom";

export function useToOrders() {
  const history = useHistory();

  function toOrders() {
    history.push(`/orders`);
  }

  return {
    toOrders,
  };
}
