import { CartCtx } from "./CartCtx";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import {
  loadCartFromLocalStorage,
  removeCurrentCartFromLocalStorage,
  saveCartToLocalStorage,
} from "./cartLocalStorageHandlers";
import { Product } from "../RistopubMenu/Product/Product";
import _ from "lodash";
import { Time } from "../Ristopub/Time";
import { Address } from "./classes/Address/Address";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useCurrentTableName } from "../InStructureOrders/useCurrentTableName";
import { CartShippingMethod } from "./classes/CartDTO";
import { Moment } from "moment-timezone";
import { Debouncer } from "../Debouncer";
import { useGetShippingCostQuery } from "./useGetShippingCostQuery";
import { CartItem } from "./classes/CartItem";
import { useAuthCtx } from "../Auth/useAuthCtx";

type PropTypes = PropsWithChildren<{}>;

export function CartCtxProvider(props: PropTypes) {
  const { ristopubId } = useCurrentRistopubId();
  const { tableName } = useCurrentTableName();

  const cartScope = `v2-${ristopubId}${tableName ? `-${tableName}` : ""}`;

  const { current: shippingCostUpdaterDebouncer } = useRef(new Debouncer(500));

  const { user } = useAuthCtx();
  const [cart, setCart] = useState(
    loadCartFromLocalStorage(cartScope, user ?? undefined),
  );

  const { getShippingCost, loading: refreshingShippingCost } =
    useGetShippingCostQuery();

  async function updateShippingCost() {
    if (!cart.isDelivery()) return;

    if (!cart.address.isCompleteAddress()) {
      setDeliveryCost(0);
      return;
    }

    const shippingCost = await getShippingCost(
      ristopubId,
      cart.getTotalItemPrice(),
      cart.address,
    );
    setDeliveryCost(shippingCost);
  }

  const totalItemQty = cart.getTotalItemQty();
  const cartAddressString = cart.address.toString();
  const cartShippingMethod = cart.getShippingMethod();
  useEffect(() => {
    shippingCostUpdaterDebouncer.debounce(updateShippingCost);
  }, [totalItemQty, cartAddressString, cartShippingMethod]);

  useEffect(() => {
    setCart(loadCartFromLocalStorage(cartScope, user ?? undefined));
  }, [cartScope, user]);

  function addToCart(product: Product, qty: number = 1) {
    cart.addToCart(product, qty);
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }

  function removeFromCart(product: Product, qty: number = 1) {
    cart.removeFromCart(product, qty);
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }

  function setCartAddress(address: Address) {
    cart.address = address;
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }

  function setSelectedTime(timeString: string) {
    cart.setSelectedTime(
      Time.create({
        time: timeString,
      }),
    );
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }
  function setDeliveryCost(deliveryCost: number) {
    cart.setDeliveryCost(deliveryCost);
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }
  function setDeliverySelectedTime(timeString: string) {
    cart.setDeliverySelectedTime(
      Time.create({
        time: timeString,
      }),
    );
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }

  function setCustomerNotes(customerNotes?: string) {
    cart.setCustomerNotes(customerNotes);
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }
  function setCustomerName(customerName?: string) {
    cart.setCustomerName(customerName);
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }
  function setCustomerPhone(customerPhone?: string) {
    cart.setCustomerPhone(customerPhone);
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }
  function setCustomerEmail(customerEmail?: string) {
    cart.setCustomerEmail(customerEmail);
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }
  function setVirtualTableId(virtualTableId?: string) {
    cart.setVirtualTableId(virtualTableId);
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }
  function setShippingMethod(shippingMethod: CartShippingMethod) {
    cart.setShippingMethod(shippingMethod);
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }
  function setSelectedDate(date: Moment) {
    cart.setSelectedDate(date);
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }

  function markCartAsOrdered(virtualTableId: string) {
    if (!virtualTableId) throw new Error("Missing virtual table id");
    setVirtualTableId(virtualTableId);
    removeCurrentCartFromLocalStorage(cartScope);
    setCart(loadCartFromLocalStorage(cartScope, user ?? undefined));
  }

  function emptyCart() {
    cart.emptyCart();
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }

  function increaseCartItemQty(cartItemId: string) {
    cart.increaseCartItemQty(cartItemId);
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }
  function decreaseCartItemQty(cartItemId: string) {
    cart.decreaseCartItemQty(cartItemId);
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }
  function toggleOptionByCartItem(
    cartItemId: string,
    modifierId: string,
    optionId: string,
    modifierName: string,
    choiceName: string,
  ) {
    cart.toggleOptionByCartItem(
      cartItemId,
      modifierId,
      optionId,
      modifierName,
      choiceName,
    );
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }

  function addToCartByCartItem(cartItem: CartItem) {
    cart.addToCartByCartItem(cartItem);
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }
  function updateCartItem(cartItem: CartItem) {
    cart.updateCartItem(cartItem);
    setCart(_.cloneDeep(cart));
    saveCartToLocalStorage(cart, cartScope);
  }

  return (
    <CartCtx.Provider
      value={{
        cartScope,
        cart,
        updateCartItem,
        addToCart,
        removeFromCart,
        setDeliveryCost,
        addToCartByCartItem,
        setSelectedDate,
        setSelectedTime,
        increaseCartItemQty,
        decreaseCartItemQty,
        toggleOptionByCartItem,
        setCustomerNotes,
        setCustomerEmail,
        setCartAddress,
        setDeliverySelectedTime,
        setCustomerName,
        setCustomerPhone,
        markCurrentCartAsOrdered: markCartAsOrdered,
        emptyCart,
        setShippingMethod,
        refreshingShippingCost,
      }}
    >
      {props.children || null}
    </CartCtx.Provider>
  );
}
