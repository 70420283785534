import { Ristopub } from "../Ristopub/Ristopub";
import { PhoneDTO } from "./VirtualTableDTO";
import { Phone } from "./Phone";

type CreateVirtualTableInput = {
  id: string;
  ristopub?: Ristopub;
  customerPhone?: PhoneDTO;
  customerName?: string;
};

export class VirtualTable {
  private constructor(
    public id: string,
    public ristopub?: Ristopub,
    private customerPhone?: Phone,
    public customerName?: string,
  ) {}

  public getCustomerPhone() {
    return this.customerPhone ? this.customerPhone.phone : undefined;
  }

  public static create({
    id,
    ristopub,
    customerName,
    customerPhone,
  }: CreateVirtualTableInput) {
    return new VirtualTable(
      id,
      ristopub,
      customerPhone
        ? Phone.create({
            phone: customerPhone.phone,
          })
        : undefined,
      customerName,
    );
  }
}
