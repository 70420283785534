import React from "react";
import { Layout } from "antd";
import styles from "./SwippyHeader.module.scss";
import { useCurrentRistopub } from "../../Ristopub/useCurrentRistopub";
import { CurrentScreen } from "./CurrentScreen/CurrentScreen";
import { useHistory } from "react-router-dom";
import { ChangedOrdersStatusBadge } from "../../Order/ChangedOrdersStatusBadge/ChangedOrdersStatusBadge";
import { MenuIcon } from "../../ui/MenuIcon";
import { ChevronLeftIcon } from "../../ui/ChevronLeftIcon";

const { Header } = Layout;

type PropTypes = {
  onMenuClicked?: () => void;
  goBack?: () => void;
  hideBackButton?: boolean;
  className?: string;
};

export function SwippyHeader(props: PropTypes) {
  const { ristopub } = useCurrentRistopub();
  const history = useHistory();

  const classes = [
    styles.header,
    "text-gray-700 border-b border-gray-100 z-10",
  ];
  if (props.className) classes.push(props.className);

  return (
    <div
      style={{
        height: 64 + 200,
        marginTop: -200,
        paddingTop: 200,
      }}
      className={classes.join(" ")}
    >
      {history.length > 0 && !props.hideBackButton ? (
        <ChevronLeftIcon
          className={"absolute left-1 cursor-pointer h-6 w-6"}
          onClick={props.goBack ? props.goBack : history.goBack}
        />
      ) : // <LeftOutlined
      // onClick={props.goBack ? props.goBack : history.goBack}
      // className={[styles.goBack, "text-gray-700"].join(" ")}/>
      null}
      {ristopub ? (
        <p className={[styles.ristopubName, "text-gray-900"].join(" ")}>
          {ristopub.name}
        </p>
      ) : (
        <p className={[styles.ristopubName, "text-gray-900"].join(" ")}>
          Swippy
        </p>
      )}
      {/*<CurrentScreen/>*/}

      <ChangedOrdersStatusBadge
        className={[styles.menu, "text-gray-700"].join(" ")}
      >
        <MenuIcon className={"cursor-pointer"} onClick={props.onMenuClicked} />
        {/*<MenuOutlined className={"text-gray-700"} onClick={props.onMenuClicked} />*/}
      </ChangedOrdersStatusBadge>
    </div>
  );
}
