import { CartItem } from "../../Cart/classes/CartItem";
import { Variation } from "./Variation";
import { VariationOption } from "./VariationOption";
import _ from "lodash";

export class CartItemToVariationDataMapper {
  public toVariation(ci: CartItem) {
    const groupedModifiers = _.groupBy(ci.getOptions(), (o) => o.modifierId);

    const options: VariationOption[] = [];

    _.forEach(groupedModifiers, (m) => {
      const first = m[0];
      if (first.modifierName === "Salsa") console.log(m);
      if (!first) return;
      options.push(
        new VariationOption(
          first.modifierId,
          first.modifierName,
          m.map((o) => ({
            choiceId: o.choiceId,
            choiceName: o.choiceName,
            choiceQty: o.choiceQty,
          })),
        ),
      );
    });

    return new Variation(options, ci.getProduct(), ci.getQty(), ci.id);
  }
}
