import React from "react";
import { DeliveryMenuComponent } from "./DeliveryMenuComponent";
import { useToDeliveryInsertAddressScreenWithQrCode } from "./useToDeliveryInsertAddressScreenWithQrCode";
import { useToDeliveryChooseTimeScreenWithQrCode } from "./useToDeliveryChooseTimeScreenWithQrCode";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useQrCodeId } from "../QrCode/useQrCodeId";

export function DeliveryMenuScreenWithQrCode() {
  const { toDeliveryInsertAddressScreenWithQrCode } =
    useToDeliveryInsertAddressScreenWithQrCode();

  const { toDeliveryChooseTimeScreenWithQrCode } =
    useToDeliveryChooseTimeScreenWithQrCode();

  const { ristopubId } = useCurrentRistopubId();

  const { qrcodeId } = useQrCodeId();

  function toInsertAddress() {
    toDeliveryInsertAddressScreenWithQrCode(ristopubId, qrcodeId);
  }

  function toChooseTime() {
    toDeliveryChooseTimeScreenWithQrCode(ristopubId, qrcodeId);
  }

  return (
    <DeliveryMenuComponent
      onBack={toInsertAddress}
      toChooseTime={toChooseTime}
    />
  );
}
