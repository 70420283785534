import { Product } from "../../RistopubMenu/Product/Product";
import { OrderItemVariation } from "./OrderItemVariation";
import _ from "lodash";

type CreateOrderItemInput = {
  id: string;
  product: Product;
  qty: number;
  variations?: OrderItemVariation[];
};

export class OrderItem {
  private constructor(
    public id: string,
    public product: Product,
    public qty: number,
    public readonly variations: OrderItemVariation[] = [],
  ) {}

  public static create({ id, product, qty, variations }: CreateOrderItemInput) {
    return new OrderItem(id, product, qty, variations);
  }

  private getTotalOptionsPrice(): number {
    return _.reduce(
      this.variations,
      (sum, v) => {
        return sum + v.getTotalPrice();
      },
      0,
    );
  }

  public getUnitPrice(): number {
    const price = this.product.price ?? 0;
    return price + this.getTotalOptionsPrice();
  }

  public getTotal() {
    return this.getUnitPrice() * this.qty;
  }
}
