import { OrderButton } from "../OrderButton";
import React from "react";
import { useCurrentCart } from "../../Cart/useCurrentCart";
import { GaProps } from "../../safeGtag/GaProps";
import styles from "./NextStepButton.module.scss";

type PropTypes = {
  disabled?: boolean;
  label: string;
  onClick?: () => void;
  gaProps?: GaProps;
  loading?: boolean;
};

export function NextStepButton(props: PropTypes) {
  const { getTotalItemQty, getTotalItemPrice, getDeliveryCost } =
    useCurrentCart();

  const gaProps: GaProps = props.gaProps || {};

  return (
    <div className={styles.wrapper}>
      <OrderButton
        shippingCost={getDeliveryCost()}
        loading={props.loading}
        gaCategory={gaProps.gaCategory}
        gaLabel={gaProps.gaLabel}
        gaAction={gaProps.gaAction || "click"}
        qty={getTotalItemQty()}
        price={getTotalItemPrice()}
        onClick={props.onClick}
        disabled={props.disabled}
        label={props.label}
      />
    </div>
  );
}
