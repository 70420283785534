import React from "react";
import { useToDeliveryOrderSummaryScreen } from "./useToDeliveryOrderSummaryScreen";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useToDeliveryMenuScreen } from "./useToDeliveryMenuScreen";
import { DeliveryChooseTimeComponent } from "./DeliveryChooseTimeComponent";

export function ChooseDeliveryTimeScreen() {
  const { ristopubId } = useCurrentRistopubId();

  const { toDeliveryOrderSummaryScreen } = useToDeliveryOrderSummaryScreen();

  const { toDeliveryMenu } = useToDeliveryMenuScreen();

  function toOrderSummary() {
    toDeliveryOrderSummaryScreen(ristopubId);
  }

  function toMenu() {
    toDeliveryMenu(ristopubId);
  }

  return (
    <DeliveryChooseTimeComponent
      toOrderSummary={toOrderSummary}
      onBack={toMenu}
    />
  );
}
