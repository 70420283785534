import React from "react";
import { MainLayout } from "../../MainLayout/MainLayout";
import { OrdersList } from "./OrdersList";

export function OrdersScreen() {
  return (
    <MainLayout screenTitle={"I tuoi ordini"} withHeader={true}>
      <OrdersList />
    </MainLayout>
  );
}
