import { useHistory } from "react-router-dom";

export function useToChooseDeliveryTimeScreen() {
  const history = useHistory();

  function toChooseDeliveryTimeScreen(
    ristopubId: string,
    replace: boolean = false,
  ) {
    if (replace) {
      history.replace(`/ristopub/${ristopubId}/delivery/choosetime`);
    } else {
      history.push(`/ristopub/${ristopubId}/delivery/choosetime`);
    }
  }

  return {
    toChooseDeliveryTimeScreen,
  };
}
