import shortid from "shortid";
import { useAuthCtx } from "../Auth/useAuthCtx";

const GUEST_USER_ID_KEY = "guest_user_id";

export function useGuestUserId() {
  const { user } = useAuthCtx();
  if (user) return { guestUserId: user.id };
  let guestUserId = window.localStorage.getItem(GUEST_USER_ID_KEY) || undefined;
  if (!guestUserId) {
    guestUserId = shortid.generate();
    window.localStorage.setItem(GUEST_USER_ID_KEY, guestUserId);
  }

  return {
    guestUserId,
  };
}
