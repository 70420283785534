import { gql, useApolloClient } from "@apollo/client";
import { Ristopub } from "../Ristopub/Ristopub";
import { Address } from "../Cart/classes/Address/Address";

const RISTOPUB_CAN_DELIVER_TO_ADDRESS_QUERY = gql`
  query ristopubCanDeliverToAddress($input: RistopubCanDeliverToAddressInput!) {
    ristopubCanDeliverToAddress(input: $input) {
      canDeliver
    }
  }
`;

export function useRistopubCanDeliverToAddress() {
  const client = useApolloClient();

  async function ristopubCanDeliverToAddress(
    ristopub: Ristopub,
    address: Address,
    orderTotal: number,
  ) {
    const { data } = await client.query<{
      ristopubCanDeliverToAddress: {
        canDeliver: boolean;
        deliveryCost: number;
      };
    }>({
      fetchPolicy: "network-only",
      query: RISTOPUB_CAN_DELIVER_TO_ADDRESS_QUERY,
      variables: {
        input: {
          orderTotal,
          ristopubId: ristopub.id,
          address: {
            address: address.getStreetWithStreetNumber(),
            city: address.city,
            country: address.country,
            postalCode: address.postalCode,
            province: address.province,
          },
        },
      },
    });

    return {
      canDeliver: data.ristopubCanDeliverToAddress.canDeliver,
    };
  }

  return {
    ristopubCanDeliverToAddress,
  };
}
