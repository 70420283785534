import { QrCodeType } from "./QrCodeType";

export class QrCode {
  constructor(
    public readonly id: string,
    public readonly ristopubId: string,
    public readonly name: string,
    public readonly enableDeliveryToLocation: boolean,
    public readonly enablePickup: boolean,
    public readonly type: QrCodeType,
    public enableDelivery?: boolean,
    public enableTakeaway?: boolean,
  ) {}
}
