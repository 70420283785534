import ReactDOM from "react-dom";
import React, { useEffect, useRef, useState } from "react";
import { CloseIcon } from "./CloseIcon";

type Props = {
  copy: string;
  trigger?: boolean;
  timeoutInSeconds: number;
};

export function WarnToast(props: Props) {
  const [visible, setVisible] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);

  const timeout = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (isFirstTime) {
      setIsFirstTime(false);
      return;
    }
    setVisible(true);
    timeout.current = setTimeout(() => {
      setVisible(false);
      timeout.current = undefined;
    }, props.timeoutInSeconds * 1000);
  }, [props.trigger]);

  return visible
    ? ReactDOM.createPortal(
        <div
          className={
            "fixed flex items-center justify-between z-40 top-2 left-2 bg-blue-500 rounded-md max-w-md ml-auto mr-auto right-2 p-2"
          }
        >
          <p
            className={"m-0 text-white font-bold"}
            dangerouslySetInnerHTML={{
              __html: props.copy,
            }}
          />
          <CloseIcon
            onClick={() => {
              if (timeout.current) {
                clearTimeout(timeout.current);
                timeout.current = undefined;
              }
              setVisible(false);
            }}
            className={"h-6 w-6 ml-2 text-white cursor-pointer"}
          />
        </div>,
        document.body,
      )
    : null;
}
