import { useHistory } from "react-router-dom";

export function useToChooseShippingMethodWithQrCode() {
  const history = useHistory();

  function toChooseShippingMethodWithQrCode(
    ristopubId: string,
    qrCodeId: string,
  ) {
    history.push(
      `/ristopub/${ristopubId}/qrcode/${qrCodeId}/chooseshippingmethod`,
    );
  }

  return {
    toChooseShippingMethodWithQrCode,
  };
}
