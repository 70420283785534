import React from "react";

type PropTypes = {
  minimumOrderAmount: number;
};

export function MinimumOrderAdvice(props: PropTypes) {
  return (
    <p
      className={"rounded font-bold text-red-500 text-sm ml-auto mr-auto mb-0"}
    >
      L'ordine minimo è {props.minimumOrderAmount} €
    </p>
  );
}
