import { OrderItemVariationDTO } from "./OrderItemDTO";
import { OrderItemVariation } from "./OrderItemVariation";
import { OrderItemVariationChoice } from "./OrderItemVariationChoice";

export class OrderItemVariationDataMapper {
  toEntity(d: OrderItemVariationDTO): OrderItemVariation {
    return new OrderItemVariation(
      d.modifierName,
      d.choices.map(
        (c) =>
          new OrderItemVariationChoice(
            c.choiceName,
            c.choicePrice,
            c.choiceQty,
          ),
      ),
    );
  }
}
