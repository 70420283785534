import { useHistory } from "react-router-dom";
import { useQueryParams } from "../useQueryParams/useQueryParams";
import { preserveInPlaceQueryParamIfSet } from "../RistopubMenu/preserveInPlaceQueryParamIfSet";

export function useToTakeawayMenuScreen() {
  const history = useHistory();
  const query = useQueryParams();

  function toTakeawayMenu(ristopubId?: string) {
    if (!ristopubId) throw new Error("Missing ristopubId");
    history.replace(
      preserveInPlaceQueryParamIfSet(
        `/ristopub/${ristopubId}/takeaway/menu`,
        query,
      ),
    );
  }

  return {
    toTakeawayMenu,
  };
}
