type CreateAddressInput = {
  address?: string;
  province?: string;
  city?: string;
  country?: string;
  postalCode?: string;
  streetNumber?: string;
};

export class Address {
  private constructor(
    public address?: string,
    public province?: string,
    public city?: string,
    public country?: string,
    public postalCode?: string,
    public streetNumber?: string,
  ) {}

  public isEmpty() {
    return this.toString().trim().length <= 0;
  }

  public getStreetWithStreetNumber() {
    if (!this.streetNumber) return this.address;
    return `${this.address} ${this.streetNumber || ""}`.trim();
  }

  public toString() {
    const addressComponents: string[] = [];
    if (this.hasAddress()) addressComponents.push(this.address as string);
    if (this.hasStreetNumber())
      addressComponents.push(this.streetNumber as string);
    if (this.hasCity()) addressComponents.push(this.city as string);
    if (this.hasPostalCode()) addressComponents.push(this.postalCode as string);
    if (this.hasProvince()) addressComponents.push(this.province as string);
    return addressComponents.join(", ").trim();
  }

  public static create({
    address,
    province,
    city,
    country,
    postalCode,
    streetNumber,
  }: CreateAddressInput) {
    return new Address(
      address,
      province,
      city,
      country,
      postalCode,
      streetNumber,
    );
  }

  public hasCity() {
    return !!this.city;
  }

  public hasProvince() {
    return !!this.province;
  }

  public hasCountry() {
    return !!this.country;
  }

  public hasPostalCode() {
    return !!this.postalCode;
  }

  public hasAddress() {
    return !!this.address;
  }
  public hasStreetNumber() {
    return !!this.streetNumber;
  }

  public isCompleteAddress() {
    return (
      this.hasPostalCode() &&
      this.hasAddress() &&
      this.hasCity() &&
      this.hasProvince() &&
      this.hasCountry() &&
      this.hasStreetNumber()
    );
  }
}
