import { IApolloCredentials } from "./IApolloCredentials";
import { ApolloLink } from "@apollo/client";

export class AnonymousCredentials implements IApolloCredentials {
  getHash(): string {
    return "anonymous-credentials";
  }

  injectAuth(link: ApolloLink): ApolloLink {
    return link;
  }
}
