import React from "react";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { TakeawayChooseTimeComponent } from "./TakeawayChooseTimeComponent";
import { useQrCodeId } from "../QrCode/useQrCodeId";
import { useToTakeawayMenuScreenWithQrCode } from "./useToTakeawayMenuScreenWithQrCode";
import { useToTakeawaySummaryScreenWithQrCode } from "./useToTakeawaySummaryScreenWithQrCode";

export function TakeawayChooseTimeScreenWithQrCode() {
  const { ristopubId } = useCurrentRistopubId();
  const { qrcodeId } = useQrCodeId();

  const { toTakeawayMenuScreenWithQrCode } =
    useToTakeawayMenuScreenWithQrCode();

  const { toTakeawaySummaryScreenWithQrCode } =
    useToTakeawaySummaryScreenWithQrCode();

  function toMenu() {
    toTakeawayMenuScreenWithQrCode(ristopubId, qrcodeId);
  }

  function toSummary() {
    toTakeawaySummaryScreenWithQrCode(ristopubId, qrcodeId);
  }

  return <TakeawayChooseTimeComponent onBack={toMenu} toSummary={toSummary} />;
}
