import { Last10DaysCtx } from "./Last10DaysOrdersCtx";
import { useLast10DaysOrders } from "../useLast10DaysOrders";
import React from "react";
import { useGuestUpdateListener } from "../ChangedOrdersStatusBadge/useGuestUpdateListener";

type PropTypes = {
  children?: React.ReactNode | null;
};

export function Last10DaysOrdersCtxProvider(props: PropTypes) {
  const {
    orders,
    loading,
    refetch: apolloRefetchLast10DaysOrders,
  } = useLast10DaysOrders({
    pollInterval: 60 * 1000,
  });

  async function refetchLast10DaysOrders() {
    await apolloRefetchLast10DaysOrders();
  }

  useGuestUpdateListener(refetchLast10DaysOrders);

  async function refetch() {
    await apolloRefetchLast10DaysOrders();
  }

  return (
    <Last10DaysCtx.Provider
      value={{
        orders,
        loading,
        refetch,
      }}
    >
      {props.children || null}
    </Last10DaysCtx.Provider>
  );
}
