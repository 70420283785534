import { gql, useMutation } from "@apollo/client";
import { VirtualTableDTO } from "../Order/VirtualTableDTO";
import { AddOrderItemInput } from "../Order/AddOrderItemInput";

const CREATE_TAKEAWAY_STRIPE_PAYMENT_INTENT_MUTATION = gql`
  mutation createTakeawayStripePaymentIntent(
    $input: CreateTakeawayStripePaymentIntentInput!
  ) {
    createTakeawayStripePaymentIntent(input: $input) {
      clientSecret
      virtualTable {
        id
      }
    }
  }
`;

type CreateTakeawayPaymentIntentResponse = {
  clientSecret: string;
  virtualTable: VirtualTableDTO;
};

export function useCreateTakeawayStripePaymentIntent() {
  const [createTakeawayStripePaymentIntentMutation, { loading }] = useMutation<
    {
      createTakeawayStripePaymentIntent: CreateTakeawayPaymentIntentResponse;
    },
    {
      input: {
        cartId: string;
        customerEmail: string;
        customerName?: string;
        customerNotes?: string;
        customerPhone: string;
        dueDate: Date;
        guestUserId: string;
        isInPlace?: boolean;
        items: AddOrderItemInput[];
        ristopubId: string;
        qrCodeId?: string;
      };
    }
  >(CREATE_TAKEAWAY_STRIPE_PAYMENT_INTENT_MUTATION);

  return {
    createTakeawayStripePaymentIntentMutation,
    creatingTakeawayStripePaymentIntent: loading,
  };
}
