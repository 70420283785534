import { loadStripe } from "@stripe/stripe-js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

function createStripePromise(stripeAccountId: string) {
  return loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string, {
    stripeAccount: stripeAccountId,
    locale: "it",
  });
}

export { createStripePromise };
