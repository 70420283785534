import { useCurrentCart } from "../Cart/useCurrentCart";
import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { useCreateDeliveryPaymentIntent } from "./useCreateDeliveryPaymentIntent";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useGetSavedStripePaymentMethodId } from "../StripeCheckoutForm/useGetSavedStripePaymentMethodId";
import { useToOrderDetail } from "../Takeaway/useToOrderDetail";
import React, { useMemo, useRef, useState } from "react";
import { useGuestUserId } from "../OrderSummaryScreen/useGuestUserId";
import { useCompleteDeliveryOrderWithCashPaymentFlow } from "./useCompleteDeliveryOrderWithCashPaymentFlow";
import { ErrorState } from "../ErrorState";
import { GenericErrorHandler } from "../GenericErrorHandler/GenericErrorHandler";
import _ from "lodash";
import { MainLayout } from "../MainLayout/MainLayout";
import { ListLoading } from "../Loading/ListLoading";
import { CantOrderForPastDeliveryDateModal } from "../Order/CantOrderForPastDeliveryDateModal";
import { InsertEmail } from "../Order/InsertEmail";
import { InsertPhone } from "../Order/InsertPhone";
import { Elements } from "@stripe/react-stripe-js";
import { createStripePromise } from "../stripePromise";
import { PaymentMethodSection } from "../StripeCheckoutForm/PaymentMethodSection";

type Props = {};

export function DeliveryCheckoutComponent(props: Props) {
  const {
    getDeliveryAddress,
    getSelectedDeliveryDate,
    getCustomerName,
    getCustomerPhone,
    getCustomerNotes,
    getItems,
    getCartId,
    getCustomerEmail,
    isValidCustomerEmail,
    isValidCustomerPhone,
  } = useCurrentCart();
  const { ristopub } = useCurrentRistopub();

  const { createDeliveryPaymentIntent } = useCreateDeliveryPaymentIntent();

  const stripeAccId = ristopub?.ownerStripeAccountId;

  const stripePromise = useMemo(() => {
    if (!stripeAccId) return null;
    return createStripePromise(stripeAccId);
  }, [stripeAccId]);

  const { ristopubId } = useCurrentRistopubId();

  const {
    savedStripePaymentMethodId,
    loadingSavedPaymentMethodId,
    last4digits,
  } = useGetSavedStripePaymentMethodId();
  const { toOrderDetail } = useToOrderDetail();
  const [showPastDateModal, setShowPastDateModal] = useState(false);
  const { guestUserId } = useGuestUserId();
  const {
    completeDeliveryOrderWithCashFlow,
    loading: completingOrderWithCash,
  } = useCompleteDeliveryOrderWithCashPaymentFlow();

  const [errorState, setErrorState] = useState<ErrorState>(new ErrorState());

  const emailRef = useRef<HTMLDivElement>(null);
  const phoneRef = useRef<HTMLDivElement>(null);

  function onEmailValidationFailed() {
    if (!emailRef.current) return console.error("Missing emailRef");

    const newErrorState = errorState.clone();
    newErrorState.enableError("email");
    setErrorState(newErrorState);
    emailRef.current.scrollIntoView();
  }

  function onMobileValidationFailed() {
    if (!phoneRef.current) return console.error("Missing phoneRef");

    const newErrorState = errorState.clone();
    newErrorState.enableError("mobile");
    setErrorState(newErrorState);
    phoneRef.current.scrollIntoView();
  }

  function displayErrorToGui(e: Error) {
    const errorHandler = new GenericErrorHandler();
    errorHandler.handleError(e);
  }

  async function createPaymentIntent() {
    try {
      if (!ristopub) {
        displayErrorToGui(new Error("Missing ristopub"));
        return undefined;
      }
      const address = getDeliveryAddress();

      const res = await createDeliveryPaymentIntent({
        ristopubId,
        guestUserId,
        items: _.map(getItems(), (ci) => {
          return {
            qty: ci.getQty(),
            productId: ci.getProduct().id,
            modifiers: ci.getOptionsGroupedByModifier().map((o) => {
              return {
                modifierId: o.modifierId,
                choices: o.options.map((o) => {
                  return {
                    id: o.choiceId,
                    qty: o.choiceQty,
                  };
                }),
              };
            }),
          };
        }),
        deliveryDate: getSelectedDeliveryDate().toDate(),
        deliveryAddress: {
          address: address.getStreetWithStreetNumber() as string,
          postalCode: address.postalCode as string,
          city: address.city as string,
          province: address.province as string,
          country: address.country as string,
        },
        customerEmail: getCustomerEmail() as string,
        customerPhone: getCustomerPhone() as string,
        customerName: getCustomerName() as string,
        customerNotes: getCustomerNotes(),
        cartId: getCartId() as string,
      });

      if (!res.virtualTable || !res.clientSecret) return undefined;

      return {
        clientSecret: res.clientSecret,
        virtualTableId: res.virtualTable.id,
      };
    } catch (e) {
      displayErrorToGui(e as Error);
      throw e;
    }
  }

  function redirectPostPayment() {
    const cartId = getCartId();
    if (!cartId || !ristopub) throw new Error("Missing cart id or ristopub");
    toOrderDetail(cartId, ristopubId, true);
  }

  if (loadingSavedPaymentMethodId)
    return (
      <MainLayout withHeader={true}>
        <ListLoading />
      </MainLayout>
    );

  function openPastDateModalIfNeeded() {
    if (ristopub?.isBefore(getSelectedDeliveryDate())) {
      setShowPastDateModal(true);
      return true;
    } else {
      return false;
    }
  }
  async function completeOrder() {
    if (openPastDateModalIfNeeded()) return;
    await completeDeliveryOrderWithCashFlow();
  }
  function clearErrorIfNeeded(key: string) {
    if (errorState.hasError(key)) {
      const newErrorState = errorState.clone();
      newErrorState.clearError(key);
      setErrorState(newErrorState);
    }
  }

  const buttonDisabled = !isValidCustomerPhone() || !isValidCustomerEmail();

  return (
    <MainLayout screenTitle={"Completa il pagamento"} withHeader={true}>
      <CantOrderForPastDeliveryDateModal
        visible={showPastDateModal}
        onClose={() => setShowPastDateModal(false)}
      />

      <div
        className={["bg-white rounded-t-lg p-4 flex flex-1 flex-col"].join(" ")}
      >
        <>
          <InsertEmail
            onType={() => {
              clearErrorIfNeeded("email");
            }}
            hasError={errorState.hasError("email")}
            ref={emailRef}
            className={"mb-6"}
          />
          <InsertPhone
            hasError={errorState.hasError("mobile")}
            onType={() => {
              clearErrorIfNeeded("mobile");
            }}
            ref={phoneRef}
            label={
              "Ti contatteremo in caso di comunicazioni riguardanti il tuo ordine"
            }
            className={"mb-6"}
          />
          {ristopub ? (
            <Elements stripe={stripePromise}>
              <PaymentMethodSection
                savedStripePaymentMethodLast4={last4digits}
                payWithCashButtonDisabled={buttonDisabled}
                payButtonDisabled={buttonDisabled}
                payWithStripeGaProps={{
                  gaCategory: "delivery_order_flow",
                  gaLabel: "delivery_order_flow_pay",
                }}
                completeOrderWithCash={completeOrder}
                cashLoading={completingOrderWithCash}
                payWithCashGaProps={{
                  gaCategory: "delivery_order_flow",
                  gaLabel: "delivery_order_flow_checkout_complete_order",
                }}
                savedStripePaymentMethodId={savedStripePaymentMethodId}
                ristopub={ristopub}
                redirectPostPayment={redirectPostPayment}
                getStripePaymentIntentClientSecret={createPaymentIntent}
                emailRequired={true}
                mobileRequired={true}
                onMobileValidationFailed={onMobileValidationFailed}
                onEmailValidationFailed={onEmailValidationFailed}
              />
            </Elements>
          ) : null}
        </>
      </div>
    </MainLayout>
  );
}
