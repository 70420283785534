import { CartDataMapper } from "./classes/CartDataMapper";
import { CartItemDataMapper } from "./classes/CartItemDataMapper";
import { ProductDataMapper } from "../RistopubMenu/Product/ProductDataMapper";
import { CartDTO } from "./classes/CartDTO";
import { Cart } from "./classes/Cart";
import { AddressDTO } from "./classes/Address/AddressDTO";
import _ from "lodash";
import { LocalStoragePhoneSanitizer } from "./LocalStoragePhoneSanitizer";
import { GuestUserDTO } from "../Auth/GuestUserDTO";

const DEFAULT_SCOPE = "default";

function getLocalStorageKey(scope: string) {
  return `cart-${scope}`;
}

function safelyLoadFromLocalStorage<T>(key: string): T | undefined {
  try {
    return JSON.parse(window.localStorage.getItem(key) as string);
  } catch (e) {
    return undefined;
  }
}

function storeInLocalStorage<T>(key: string, data: T) {
  window.localStorage.setItem(key, JSON.stringify(data));
}

function completeCartAddressFromSavedAddress(cart: Cart) {
  const savedAddress = safelyLoadFromLocalStorage<AddressDTO>("address") || {};

  if (!cart.address.hasStreetNumber() && savedAddress.streetNumber)
    cart.address.streetNumber = savedAddress.streetNumber;
  if (!cart.address.hasAddress() && savedAddress.address)
    cart.address.address = savedAddress.address;
  if (!cart.address.hasCity() && savedAddress.city)
    cart.address.city = savedAddress.city;
  if (!cart.address.hasProvince() && savedAddress.province)
    cart.address.province = savedAddress.province;
  if (!cart.address.hasPostalCode() && savedAddress.postalCode)
    cart.address.postalCode = savedAddress.postalCode;
  if (!cart.address.hasCountry() && savedAddress.country)
    cart.address.country = savedAddress.country;

  return _.cloneDeep(cart);
}

export function loadCartFromLocalStorage(
  scope: string = DEFAULT_SCOPE,
  user?: GuestUserDTO,
): Cart {
  const cartDataMapper = new CartDataMapper(
    new CartItemDataMapper(new ProductDataMapper()),
  );
  const key = getLocalStorageKey(scope);
  const data = safelyLoadFromLocalStorage<CartDTO>(key);

  const savedName = window.localStorage.getItem("name") || undefined;
  const savedPhone =
    window.localStorage.getItem("phone") ?? user?.phoneNumber?.number;
  const savedEmail = window.localStorage.getItem("email") || undefined;
  const sanitizer = new LocalStoragePhoneSanitizer();
  if (!data) {
    const cart = cartDataMapper.toEntity({
      items: {},
      customerName: savedName,
      customerPhone: savedPhone ? sanitizer.sanitize(savedPhone) : undefined,
      customerEmail: savedEmail,
    });

    return completeCartAddressFromSavedAddress(cart);
  } else {
    if (!data.customerName) data.customerName = savedName;
    if (!data.customerPhone) data.customerPhone = savedPhone;
    if (!data.customerEmail) data.customerEmail = savedEmail;

    const cart = cartDataMapper.toEntity(data);

    const cPhone = cart.getCustomerPhone();
    if (cPhone && cPhone.length) {
      cart.setCustomerPhone(sanitizer.sanitize(cPhone));
    }

    return completeCartAddressFromSavedAddress(cart);
  }
}

export function removeCurrentCartFromLocalStorage(
  scope: string = DEFAULT_SCOPE,
) {
  const key = getLocalStorageKey(scope);
  window.localStorage.removeItem(key);
}

export function saveCartToLocalStorage(
  cart: Cart,
  scope: string = DEFAULT_SCOPE,
) {
  const key = getLocalStorageKey(scope);
  storeInLocalStorage<Cart>(key, cart);
}
