import { useCurrentCart } from "../Cart/useCurrentCart";
import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useToInStructureOrderDetailScreen } from "./useToInStructureOrderDetailScreen";
import { useGuestUserId } from "../OrderSummaryScreen/useGuestUserId";
import { GenericErrorHandler } from "../GenericErrorHandler/GenericErrorHandler";
import _ from "lodash";
import { CartItem } from "../Cart/classes/CartItem";
import { VirtualTableDTO } from "../Order/VirtualTableDTO";
import { AddOrderItemInput } from "../Order/AddOrderItemInput";

const COMPLETE_INSTRUCTURE_ORDER_MUTATION = gql`
  mutation addInStructureOrder($input: AddInStructureOrderInput!) {
    addInStructureOrder(input: $input) {
      name
      __typename
      id
    }
  }
`;

export function useCompleteInStructureOrderWithCashPaymentFlow() {
  const {
    markCurrentCartAsOrdered,
    getCustomerPhone,
    getCustomerNotes,
    getItems,
    getCartId,
  } = useCurrentCart();
  const { ristopub } = useCurrentRistopub();
  const [completingOrder, setCompletingOrder] = useState(false);
  const [completeTakeAwayOrderMutation] = useMutation<
    {
      addInStructureOrder: VirtualTableDTO;
    },
    {
      input: {
        cartId: string;
        customerNotes?: string;
        customerPhone?: string;
        guestUserId: string;
        items: AddOrderItemInput[];
        ristopubId: string;
        selfPickup?: boolean;
        tableName: string;
      };
    }
  >(COMPLETE_INSTRUCTURE_ORDER_MUTATION);

  const { tableName } = useParams<{
    tableName: string;
  }>();

  const { toInStructureOrderDetailScreen } =
    useToInStructureOrderDetailScreen();

  const { guestUserId } = useGuestUserId();

  function displayErrorToGui(e: Error) {
    const errorHandler = new GenericErrorHandler();
    errorHandler.handleError(e);
  }

  async function completeInStructureOrderWithCashPaymentFlow(
    selfPickup?: boolean,
  ) {
    try {
      setCompletingOrder(true);
      if (!ristopub) return displayErrorToGui(new Error("Missing ristopub"));
      const res = await completeTakeAwayOrderMutation({
        variables: {
          input: {
            selfPickup,
            tableName,
            items: _.map(getItems(), (i: CartItem) => {
              return {
                qty: i.getQty(),
                productId: i.getProduct().id,
                modifiers: i.getOptionsGroupedByModifier().map((o) => {
                  return {
                    modifierId: o.modifierId,
                    choices: o.options.map((o) => {
                      return {
                        id: o.choiceId,
                        qty: o.choiceQty,
                      };
                    }),
                  };
                }),
              };
            }),
            ristopubId: ristopub.id,
            customerNotes: getCustomerNotes(),
            cartId: getCartId() as string,
            customerPhone: getCustomerPhone(),
            guestUserId,
          },
        },
      });

      const virtualTableId: string | undefined = _.get(
        res.data,
        "addInStructureOrder.id",
      );
      if (!virtualTableId)
        return displayErrorToGui(new Error("Oooops. Qualcosa è andato storto"));
      const cartId = getCartId();
      if (!cartId) return displayErrorToGui(new Error("Missing cart id"));
      markCurrentCartAsOrdered(virtualTableId);
      toInStructureOrderDetailScreen(
        cartId,
        ristopub.id as string,
        tableName,
        true,
      );
    } catch (e) {
      displayErrorToGui(e as Error);
      throw e;
    } finally {
      setCompletingOrder(false);
    }
  }

  return {
    completeInStructureOrderWithCashPaymentFlow,
    completingOrder,
  };
}
