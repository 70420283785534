import React, { PropsWithChildren, useEffect, useRef } from "react";
import { useCurrentRistopub } from "./useCurrentRistopub";

type PropTypes = PropsWithChildren<{
  disabled?: boolean;
  offsetTop?: number;
}>;
export function RistopubTopImage(props: PropTypes) {
  const { ristopub } = useCurrentRistopub();

  // const {openedOrders} = useOpenedOrders()
  const imgContainerRef = useRef<HTMLDivElement>(null);

  //const topWhiteBoxRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handler() {
      if (!imgContainerRef.current /*|| !topWhiteBoxRef.current*/) return;
      const imgBg = imgContainerRef.current.firstChild as HTMLDivElement;

      if (document.body.scrollTop >= 0) {
        imgBg.style.transform = "none";

        return;
      }

      imgBg.style.transform = `scale(${1 - document.body.scrollTop / 150})`;
    }
    document.body.addEventListener("scroll", handler, true);

    return () => {
      document.body.removeEventListener("scroll", handler, true);
    };
  }, []);
  if (!ristopub?.featuredImageSrc || props.disabled)
    return <>{props.children || null}</>;

  const topNegativeOffset = -64 - (props.offsetTop ?? 0);

  const imgBaseStyle = {
    backgroundImage: `url("${ristopub.featuredImageSrc}")`,
    height: 250,
    marginTop: topNegativeOffset,
  };

  const ImgComponent = (
    <div
      style={imgBaseStyle}
      className={
        "bg-no-repeat max-w-md ml-auto mr-auto bg-center bg-cover w-full pointer-events-none"
      }
    />
  );

  return (
    <div className={"flex-1 flex flex-col "}>
      <div ref={imgContainerRef}>
        {React.cloneElement(ImgComponent, {
          style: {
            ...imgBaseStyle,
            position: "absolute",
            height: 270,
          },
        })}
      </div>
      {React.cloneElement(ImgComponent, {
        style: {
          ...imgBaseStyle,
          visibility: "hidden",
        },
      })}
      <div className={"-mt-2 z-10 relative flex-1 flex flex-col"}>
        {props.children || null}
      </div>
    </div>
  );
}
