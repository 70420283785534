import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { useCurrentCart } from "../Cart/useCurrentCart";
import { MainLayout } from "../MainLayout/MainLayout";
import { EmptyCart } from "../OrderSummaryScreen/EmptyCart";
import { OrderSummary } from "../OrderSummaryScreen/OrderSummary";
import { AddOrderNotes } from "../OrderSummaryScreen/AddOrderNotes";
import { OrderMetaDetails } from "../Order/OrderDetailScreen/OrderMetaDetails";
import React, { useState } from "react";
import { useToChooseDeliveryTimeScreen } from "./useToChooseDeliveryTimeScreen";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { NextStepButton } from "../OrderButton/NextStepButton/NextStepButton";
import { useToDeliveryCheckoutScreen } from "./useToDeliveryCheckoutScreen";
import { CantOrderForPastDeliveryDateModal } from "../Order/CantOrderForPastDeliveryDateModal";
import { MinimumOrderAdvice } from "../Order/MinimumOrderAdvice";
import { DeliveryFreeShippingAdvice } from "./DeliveryFreeShippingAdvice";
import { useToDeliveryMenuScreen } from "./useToDeliveryMenuScreen";
import { DeliveryOrderSummaryComponent } from "./DeliveryOrderSummaryComponent";
import { useToDeliveryOrderSummaryScreen } from "./useToDeliveryOrderSummaryScreen";

export function DeliveryOrderSummaryScreen() {
  const { ristopubId } = useCurrentRistopubId();

  const { toDeliveryCheckoutScreen } = useToDeliveryCheckoutScreen();

  const { toDeliveryOrderSummaryScreen } = useToDeliveryOrderSummaryScreen();

  const { toDeliveryMenu } = useToDeliveryMenuScreen();

  function toCheckoutScreen() {
    toDeliveryCheckoutScreen(ristopubId);
  }

  function toSummary() {
    toDeliveryOrderSummaryScreen(ristopubId);
  }

  function toMenu() {
    toDeliveryMenu(ristopubId);
  }

  return (
    <DeliveryOrderSummaryComponent
      toCheckoutScreen={toCheckoutScreen}
      onBack={toSummary}
      toMenuScreen={toMenu}
    />
  );
}
