import styles from "./SwippyTextarea.module.scss";
import { Input } from "antd";
import React from "react";
import { TextAreaProps } from "antd/lib/input/TextArea";

type PropTypes = {} & TextAreaProps;

const SwippyTextarea = React.forwardRef<Input, PropTypes>((props, ref) => {
  const classes = [
    styles.swippyTextarea,
    "border-2 border-gray-300 rounded hover:border-swippy-orange focus:border-swippy-orange",
  ];
  if (props.className) classes.push(props.className);
  return <Input.TextArea {...props} className={classes.join(" ")} />;
});

export { SwippyTextarea };
