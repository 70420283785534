import { ListLoading } from "../../Loading/ListLoading";
import React, { useEffect } from "react";
import { OrdersListItem } from "./OrdersListItem";

import _ from "lodash";
import { useLast10DaysOrdersCtx } from "../Last10DaysOrdersCtx/useLast10DaysOrdersCtx";
import { useOrderLastSeen } from "../OrderLastSeenCtx/useOrderLastSeen";

export function OrdersList() {
  const { orders, loading } = useLast10DaysOrdersCtx();
  const { updateLastSeenOf } = useOrderLastSeen();
  useEffect(() => {
    _.forEach(orders, (o) => updateLastSeenOf(o));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  if (loading && orders.length <= 0) return <ListLoading />;

  return (
    <div>
      {_.map(orders, (o) => (
        <OrdersListItem key={o.id} order={o} />
      ))}
    </div>
  );
}
