import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { useCurrentCart } from "../Cart/useCurrentCart";
import React, { useState } from "react";
import { MainLayout } from "../MainLayout/MainLayout";
import { EmptyCart } from "../OrderSummaryScreen/EmptyCart";
import { CantOrderForPastTakeawayDateModal } from "../Order/CantOrderForPastTakeawayDateModal";
import { OrderSummary } from "../OrderSummaryScreen/OrderSummary";
import { AddOrderNotes } from "../OrderSummaryScreen/AddOrderNotes";
import { OrderMetaDetails } from "../Order/OrderDetailScreen/OrderMetaDetails";
import { MinimumOrderAdvice } from "../Order/MinimumOrderAdvice";
import { NextStepButton } from "../OrderButton/NextStepButton/NextStepButton";

type Props = {
  onBack: () => void;
  toMenu: () => void;
  toCheckout: () => void;
};

export function TakeawaySummaryComponent(props: Props) {
  const { ristopub } = useCurrentRistopub();
  const {
    getTotalItemQty,
    getCustomerName,
    getCustomerPhone,
    getTotalPrice,
    getCustomerEmail,
    getCustomerNotes,
    getTotalItemPrice,
    getSelectedDueDate,
  } = useCurrentCart();

  const [showPastDateModal, setShowPastDateModal] = useState(false);

  function openPastDateModalIfNeeded() {
    if (ristopub?.isBefore(getSelectedDueDate())) {
      setShowPastDateModal(true);
      return true;
    } else {
      return false;
    }
  }

  function toTakeawayCheckout() {
    if (openPastDateModalIfNeeded()) return;
    props.toCheckout();
  }

  function goToTakeawayMenuScreen() {
    props.toMenu();
  }

  const totalItemQty = getTotalItemQty();
  if (totalItemQty <= 0)
    return (
      <MainLayout withHeader={true} goBack={goToTakeawayMenuScreen}>
        <EmptyCart onClick={goToTakeawayMenuScreen} />
      </MainLayout>
    );

  const orderLessThanMinimum =
    getTotalItemPrice() < (ristopub?.minimumTakeawayOrderValue || 0);

  return (
    <>
      <CantOrderForPastTakeawayDateModal
        visible={showPastDateModal}
        onClose={() => setShowPastDateModal(false)}
      />
      <MainLayout
        screenTitle={"Riepilogo ordine"}
        goBack={props.onBack}
        withHeader={true}
      >
        <div className={"p-1"}>
          <OrderSummary />
        </div>
        <div
          className={["bg-white flex flex-col pb-20 rounded-t-lg px-4"].join(
            " ",
          )}
        >
          <div className={"mt-3 mb-2"}>
            <AddOrderNotes />
          </div>

          <OrderMetaDetails
            customerEmail={getCustomerEmail()}
            total={getTotalPrice()}
            customerName={getCustomerName()}
            customerPhone={getCustomerPhone()}
            customerNotes={getCustomerNotes()}
            dueDate={getSelectedDueDate()}
          />

          <div
            className={
              "fixed bottom-0 left-0 right-0 mr-auto ml-auto max-w-md p-4 text-center"
            }
          >
            {orderLessThanMinimum ? (
              <div className={"mb-1 flex justify-center"}>
                <MinimumOrderAdvice
                  minimumOrderAmount={
                    ristopub?.minimumTakeawayOrderValue as number
                  }
                />
              </div>
            ) : null}

            <NextStepButton
              disabled={orderLessThanMinimum}
              gaProps={{
                gaLabel: "takeaway_order_flow_summary_to_checkout",
                gaCategory: "takeaway_order_flow",
              }}
              onClick={toTakeawayCheckout}
              label={"Vai alla cassa"}
            />
          </div>
        </div>
      </MainLayout>
    </>
  );
}
