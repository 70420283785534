import React from "react";
import { Spin } from "antd";
import { LoadingIcon } from "./LoadingIcong";

export type ListItem = {
  title: string;
  value: string;
  loading?: boolean;
};

type PropTypes = {
  listItems: ListItem[];
};

export function InformationList(props: PropTypes) {
  return (
    <dl>
      {props.listItems.map((i, index) => {
        return (
          <Spin
            key={index}
            indicator={<LoadingIcon />}
            spinning={i.loading || false}
          >
            <div
              className={"px-0 py-3 sm:grid sm:grid-cols-2 sm:gap-4 text-base"}
            >
              <dt className={"md:w-2/5 m-0 font-bold text-gray-500"}>
                {i.title}
              </dt>
              <dd className={"md:w-3/5 m-0 text-gray-900"}>{i.value}</dd>
            </div>
          </Spin>
        );
      })}
    </dl>
  );
}
