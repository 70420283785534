import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { useCurrentCart } from "../Cart/useCurrentCart";
import { MainLayout } from "../MainLayout/MainLayout";
import { ChooseAvailability } from "../ChooseAvailability";
import { AvailabilityType } from "../Ristopub/Availability/AvailabilityType";
import { NextStepButton } from "../OrderButton/NextStepButton/NextStepButton";
import { DeliveryFreeShippingAdvice } from "./DeliveryFreeShippingAdvice";
import React from "react";

type Props = {
  toOrderSummary: () => void;
  onBack: () => void;
};

export function DeliveryChooseTimeComponent(props: Props) {
  const { ristopub } = useCurrentRistopub();
  const { setDeliverySelectedTime, getDeliverySelectedTime } = useCurrentCart();

  if (!ristopub) return null;

  function toOrderSummary() {
    props.toOrderSummary();
  }

  return (
    <MainLayout
      screenTitle={"Quando vuoi ricevere l'ordine?"}
      goBack={props.onBack}
      withHeader={true}
    >
      <div className={"p-4 flex flex-col flex-1 bg-white rounded-t-lg"}>
        <div className={"mb-2 h-96 flex flex-col"}>
          <ChooseAvailability
            selectedTime={getDeliverySelectedTime()}
            onTimeSelected={(time) => setDeliverySelectedTime(time)}
            type={AvailabilityType.DELIVERY}
            dateLabel={"Giorno per la consegna"}
            timeLabel={"Orario per la consegna"}
          />
        </div>

        <div
          className={
            "mt-2 fixed bottom-0 left-0 right-0 ml-auto mr-auto max-w-md px-4 py-2"
          }
        >
          <NextStepButton
            label={"Vedi il carrello"}
            disabled={!getDeliverySelectedTime()}
            gaProps={{
              gaCategory: "delivery_order_flow",
              gaLabel:
                "delivery_order_flow_choose_availability_to_order_summary",
            }}
            onClick={toOrderSummary}
          />
          <div className={"mt-1"}>
            <DeliveryFreeShippingAdvice />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
