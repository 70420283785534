import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { SwitchContainer } from "./SwitchContainer";
import { OrderLastSeenCtxProvider } from "../Order/OrderLastSeenCtx/OrderLastSeenCtxProvider";
import { Last10DaysOrdersCtxProvider } from "../Order/Last10DaysOrdersCtx/Last10DaysOrdersCtxProvider";

export function RootComponent() {
  return (
    <OrderLastSeenCtxProvider>
      <Last10DaysOrdersCtxProvider>
        <Router>
          <SwitchContainer />
        </Router>
      </Last10DaysOrdersCtxProvider>
    </OrderLastSeenCtxProvider>
  );
}
