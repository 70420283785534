import { IDataMapper } from "../interfaces/IDataMapper";
import { RistopubTakeawaySlotDTO } from "./RistopubTakeawaySlotDTO";
import { RistopubTakeawaySlot } from "./RistopubTakeawaySlot";

export class RistopubTakeawaySlotDataMapper
  implements IDataMapper<RistopubTakeawaySlotDTO, RistopubTakeawaySlot>
{
  toDto(e: RistopubTakeawaySlot): RistopubTakeawaySlotDTO {
    return {
      start: {
        time: e.start.time,
      },
      end: {
        time: e.end.time,
      },
    };
  }

  toEntity(d: RistopubTakeawaySlotDTO): RistopubTakeawaySlot {
    return RistopubTakeawaySlot.create({
      start: d.start.time,
      end: d.end.time,
    });
  }
}
