import React from "react";
import { CheckIcon } from "./CheckIcon";

type Props = {
  checked?: boolean;
  onClick?: () => void;
  onChange?: (v: boolean) => void;
  label?: string;
  className?: string;
  labelClassName?: string;
  disabled?: boolean;
  checkedClasses?: string;
  subLabel?: string;
};

export function Checkbox(props: Props) {
  function onClick() {
    if (props.onClick) props.onClick();
    if (props.onChange) props.onChange(!props.checked);
  }

  return (
    <div
      onClick={onClick}
      className={["flex items-center", props.className ?? ""].join(" ")}
    >
      <div
        className={[
          "h-5 w-5 rounded border border-gray-400",
          props.disabled
            ? "bg-gray-200 text-gray-400"
            : props.checked
            ? props.checkedClasses ?? "bg-swippy-orange text-white"
            : "",
        ].join(" ")}
      >
        {props.checked ? <CheckIcon /> : null}
      </div>

      <div className={"ml-2"}>
        {props.label ? (
          <label
            className={[
              "block mb-0 leading-none",
              props.labelClassName ?? "",
            ].join(" ")}
          >
            {props.label}
          </label>
        ) : null}
        {props.subLabel ? (
          <label className="text-sm text-gray-600 leading-none">
            {props.subLabel}
          </label>
        ) : null}
      </div>
    </div>
  );
}
