import { Time } from "./Time";

type CreateRistopubTakeawaySlot = {
  start: string;
  end: string;
};

export class RistopubTakeawaySlot {
  private constructor(
    public start: Time,
    public end: Time,
  ) {}

  public static create({ start, end }: CreateRistopubTakeawaySlot) {
    const startTime = Time.create({
      time: start,
    });

    const endTime = Time.create({
      time: end,
    });

    return new RistopubTakeawaySlot(startTime, endTime);
  }
}
