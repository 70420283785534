import { useRistopubAvailability } from "./Ristopub/Availability/useRistopubAvailability";
import { Loading } from "./Loading/Loading";
import React from "react";
import { Moment } from "moment-timezone";
import { AvailabilityType } from "./Ristopub/Availability/AvailabilityType";
import { TimeSelectorList } from "./ChooseAvailabilityList/TimeSelectorList";

type PropTypes = {
  date?: Moment;
  type: AvailabilityType;
  onTimeSelected: (time: string) => void;
  selectedTime?: string;
};

export function TimeSelector(props: PropTypes) {
  const { availabilities, loading } = useRistopubAvailability(
    props.type,
    props.date,
  );

  if (loading)
    return (
      <div className={"p-2 flex justify-center"}>
        <Loading />
      </div>
    );

  return (
    <TimeSelectorList
      selectedTime={props.selectedTime}
      onTimeSelected={props.onTimeSelected}
      availabilities={availabilities}
    />
  );
}
