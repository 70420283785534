export class ProductModifierChoice {
  constructor(
    public id: string,
    public name?: string,
    public price: number = 0,
    private maxQty?: number,
  ) {}

  public getMaxQty(): number {
    const maxQty = this.maxQty ?? 0;
    return maxQty <= 0 ? Infinity : maxQty;
  }
}
