import React from "react";
import { useToOrderSummary } from "./useToOrderSummary";
import { useToTakeawayMenuScreen } from "./useToTakeawayMenuScreen";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { TakeawayChooseTimeComponent } from "./TakeawayChooseTimeComponent";

export function ChooseTakeawayTimeScreen() {
  const { toOrderSummary } = useToOrderSummary();
  const { ristopubId } = useCurrentRistopubId();
  const { toTakeawayMenu } = useToTakeawayMenuScreen();
  function goBack() {
    toTakeawayMenu(ristopubId);
  }

  return (
    <TakeawayChooseTimeComponent onBack={goBack} toSummary={toOrderSummary} />
  );
}
