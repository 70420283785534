import React from "react";
import { useToChooseShippingMethodScreen } from "../ChooseShippingMethodScreen/useToChooseShippingMethodScreen";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useToChooseTakeawayTimeScreen } from "./useToChooseTakeawayTimeScreen";
import { TakeawayMenuComponent } from "./TakeawayMenuComponent";

export function TakeAwayMenuScreen() {
  const { ristopubId } = useCurrentRistopubId();

  const { toChooseShippingMethodScreen } = useToChooseShippingMethodScreen();

  const { toChooseTakeawayTimeScreen } = useToChooseTakeawayTimeScreen();

  function toChooseShippingMethod() {
    toChooseShippingMethodScreen(ristopubId);
  }

  function toChooseTime() {
    toChooseTakeawayTimeScreen();
  }

  return (
    <TakeawayMenuComponent
      onBack={toChooseShippingMethod}
      toChooseTime={toChooseTime}
    />
  );
}
