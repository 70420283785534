import { useHistory } from "react-router-dom";

export function useToDeliveryOrderSummaryScreen() {
  const history = useHistory();

  function toDeliveryOrderSummaryScreen(ristopubId: string) {
    history.push(`/ristopub/${ristopubId}/delivery/ordersummary`);
  }

  return {
    toDeliveryOrderSummaryScreen,
  };
}
