import React from "react";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { TakeawayMenuComponent } from "./TakeawayMenuComponent";
import { useToChooseShippingMethodWithQrCode } from "../ChooseShippingMethodScreen/useToChooseShippingMethodWithQrCode";
import { useQrCodeId } from "../QrCode/useQrCodeId";
import { useToTakeawayChooseTimeScreenWithQrCode } from "./useToTakeawayChooseTimeScreenWithQrCode";

export function TakeawayMenuScreenWithQrCode() {
  const { ristopubId } = useCurrentRistopubId();
  const { qrcodeId } = useQrCodeId();

  const { toChooseShippingMethodWithQrCode } =
    useToChooseShippingMethodWithQrCode();

  const { toTakeawayChooseTimeScreenWithQrCode } =
    useToTakeawayChooseTimeScreenWithQrCode();

  function toChooseShippingMethod() {
    toChooseShippingMethodWithQrCode(ristopubId, qrcodeId);
  }

  function toChooseTime() {
    toTakeawayChooseTimeScreenWithQrCode(ristopubId, qrcodeId);
  }

  return (
    <TakeawayMenuComponent
      onBack={toChooseShippingMethod}
      toChooseTime={toChooseTime}
    />
  );
}
