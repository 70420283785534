import _ from "lodash";

export class ErrorState {
  private hasErrors: Record<string, boolean> = {};

  public enableError(key: string) {
    this.hasErrors[key] = true;
  }

  public clearError(key: string) {
    this.hasErrors[key] = false;
  }

  public hasSomeError(): boolean {
    return _.some(this.hasErrors, (v) => v);
  }

  public getFirstErroredKey(): string | undefined {
    const hasErrorArray = _.map(this.hasErrors, (v, k) => {
      return {
        key: k,
        hasError: v,
      };
    });

    const firstKeyWithError = _.first(
      _.filter(hasErrorArray, ({ hasError }) => {
        return hasError;
      }),
    );

    return firstKeyWithError ? firstKeyWithError.key : undefined;
  }

  public hasError(key: string): boolean {
    return this.hasErrors[key];
  }

  public clone(): ErrorState {
    return _.cloneDeep(this);
  }
}
