import { ChooseTakeawayTimeScreen } from "../Takeaway/ChooseTakeawayTimeScreen";
import { TakeAwayMenuScreen } from "../Takeaway/TakeAwayMenuScreen";
import { TakeAwayOrderSummaryScreen } from "../Takeaway/TakeAwayOrderSummaryScreen";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { OrdersScreen } from "../Order/OrdersScreen/OrdersScreen";
import { TestScreen } from "../TestScreen/TestScreen";
import React, { useEffect } from "react";
import { safeGtag } from "../safeGtag/safeGtag";
import { OrderDetailScreen } from "../Order/OrderDetailScreen/OrderDetailScreen";
import { InStructureMenuScreen } from "../InStructureOrders/InStructureMenuScreen";
import { InStructureOrderSummaryScreen } from "../InStructureOrders/InStructureOrderSummaryScreen";
import { InStructureCheckoutScreen } from "../InStructureOrders/InStructureCheckoutScreen";
import { TakeawayCheckoutScreen } from "../Takeaway/TakeawayCheckoutScreen";
import { AppHomeScreen } from "../AppHomeScreen/AppHomeScreen";
import { ChooseShippingMethodScreen } from "../ChooseShippingMethodScreen/ChooseShippingMethodScreen";
import { ChooseDeliveryTimeScreen } from "../Delivery/ChooseDeliveryTimeScreen";
import { DeliveryInsertAddressScreen } from "../Delivery/DeliveryInsertAddressScreen";
import { DeliveryOrderSummaryScreen } from "../Delivery/DeliveryOrderSummaryScreen";
import { DeliveryCheckoutScreen } from "../Delivery/DeliveryCheckoutScreen";
import { DeliveryMenuScreen } from "../Delivery/DeliveryMenuScreen";
import { RouteWithCartAndRistopub } from "../RouteWithCart/RouteWithCartAndRistopub";
import { SetDeliveryAddressScreen } from "../Delivery/SetDeliveryAddressScreen";
import { RistopubSlugTakeawayAndDeliveryScreen } from "../Ristopub/RistopubSlugTakeawayAndDeliveryScreen";
import { InStructureSelfPickupMenuScreen } from "../InStructureOrders/InStructureSelfPickupMenuScreen";
import { ChooseInStructurePickupMethodScreen } from "../InStructureOrders/ChooseInStructurePickupMethodScreen";
import { QrCodeScreen } from "../QrCode/QrCodeScreen";
import { InStructureSelfPickupSummaryScreen } from "../InStructureOrders/InStructureSelfPickupSummaryScreen";
import { InStructureSelfPickupCheckoutScreen } from "../InStructureOrders/InStructureSelfPickupCheckoutScreen";
import { TakeawayMenuScreenWithQrCode } from "../Takeaway/TakeawayMenuScreenWithQrCode";
import { TakeawayChooseTimeScreenWithQrCode } from "../Takeaway/TakeawayChooseTimeScreenWithQrCode";
import { TakeawaySummaryScreenWithQrCode } from "../Takeaway/TakeawaySummaryScreenWithQrCode";
import { TakeawayCheckoutScreenWithQrCode } from "../Takeaway/TakeawayCheckoutScreenWithQrCode";
import { ChooseShippingMethodScreenWithQrCode } from "../ChooseShippingMethodScreen/ChooseShippingMethodScreenWithQrCode";
import { DeliveryInsertAddressScreenWithQrCode } from "../Delivery/DeliveryInsertAddressScreenWithQrCode";
import { DeliveryMenuScreenWithQrCode } from "../Delivery/DeliveryMenuScreenWithQrCode";
import { DeliveryChooseTimeScreenWithQrCode } from "../Delivery/DeliveryChooseTimeScreenWithQrCode";
import { DeliverySummaryScreenWithQrCode } from "../Delivery/DeliverySummaryScreenWithQrCode";
import { DeliveryCheckoutScreenWithQrCode } from "../Delivery/DeliveryCheckoutScreenWithQrCode";
import { AuthCtxProvider } from "../Auth/AuthCtxProvider";
import { AuthWithPhoneNumberScreen } from "../Auth/AuthWithPhoneNumberScreen";
import { AuthGuard } from "../Auth/AuthGuard";
import { LogoutScreen } from "../Auth/LogoutScreen";

export function SwitchContainer() {
  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    const unlisten = history.listen((newLocation) => {
      safeGtag("event", "page_view", {
        page_title: "Swippy",
        page_path: newLocation.pathname,
        page_location: window.location.href,
      });

      if (location.pathname !== newLocation.pathname)
        document.body.scrollTop = 0;
    });

    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthCtxProvider>
      <Switch>
        <Route exact={true} path="/orders">
          <OrdersScreen />
        </Route>
        <Route exact={true} path="/login">
          <AuthWithPhoneNumberScreen />
        </Route>
        <Route exact={true} path="/logout">
          <LogoutScreen />
        </Route>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/takeawaytime"
        >
          <ChooseTakeawayTimeScreen />
        </RouteWithCartAndRistopub>
        <Route exact={true} path="/">
          <AppHomeScreen />
        </Route>
        <Redirect
          exact={true}
          from="/ristopub/:ristopubId"
          to="/ristopub/:ristopubId/chooseshippingmethod"
        />

        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/chooseshippingmethod"
        >
          <ChooseShippingMethodScreen />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub exact={true} path="/:ristopubSlug">
          <RistopubSlugTakeawayAndDeliveryScreen />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/delivery/choosetime"
        >
          <ChooseDeliveryTimeScreen />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/delivery/ordersummary"
        >
          <DeliveryOrderSummaryScreen />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/delivery/checkout"
        >
          <AuthGuard>
            <DeliveryCheckoutScreen />
          </AuthGuard>
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/delivery/insertaddress"
        >
          <DeliveryInsertAddressScreen />
        </RouteWithCartAndRistopub>

        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/ordersummary"
        >
          <TakeAwayOrderSummaryScreen />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/checkout"
        >
          <AuthGuard>
            <TakeawayCheckoutScreen />
          </AuthGuard>
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/order/:cartId"
        >
          <OrderDetailScreen />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/qrcode/:qrcodeId"
        >
          <QrCodeScreen />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/table/:tableName/menu"
        >
          <InStructureMenuScreen />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/takeaway/menu"
        >
          <TakeAwayMenuScreen />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/qrcode/:qrcodeId/takeaway/menu"
        >
          <TakeawayMenuScreenWithQrCode />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/qrcode/:qrcodeId/takeaway/choosetime"
        >
          <TakeawayChooseTimeScreenWithQrCode />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/qrcode/:qrcodeId/takeaway/summary"
        >
          <TakeawaySummaryScreenWithQrCode />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/qrcode/:qrcodeId/takeaway/checkout"
        >
          <AuthGuard>
            <TakeawayCheckoutScreenWithQrCode />
          </AuthGuard>
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/qrcode/:qrcodeId/chooseshippingmethod"
        >
          <ChooseShippingMethodScreenWithQrCode />
        </RouteWithCartAndRistopub>

        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/qrcode/:qrcodeId/delivery/insertaddress"
        >
          <DeliveryInsertAddressScreenWithQrCode />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/qrcode/:qrcodeId/delivery/menu"
        >
          <DeliveryMenuScreenWithQrCode />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/qrcode/:qrcodeId/delivery/choosetime"
        >
          <DeliveryChooseTimeScreenWithQrCode />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/qrcode/:qrcodeId/delivery/summary"
        >
          <DeliverySummaryScreenWithQrCode />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/qrcode/:qrcodeId/delivery/checkout"
        >
          <AuthGuard>
            <DeliveryCheckoutScreenWithQrCode />
          </AuthGuard>
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/delivery/menu"
        >
          <DeliveryMenuScreen />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/delivery/setaddress"
        >
          <SetDeliveryAddressScreen />
        </RouteWithCartAndRistopub>

        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/table/:tableName/ordersummary"
        >
          <InStructureOrderSummaryScreen />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/table/:tableName/order/:cartId"
        >
          <OrderDetailScreen />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/table/:tableName/checkout"
        >
          <AuthGuard>
            <InStructureCheckoutScreen />
          </AuthGuard>
        </RouteWithCartAndRistopub>

        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/table/:tableName/selfmenu"
        >
          <InStructureSelfPickupMenuScreen />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/table/:tableName/selfsummary"
        >
          <InStructureSelfPickupSummaryScreen />
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/table/:tableName/selfcheckout"
        >
          <AuthGuard>
            <InStructureSelfPickupCheckoutScreen />
          </AuthGuard>
        </RouteWithCartAndRistopub>
        <RouteWithCartAndRistopub
          exact={true}
          path="/ristopub/:ristopubId/table/:tableName/choosepickupmethod"
        >
          <ChooseInStructurePickupMethodScreen />
        </RouteWithCartAndRistopub>

        <Route path="/screen/test">
          <TestScreen />
        </Route>
      </Switch>
    </AuthCtxProvider>
  );
}
