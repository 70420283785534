import { MainLayout } from "./MainLayout/MainLayout";
import React from "react";

export function NotFoundScreen() {
  return (
    <MainLayout withHeader={true}>
      <div className={"p-4 flex flex-col items-center"}>
        <img
          className={"w-40"}
          src={"/swippy-side-logotype.svg"}
          alt={"Logo"}
        />
        <p className={"text-center mt-4 max-w-xs"}>
          Ooops. La pagina che stai cercando non è stata trovata
        </p>
      </div>
    </MainLayout>
  );
}
