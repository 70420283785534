import React, { useState } from "react";
import { Order } from "../Order";
import { OrderSeenTracker } from "../ChangedOrdersStatusBadge/OrderSeenTracker";
import { OrderLastSeenCtx } from "./OrderLastSeenCtx";
import _ from "lodash";

type PropTypes = {
  children?: React.ReactNode;
};
export function OrderLastSeenCtxProvider(props: PropTypes) {
  const [orderSeenTracker, setSeenTracker] = useState<OrderSeenTracker>(
    OrderSeenTracker.create(),
  );

  function updateLastSeenOf(order: Order) {
    if (isChangedSinceLastSeen(order)) {
      orderSeenTracker.updateLastSeenOf(order);
      setSeenTracker(_.cloneDeep(orderSeenTracker));
    }
  }

  function isChangedSinceLastSeen(order: Order) {
    return orderSeenTracker.isStatusChangedSinceLastSeen(order);
  }

  return (
    <OrderLastSeenCtx.Provider
      value={{
        updateLastSeenOf,
        isChangedSinceLastSeen,
      }}
    >
      {props.children || null}
    </OrderLastSeenCtx.Provider>
  );
}
