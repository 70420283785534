import React from "react";
import { Cart } from "./classes/Cart";
import { loadCartFromLocalStorage } from "./cartLocalStorageHandlers";
import { Product } from "../RistopubMenu/Product/Product";
import { Address } from "./classes/Address/Address";
import { CartShippingMethod } from "./classes/CartDTO";
import { Moment } from "moment-timezone";
import { CartItem } from "./classes/CartItem";

export type CartCtxType = {
  cart: Cart;
  addToCart: (product: Product, qty: number) => void;
  removeFromCart: (product: Product, qty: number) => void;
  setCartAddress: (address: Address) => void;
  setSelectedTime: (timeString: string) => void;
  setCustomerNotes: (customerNotes?: string) => void;
  setCustomerName: (customerName?: string) => void;
  setCustomerPhone: (customerName?: string) => void;
  setCustomerEmail: (customerEmail?: string) => void;
  setDeliveryCost: (deliveryCost: number) => void;
  setDeliverySelectedTime: (timeString: string) => void;
  increaseCartItemQty: (cartItemId: string) => void;
  decreaseCartItemQty: (cartItemId: string) => void;
  addToCartByCartItem: (cartItem: CartItem) => void;
  updateCartItem: (cartItem: CartItem) => void;
  toggleOptionByCartItem: (
    cartItemId: string,
    modifierId: string,
    optionId: string,
    modifierName: string,
    choiceName: string,
  ) => void;
  setShippingMethod: (shippingMethod: CartShippingMethod) => void;
  setSelectedDate: (date: Moment) => void;
  markCurrentCartAsOrdered: (virtualTableId: string) => void;
  emptyCart: () => void;
  cartScope: string;
  refreshingShippingCost: boolean;
};

const CartCtx = React.createContext<CartCtxType>({
  cartScope: "",
  cart: loadCartFromLocalStorage(),
  addToCart: () => {},
  removeFromCart: () => {},
  setSelectedTime: () => {},
  setSelectedDate: () => {},
  setCustomerNotes: () => {},
  setCustomerName: () => {},
  setCustomerEmail: () => {},
  setDeliveryCost: () => {},
  setCartAddress: () => {},
  setDeliverySelectedTime: () => {},
  setCustomerPhone: () => {},
  markCurrentCartAsOrdered: () => {},
  emptyCart: () => {},
  setShippingMethod: () => {},
  increaseCartItemQty: () => {},
  decreaseCartItemQty: () => {},
  toggleOptionByCartItem: () => {},
  addToCartByCartItem: () => {},
  updateCartItem: () => {},
  refreshingShippingCost: false,
});

export { CartCtx };
