import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { useCurrentCart } from "../Cart/useCurrentCart";
import { MainLayout } from "../MainLayout/MainLayout";
import { RistopubTopImage } from "../Ristopub/RistopubTopImage";
import { NewRistopubMenu } from "../RistopubMenu/NewRistopubMenu";
import { MinimumOrderAdvice } from "../Order/MinimumOrderAdvice";
import { NextStepButton } from "../OrderButton/NextStepButton/NextStepButton";
import React, { useState } from "react";
import { WarnToast } from "../ui/WarnToast";
import { formatPrice } from "../formatPrice/formatPrice";

type Props = {
  onBack: () => void;
  toChooseTime: () => void;
};

export function TakeawayMenuComponent(props: Props) {
  const { ristopub } = useCurrentRistopub();
  const { getTotalItemQty, getTotalItemPrice } = useCurrentCart();

  const [triggerEmptyCartWarn, setTriggerEmptyCartWarn] = useState(false);

  const [triggerOrderLessThanMinimum, setTriggerOrderLessThanMinimum] =
    useState(false);

  const orderLessThanMinimum =
    getTotalItemPrice() < (ristopub?.minimumTakeawayOrderValue || 0);

  const itemQty = getTotalItemQty();

  function onClickNext() {
    if (itemQty <= 0) {
      setTriggerEmptyCartWarn(!triggerEmptyCartWarn);
    } else if (orderLessThanMinimum) {
      setTriggerOrderLessThanMinimum(!triggerOrderLessThanMinimum);
    } else {
      props.toChooseTime();
    }
  }

  return (
    <MainLayout goBack={props.onBack} screenTitle={"Menu"} withHeader={true}>
      <WarnToast
        trigger={triggerOrderLessThanMinimum}
        copy={`Il tuo ordine deve essere minimo di ${formatPrice(
          ristopub?.minimumTakeawayOrderValue as number,
        )} €`}
        timeoutInSeconds={5}
      />

      <WarnToast
        trigger={triggerEmptyCartWarn}
        copy={
          "Il tuo carrello è vuoto.<br/>Clicca su un prodotto per aggiungerlo al carrello"
        }
        timeoutInSeconds={5}
      />
      <RistopubTopImage offsetTop={45}>
        <div className={"flex flex-1 flex-col pb-20 bg-white rounded-t-3xl"}>
          <NewRistopubMenu />
          <div
            className={[
              "fixed bottom-0 flex flex-col items-center justify-center left-0 right-0 p-4 max-w-md ml-auto mr-auto",
            ].join(" ")}
          >
            {orderLessThanMinimum ? (
              <div className={"mb-1 flex justify-center"}>
                <MinimumOrderAdvice
                  minimumOrderAmount={
                    ristopub?.minimumTakeawayOrderValue as number
                  }
                />
              </div>
            ) : null}
            <NextStepButton
              label={"Scegli orario ritiro"}
              gaProps={{
                gaLabel: "takeaway_order_flow_menu_to_choose_time",
                gaCategory: "takeaway_order_flow",
              }}
              onClick={onClickNext}
            />
          </div>
        </div>
      </RistopubTopImage>
    </MainLayout>
  );
}
