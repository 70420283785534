import React, { useState } from "react";
import isMobile from "is-mobile";

type RenderProps = {
  touchHover: boolean;
};

type PropTypes = {
  children?: (props: RenderProps) => React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  stopPropagation?: boolean;
  disableClick?: boolean;
};

export function MobileTouchFeedbackWrapper(props: PropTypes) {
  const [touchHover, setTouchHover] = useState(false);

  return (
    <div
      className={props.className}
      style={props.style}
      onMouseEnter={(e) => {
        if (props.disableClick) return;
        if (props.stopPropagation) e.stopPropagation();
        if (isMobile()) return;
        setTouchHover(true);
      }}
      onMouseLeave={(e) => {
        if (props.disableClick) return;
        if (props.stopPropagation) e.stopPropagation();
        if (isMobile()) return;
        setTouchHover(false);
      }}
      onTouchEnd={(e) => {
        if (props.disableClick) return;
        if (props.stopPropagation) e.stopPropagation();
        setTouchHover(false);
      }}
      onTouchStart={(e) => {
        if (props.disableClick) return;
        if (props.stopPropagation) e.stopPropagation();
        setTouchHover(true);
      }}
    >
      {props.children
        ? props.children({
            touchHover,
          })
        : null}
    </div>
  );
}
