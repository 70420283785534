import { useHistory } from "react-router-dom";

export function useToDeliveryCheckoutScreen() {
  const history = useHistory();

  function toDeliveryCheckoutScreen(ristopubId: string) {
    history.push(`/ristopub/${ristopubId}/delivery/checkout`);
  }

  return {
    toDeliveryCheckoutScreen,
  };
}
