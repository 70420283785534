import { useHistory } from "react-router-dom";

export function useToDeliveryCheckoutScreenWithQrCode() {
  const history = useHistory();

  function toDeliveryCheckoutScreenWithQrCode(
    ristopubId: string,
    qrCodeId: string,
  ) {
    return history.push(
      `/ristopub/${ristopubId}/qrcode/${qrCodeId}/delivery/checkout`,
    );
  }

  return {
    toDeliveryCheckoutScreenWithQrCode,
  };
}
