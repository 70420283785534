import { gql, useMutation } from "@apollo/client";
import _ from "lodash";
import { CartItem } from "../Cart/classes/CartItem";
import { useCurrentCart } from "../Cart/useCurrentCart";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useGuestUserId } from "../OrderSummaryScreen/useGuestUserId";
import { GenericErrorHandler } from "../GenericErrorHandler/GenericErrorHandler";
import { useToOrderDetail } from "../Takeaway/useToOrderDetail";
import { VirtualTableDTO } from "../Order/VirtualTableDTO";
import { AddressDTO } from "../Cart/classes/Address/AddressDTO";
import { AddOrderItemInput } from "../Order/AddOrderItemInput";
import { useQrCodeId } from "../QrCode/useQrCodeId";

const ADD_DELIVERY_ORDER_MUTATION = gql`
  mutation addDeliveryOrder($input: AddDeliveryOrderInput!) {
    addDeliveryOrder(input: $input) {
      name
      __typename
      id
    }
  }
`;

export function useCompleteDeliveryOrderWithCashPaymentFlow() {
  const { qrcodeId } = useQrCodeId();
  const { ristopubId } = useCurrentRistopubId();
  const { guestUserId } = useGuestUserId();
  const { toOrderDetail } = useToOrderDetail();
  const {
    getItems,
    getSelectedDeliveryDate,
    markCurrentCartAsOrdered,
    getCustomerName,
    getCustomerNotes,
    getCartId,
    getCustomerPhone,
    getDeliveryAddress,
    getCustomerEmail,
  } = useCurrentCart();

  const [addDeliveryOrderMutation, { loading }] = useMutation<
    {
      addDeliveryOrder: VirtualTableDTO;
    },
    {
      input: {
        cartId: string;
        customerEmail: string;
        customerName?: string;
        customerNotes?: string;
        customerPhone: string;
        deliveryAddress: AddressDTO;
        deliveryDate: Date;
        guestUserId: string;
        inPlace?: boolean;
        items: AddOrderItemInput[];
        ristopubId: string;
        qrCodeId?: string;
      };
    }
  >(ADD_DELIVERY_ORDER_MUTATION);

  function displayErrorToGui(e: Error) {
    const errorHandler = new GenericErrorHandler();
    errorHandler.handleError(e);
  }

  async function completeDeliveryOrderWithCashFlow() {
    const address = getDeliveryAddress();
    const res = await addDeliveryOrderMutation({
      variables: {
        input: {
          qrCodeId: qrcodeId,
          deliveryAddress: {
            address: address.getStreetWithStreetNumber(),
            postalCode: address.postalCode,
            city: address.city,
            province: address.province,
            country: address.country,
          },
          items: _.map(getItems(), (i: CartItem) => {
            return {
              qty: i.getQty(),
              productId: i.getProduct().id,
              modifiers: i.getOptionsGroupedByModifier().map((o) => {
                return {
                  modifierId: o.modifierId,
                  choices: o.options.map((o) => {
                    return {
                      id: o.choiceId,
                      qty: o.choiceQty,
                    };
                  }),
                };
              }),
            };
          }),
          ristopubId: ristopubId,
          customerName: getCustomerName(),
          customerNotes: getCustomerNotes(),
          deliveryDate: getSelectedDeliveryDate()?.toDate() as Date,
          cartId: getCartId() as string,
          customerPhone: getCustomerPhone() as string,
          guestUserId,
          customerEmail: getCustomerEmail() as string,
        },
      },
    });

    const virtualTableId: string | undefined = _.get(
      res.data,
      "addDeliveryOrder.id",
    );
    if (!virtualTableId)
      return displayErrorToGui(new Error("Oooops. Qualcosa è andato storto"));
    const cartId = getCartId();
    if (!cartId) return displayErrorToGui(new Error("Missing cart id"));
    markCurrentCartAsOrdered(virtualTableId);
    toOrderDetail(cartId, ristopubId, true);
  }

  return {
    completeDeliveryOrderWithCashFlow,
    loading,
  };
}
