import React from "react";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { TakeawaySummaryComponent } from "./TakeawaySummaryComponent";
import { useQrCodeId } from "../QrCode/useQrCodeId";
import { useToTakeawayCheckoutScreenWithQrCode } from "./useToTakeawayCheckoutScreenWithQrCode";
import { useToTakeawayChooseTimeScreenWithQrCode } from "./useToTakeawayChooseTimeScreenWithQrCode";
import { useToTakeawayMenuScreenWithQrCode } from "./useToTakeawayMenuScreenWithQrCode";

export function TakeawaySummaryScreenWithQrCode() {
  const { ristopubId } = useCurrentRistopubId();
  const { qrcodeId } = useQrCodeId();

  const { toTakeawayCheckoutScreenWithQrCode } =
    useToTakeawayCheckoutScreenWithQrCode();

  const { toTakeawayChooseTimeScreenWithQrCode } =
    useToTakeawayChooseTimeScreenWithQrCode();

  const { toTakeawayMenuScreenWithQrCode } =
    useToTakeawayMenuScreenWithQrCode();

  function toCheckout() {
    toTakeawayCheckoutScreenWithQrCode(ristopubId, qrcodeId);
  }

  function toMenu() {
    toTakeawayMenuScreenWithQrCode(ristopubId, qrcodeId);
  }

  function toChooseTime() {
    toTakeawayChooseTimeScreenWithQrCode(ristopubId, qrcodeId);
  }

  return (
    <TakeawaySummaryComponent
      toCheckout={toCheckout}
      onBack={toChooseTime}
      toMenu={toMenu}
    />
  );
}
