import React from "react";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useToTakeawayMenuScreen } from "../Takeaway/useToTakeawayMenuScreen";
import { useToDeliveryInsertAddressScreen } from "../Delivery/useToDeliveryInsertAddressScreen";
import { ChooseShippingMethodComponent } from "./ChooseShippingMethodComponent";

export function ChooseShippingMethodScreen() {
  const { ristopubId } = useCurrentRistopubId();

  const { toDeliveryInsertAddressScreen } = useToDeliveryInsertAddressScreen();

  const { toTakeawayMenu } = useToTakeawayMenuScreen();

  function toDelivery() {
    toDeliveryInsertAddressScreen(ristopubId);
  }

  function toTakeaway() {
    toTakeawayMenu(ristopubId);
  }

  return (
    <ChooseShippingMethodComponent
      toDelivery={toDelivery}
      toTakeaway={toTakeaway}
    />
  );
}
