import React from "react";

export function PendingOrders() {
  return null;
  // const {toOrders} = useToOrders()
  //
  // const {openedOrders} = useOpenedOrders()
  //
  // if (openedOrders.length <= 0) return null
  //
  //
  // const El = <MobileTouchFeedbackWrapper
  //
  // >
  //     {({touchHover}) => {
  //         const classes =[
  //             "rounded flex items-center justify-center",
  //             touchHover ? "bg-swippy-dark-orange" : "bg-swippy-orange"
  //         ]
  //         return <div className={"p-1 ml-auto mr-auto max-w-md cursor-pointer"}>
  //             <div onClick={toOrders} className={classes.join(" ")}>
  //                 <span className={"text-center font-bold text-white"}>Visualizza {openedOrders.length > 1 ? "ordini" : "ordine"} in corso</span>
  //
  //             </div>
  //         </div>
  //     }}
  // </MobileTouchFeedbackWrapper>
  //
  // return <div className={"z-20"}>
  //     {React.cloneElement(El, {
  //         style: {
  //             position: "fixed",
  //             top: 0,
  //             left: 0,
  //             right: 0,
  //         }
  //     })}
  //     <div className={"bg-white"}>
  //         {React.cloneElement(El, {
  //             style: {
  //                 visibility: "hidden"
  //             }
  //         })}
  //     </div>
  // </div>
}
