import { preserveInPlaceQueryParamIfSet } from "../RistopubMenu/preserveInPlaceQueryParamIfSet";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "../useQueryParams/useQueryParams";

export function useToInStructureCheckoutScreen() {
  const history = useHistory();
  const query = useQueryParams();
  function toInStructureCheckoutScreen(
    cartId: string,
    ristopubId: string,
    tableName: string,
    replace: boolean = false,
  ) {
    const path = preserveInPlaceQueryParamIfSet(
      `/ristopub/${ristopubId}/table/${tableName}/checkout`,
      query,
    );

    if (replace) history.replace(path);
    else history.push(path);
  }

  return {
    toInStructureCheckoutScreen,
  };
}
