export class LocalStoragePhoneSanitizer {
  private static hasPlus(phoneNumber: string) {
    return phoneNumber.includes("+");
  }

  sanitize(phoneNumber: string): string {
    let newPhone = phoneNumber;
    if (!LocalStoragePhoneSanitizer.hasPlus(newPhone))
      newPhone = `+39${newPhone}`;
    return newPhone.replace(/\s/g, "");
  }
}
