import { useQueryParams } from "../useQueryParams/useQueryParams";
import React, { useEffect, useRef, useState } from "react";
import { MainLayout } from "../MainLayout/MainLayout";
import { ListLoading } from "../Loading/ListLoading";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useCurrentCart } from "../Cart/useCurrentCart";
import { useRistopubCanDeliverToAddress } from "./useRistopubCanDeliverToAddress";
import { useGeocodeAddress } from "./useGeocodeAddress";
import { AddressFromComponentsExtractor } from "./AddressFromComponentsExtractor";
import { useToDeliveryInsertAddressScreen } from "./useToDeliveryInsertAddressScreen";
import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { useToDeliveryMenuScreen } from "./useToDeliveryMenuScreen";

export function SetDeliveryAddressScreen() {
  const queryParams = useQueryParams();

  const address = queryParams.get("address");

  const { ristopubId } = useCurrentRistopubId();
  const { setCartAddress } = useCurrentCart();

  const { ristopub } = useCurrentRistopub();

  const { ristopubCanDeliverToAddress } = useRistopubCanDeliverToAddress();

  const { geocodeAddress } = useGeocodeAddress();

  const { current: addressExtractor } = useRef(
    new AddressFromComponentsExtractor(),
  );

  const [loading, setLoading] = useState(false);

  const { toDeliveryInsertAddressScreen } = useToDeliveryInsertAddressScreen();
  const { toDeliveryMenu } = useToDeliveryMenuScreen();
  async function init() {
    if (!ristopub) throw new Error(`Can't call init while loading ristopub`);
    if (!address) return toDeliveryInsertAddressScreen(ristopubId, true);
    const geoRes = await geocodeAddress(address);
    if (!geoRes) return toDeliveryInsertAddressScreen(ristopubId, true);
    const addressObj = addressExtractor.toAddress(geoRes.addressComponents);
    setCartAddress(addressObj);
    if (!addressObj.isCompleteAddress())
      return toDeliveryInsertAddressScreen(ristopubId, true);

    const canDeliver = ristopubCanDeliverToAddress(ristopub, addressObj, 0);
    if (!canDeliver) return toDeliveryInsertAddressScreen(ristopubId, true);
    toDeliveryMenu(ristopubId, true);
  }

  useEffect(() => {
    if (!ristopub) return;
    init().catch((e) => {
      console.error(e);
      throw e;
    });
  }, [address, ristopub ? ristopub.id : undefined]);

  if (loading)
    return (
      <MainLayout withHeader={true}>
        <ListLoading />
      </MainLayout>
    );

  return null;
}
