import { useHistory } from "react-router-dom";
import { useQueryParams } from "../useQueryParams/useQueryParams";
import { preserveInPlaceQueryParamIfSet } from "../RistopubMenu/preserveInPlaceQueryParamIfSet";
import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";

export function useToOrderSummary() {
  const { ristopub } = useCurrentRistopub();
  const history = useHistory();
  const query = useQueryParams();

  function toOrderSummary(replace: boolean = false) {
    if (!ristopub) throw new Error("Missing ristopub");
    const path = preserveInPlaceQueryParamIfSet(
      `/ristopub/${ristopub.id}/ordersummary`,
      query,
    );
    if (replace) history.replace(path);
    else history.push(path);
  }

  return {
    toOrderSummary,
  };
}
