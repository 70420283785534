import { useCurrentCart } from "../Cart/useCurrentCart";
import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useToOrderDetail } from "./useToOrderDetail";
import { useGuestUserId } from "../OrderSummaryScreen/useGuestUserId";
import { GenericErrorHandler } from "../GenericErrorHandler/GenericErrorHandler";
import _ from "lodash";
import { CartItem } from "../Cart/classes/CartItem";
import { OrderDTO } from "../Order/OrderDTO";
import { AddOrderItemInput } from "../Order/AddOrderItemInput";
import { useQrCodeId } from "../QrCode/useQrCodeId";

const COMPLETE_TAKEAWAY_ORDER_MUTATION = gql`
  mutation addTakeawayOrder($input: AddTakeawayOrderInput!) {
    addTakeawayOrder(input: $input) {
      name
      __typename
      id
    }
  }
`;
export function useCompleteTakeawayOrderWithCashPaymentFlow() {
  const {
    markCurrentCartAsOrdered,
    getCustomerPhone,
    getSelectedDueDate,
    getCustomerEmail,
    getCustomerName,
    getCustomerNotes,
    getItems,
    getCartId,
  } = useCurrentCart();
  const { ristopub } = useCurrentRistopub();
  const [completingOrder, setCompletingOrder] = useState(false);
  const [completeTakeAwayOrderMutation] = useMutation<
    {
      addTakeawayOrder: OrderDTO;
    },
    {
      input: {
        items: AddOrderItemInput[];
        ristopubId: string;
        customerName?: string;
        customerPhone: string;
        customerNotes?: string;
        dueDate: Date;
        cartId: string;
        inPlace?: boolean;
        guestUserId: string;
        customerEmail: string;
        qrCodeId?: string;
      };
    }
  >(COMPLETE_TAKEAWAY_ORDER_MUTATION);
  const { qrcodeId } = useQrCodeId();
  const { toOrderDetail } = useToOrderDetail();

  const { guestUserId } = useGuestUserId();

  function displayErrorToGui(e: Error) {
    const errorHandler = new GenericErrorHandler();
    errorHandler.handleError(e);
  }

  async function completeTakeawayOrderWithCashPaymentFlow() {
    try {
      setCompletingOrder(true);
      if (!ristopub) return displayErrorToGui(new Error("Missing ristopub"));

      const res = await completeTakeAwayOrderMutation({
        variables: {
          input: {
            qrCodeId: qrcodeId,
            inPlace: false,
            items: _.map(getItems(), (i: CartItem) => {
              return {
                qty: i.getQty(),
                productId: i.getProduct().id,
                modifiers: i.getOptionsGroupedByModifier().map((o) => {
                  return {
                    modifierId: o.modifierId,
                    choices: o.options.map((o) => {
                      return {
                        id: o.choiceId,
                        qty: o.choiceQty,
                      };
                    }),
                  };
                }),
              };
            }),
            ristopubId: ristopub.id,
            customerName: getCustomerName(),
            customerNotes: getCustomerNotes(),
            dueDate: getSelectedDueDate()?.toDate() as Date,
            cartId: getCartId() as string,
            customerPhone: getCustomerPhone() as string,
            guestUserId,
            customerEmail: getCustomerEmail() as string,
          },
        },
      });

      const virtualTableId: string | undefined = _.get(
        res.data,
        "addTakeawayOrder.id",
      );
      if (!virtualTableId)
        return displayErrorToGui(new Error("Oooops. Qualcosa è andato storto"));
      const cartId = getCartId();
      if (!cartId) return displayErrorToGui(new Error("Missing cart id"));
      markCurrentCartAsOrdered(virtualTableId);
      toOrderDetail(cartId, ristopub.id as string, true);
    } catch (e) {
      displayErrorToGui(e as Error);
      throw e;
    } finally {
      setCompletingOrder(false);
    }
  }

  return {
    completeTakeawayOrderWithCashPaymentFlow,
    completingOrder,
  };
}
