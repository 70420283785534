import { useContext } from "react";
import { RistopubCtx } from "./RistopubCtx";

export function useCurrentRistopub() {
  const { ristopub, loadingCurrentRistopub } = useContext(RistopubCtx);

  return {
    ristopub,

    loadingCurrentRistopub,
  };
}
