import { Product } from "../../RistopubMenu/Product/Product";
import { CartItemSelectedOption } from "./CartItemSelectedOption";
import _ from "lodash";
import { nanoid } from "nanoid";

type CreateCartItemInput = {
  product: Product;
  qty: number;
  id?: string;
  options?: CartItemSelectedOption[];
};

type OptionGroupedByModifier = {
  modifierId: string;
  modifierName: string;
  options: CartItemSelectedOption[];
};

export class CartItem {
  private constructor(
    public readonly id: string = nanoid(),
    private product: Product,
    private qty: number,
    private options: CartItemSelectedOption[] = [],
  ) {}

  private getOptionsKey(): string {
    const options = _.sortBy(this.options, (o) => o.getKey());
    return _.reduce(
      options,
      (res: string[], o, index) => {
        res.push(`option${index}:${o.getKey()}`);
        return res;
      },
      [],
    ).join("-");
  }

  public getKey(): string {
    const optionKey = this.getOptionsKey();
    const res: string[] = [`productId:${this.product.id}`];
    if (optionKey.length > 0) res.push(optionKey);

    return res.join("-");
  }

  public getOptions(): CartItemSelectedOption[] {
    return _.cloneDeep(this.options);
  }

  public hasAtLeastOneOptionOfModifier(modifierId: string): boolean {
    return !!this.options.find((o) => o.modifierId === modifierId);
  }

  private removeOption(modifierId: string, optionId: string) {
    _.remove(
      this.options,
      (o) => o.modifierId === modifierId && o.choiceId === optionId,
    );
  }

  public increaseOptionQty(
    modifierId: string,
    choiceId: string,
    modifierName: string,
    choiceName: string,
    choicePrice: number,
  ) {
    const opt = this.getOption(modifierId, choiceId);
    if (opt) {
      opt.increaseQty();
    } else {
      this.options.push(
        new CartItemSelectedOption(
          modifierId,
          choiceId,
          modifierName,
          choiceName,
          choicePrice,
          1,
        ),
      );
    }
  }

  public decreaseOptionQty(modifierId: string, choiceId: string) {
    const opt = this.getOption(modifierId, choiceId);
    if (!opt)
      throw new Error(
        `Option mid: ${modifierId} oid: ${choiceId} is not on that cart item. Product ${this.product.id}`,
      );
    opt.decreaseQty();
    if (opt.choiceQty <= 0) this.removeOption(modifierId, choiceId);
  }

  public toggleOption(
    modifierId: string,
    optionId: string,
    modifierName: string,
    choiceName: string,
    choicePrice?: number,
  ) {
    if (this.hasOption(modifierId, optionId)) {
      this.removeOption(modifierId, optionId);
    } else {
      this.options.push(
        new CartItemSelectedOption(
          modifierId,
          optionId,
          modifierName,
          choiceName,
          choicePrice,
        ),
      );
    }
  }

  private getOption(
    modifierId: string,
    optionId: string,
  ): CartItemSelectedOption | undefined {
    return this.options.find(
      (o) => o.modifierId === modifierId && o.choiceId === optionId,
    );
  }

  public hasOption(modifierId: string, optionId: string): boolean {
    return !!this.options.find(
      (o) => o.modifierId === modifierId && o.choiceId === optionId,
    );
  }

  public static create({ product, qty, id, options }: CreateCartItemInput) {
    return new CartItem(id, product, qty, options);
  }

  public increaseQty(by: number = 1) {
    this.qty = this.qty + by;
  }

  public getQty() {
    return this.qty;
  }

  public getProduct() {
    return this.product;
  }

  private getTotalOptionsPrice(): number {
    return _.reduce(
      this.options,
      (sum, o) => {
        return sum + o.getTotalPrice();
      },
      0,
    );
  }

  public getUnitPrice(): number {
    const price = this.product.price ?? 0;
    return price + this.getTotalOptionsPrice();
  }

  public getTotalPrice() {
    return this.getUnitPrice() * this.qty;
  }

  public decreaseQty(by: number = 1) {
    if (this.qty - by <= 0) this.qty = 0;
    else this.qty = this.qty - by;
  }

  public getOptionsGroupedByModifier(): OptionGroupedByModifier[] {
    const groupByModifier = _.groupBy(this.getOptions(), (o) => o.modifierId);
    return _.reduce(
      groupByModifier,
      (res: OptionGroupedByModifier[], options) => {
        const firstOption = options[0];
        if (!firstOption) return res;
        res.push({
          modifierName: firstOption.modifierName,
          modifierId: firstOption.modifierId,
          options,
        });
        return res;
      },
      [],
    );
  }

  public getNumberOfOptionsOfModifier(modifierId: string): number {
    const optionsByModifier = _.groupBy(this.options, (o) => o.modifierId);
    return _.reduce(
      optionsByModifier[modifierId],
      (sum, o) => {
        return sum + o.choiceQty;
      },
      0,
    );
  }

  public getOptionQty(modifierId: string, choiceId: string): number {
    const opt = this.getOption(modifierId, choiceId);
    return opt?.choiceQty ?? 0;
  }

  public clearModifier(modifierId: string) {
    _.remove(this.options, (o) => o.modifierId === modifierId);
  }
}
