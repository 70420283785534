import { IProductDataMapper } from "./IProductDataMapper";
import { Product } from "./Product";
import { ProductDTO } from "./ProductDTO";
import { ProductModifierDataMapper } from "./ProductModifierDataMapper";

export class ProductDataMapper implements IProductDataMapper {
  constructor(
    private productModifierDataMapper = new ProductModifierDataMapper(),
  ) {}

  toDto(e: Product): ProductDTO {
    const imageSrc = e.getImageSrc();

    return {
      id: e.id,
      subTitle: e.subTitle,
      pos: e.pos,
      name: e.name,
      description: e.description,
      price: e.price,
      image: imageSrc
        ? {
            src: imageSrc,
          }
        : undefined,
      categoryId: e.categoryId,
    };
  }

  toEntity(d: ProductDTO): Product {
    return Product.create({
      id: d.id,
      pos: d.pos || 0,
      name: d.name,
      image: d.image ? d.image.src : undefined,
      description: d.description,
      price: d.price,
      categoryId: d.categoryId,
      subTitle: d.subTitle,
      modifiers: d.modifiers
        ? d.modifiers.map((m) => this.productModifierDataMapper.toEntity(m))
        : undefined,
    });
  }
}
