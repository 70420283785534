import React from "react";

import { GuestUserDTO } from "./GuestUserDTO";

type AuthCtxType = {
  user: GuestUserDTO | undefined;
};

const AuthCtx = React.createContext<AuthCtxType>({
  user: undefined,
});

export { AuthCtx };
