import React from "react";
import { Order } from "../Order";
import { OrdersListComponent } from "./OrdersListComponent";
import { useToOrderDetail } from "../../Takeaway/useToOrderDetail";
import { useOrderLastSeen } from "../OrderLastSeenCtx/useOrderLastSeen";
import { SuccessIcon } from "../../ui/SuccessIcon";
import { WarningIcon } from "../../ui/WarningIcon";
import { InfoIcon } from "../../ui/InfoIcon";

type PropTypes = {
  order: Order;
};

export function OrdersListItem(props: PropTypes) {
  const { isChangedSinceLastSeen } = useOrderLastSeen();

  const ristopub = props.order.virtualTable?.ristopub;

  const { toOrderDetail } = useToOrderDetail();
  if (!ristopub) return null;

  function goToOrderDetail() {
    if (!ristopub) throw new Error("Missing ristopub");
    if (!props.order.cartId) throw new Error("Missing cartId");
    toOrderDetail(props.order.cartId, ristopub.id as string, false, true);
  }

  function getIcon() {
    if (props.order.isCancelled() || props.order.isRejected()) {
      return (
        <span className={"text-red-600"}>
          <WarningIcon />
        </span>
      );
    } else if (props.order.isInProposedDateChangeState()) {
      return (
        <span className={"text-blue-600"}>
          <InfoIcon />
        </span>
      );
    } else {
      return (
        <span className={"text-green-600"}>
          <SuccessIcon />
        </span>
      );
    }
  }

  function getStatusClassName() {
    if (props.order.isCancelled() || props.order.isRejected()) {
      return "text-red-600";
    } else if (props.order.isInProposedDateChangeState()) {
      return "text-blue-600";
    } else {
      return "text-green-600";
    }
  }

  return (
    <OrdersListComponent
      icon={getIcon()}
      isInProgress={props.order.isInAnOpenedState()}
      ristopubName={ristopub.name}
      status={
        <span className={getStatusClassName()}>
          {props.order.formatStatus()}
        </span>
      }
      onClick={goToOrderDetail}
      isChanged={isChangedSinceLastSeen(props.order)}
    />
  );
}
