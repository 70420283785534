import { LoadingOutlined } from "@ant-design/icons";
import React from "react";

type PropTypes = {
  size?: number;
};

export function LoadingIcon(props: PropTypes) {
  return (
    <LoadingOutlined
      className={"text-swippy-orange"}
      style={{
        fontSize: props.size || 24,
      }}
      spin
    />
  );
}
