import { useGuestUserId } from "../OrderSummaryScreen/useGuestUserId";
import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { OrderDTO } from "./OrderDTO";
import _ from "lodash";
import { OrderDataMapper } from "./OrderDataMapper";
import { VirtualTableDataMapper } from "./VirtualTableDataMapper";
import { RistopubDataMapper } from "../Ristopub/RistopubDataMapper";
import { RistopubTakeawaySlotDataMapper } from "../Ristopub/RistopubTakeawaySlotDataMapper";

const USE_LAST_10_DAYS_ORDERS = gql`
  query getLast10DaysOrdersByGuestUserId(
    $input: GetPendingOrdersByGuestUserIdInput!
  ) {
    getLast10DaysOrdersByGuestUserId(input: $input) {
      id
      __typename
      cartId
      updatedAt
      deliveryCost
      statusChangedAt
      virtualTable {
        id
        ristopub {
          id
          name
          __typename
        }
        __typename
      }
      orderStatus {
        id
        proposedDateChange
      }
    }
  }
`;

const orderDataMapper = new OrderDataMapper(
  new VirtualTableDataMapper(
    new RistopubDataMapper(new RistopubTakeawaySlotDataMapper()),
  ),
);

export function useLast10DaysOrders(options: QueryHookOptions = {}) {
  const { guestUserId } = useGuestUserId();
  const { data, loading, refetch } = useQuery(USE_LAST_10_DAYS_ORDERS, {
    ...options,
    variables: {
      input: {
        guestUserId,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const orders: OrderDTO[] = _.get(
    data,
    "getLast10DaysOrdersByGuestUserId",
    [],
  );

  return {
    orders: _.map(orders, (o) => orderDataMapper.toEntity(o)),
    loading,
    refetch,
  };
}
