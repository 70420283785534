import { ProductModifierType } from "./ProductModifierType";
import { ProductModifierChoice } from "./ProductModifierChoice";

export class ProductModifier {
  constructor(
    public id: string,
    public type: ProductModifierType,
    public name?: string,
    public description?: string,
    public choices: ProductModifierChoice[] = [],
    public maxSelectableChoices?: number,
    public required?: boolean,
  ) {}

  public hasMax(): boolean {
    return (
      this.maxSelectableChoices !== undefined && this.maxSelectableChoices > 0
    );
  }

  public getMaxSelectableOptions(): number {
    return this.maxSelectableChoices && this.maxSelectableChoices > 0
      ? this.maxSelectableChoices
      : Infinity;
  }
}
