import { MainLayout } from "../MainLayout/MainLayout";
import React, { useMemo, useRef, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { createStripePromise } from "../stripePromise";
import { PaymentMethodSection } from "../StripeCheckoutForm/PaymentMethodSection";
import { useCurrentCart } from "../Cart/useCurrentCart";
import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { useCreateInStructureStripePaymentIntent } from "./useCreateInStructureStripePaymentIntent";
import { useParams } from "react-router-dom";
import { useToInStructureOrderDetailScreen } from "./useToInStructureOrderDetailScreen";
import { useGuestUserId } from "../OrderSummaryScreen/useGuestUserId";
import { GenericErrorHandler } from "../GenericErrorHandler/GenericErrorHandler";
import _ from "lodash";
import { CartItem } from "../Cart/classes/CartItem";
import { useGetSavedStripePaymentMethodId } from "../StripeCheckoutForm/useGetSavedStripePaymentMethodId";
import { ListLoading } from "../Loading/ListLoading";
import { useCompleteInStructureOrderWithCashPaymentFlow } from "./useCompleteInStructureOrderWithCashPaymentFlow";
import { InsertEmail } from "../Order/InsertEmail";
import { ErrorState } from "../ErrorState";

export function InStructureCheckoutScreen() {
  const {
    getCustomerPhone,
    getCustomerNotes,
    getItems,
    getCartId,
    getCustomerEmail,
    isValidCustomerEmail,
  } = useCurrentCart();

  const emailRef = useRef<HTMLDivElement>(null);

  const { ristopub } = useCurrentRistopub();
  const { createInStructureStripePaymentIntentMutation } =
    useCreateInStructureStripePaymentIntent();

  const [errorState, setErrorState] = useState<ErrorState>(new ErrorState());

  const { tableName } = useParams<{
    tableName: string;
  }>();
  const {
    savedStripePaymentMethodId,
    loadingSavedPaymentMethodId,
    last4digits,
  } = useGetSavedStripePaymentMethodId();
  const { toInStructureOrderDetailScreen } =
    useToInStructureOrderDetailScreen();

  const stripeAccId = ristopub?.ownerStripeAccountId;

  const stripePromise = useMemo(() => {
    if (!stripeAccId) return null;
    return createStripePromise(stripeAccId);
  }, [stripeAccId]);
  const { completingOrder, completeInStructureOrderWithCashPaymentFlow } =
    useCompleteInStructureOrderWithCashPaymentFlow();

  async function payWithCash() {
    await completeInStructureOrderWithCashPaymentFlow(false);
  }

  const { guestUserId } = useGuestUserId();
  function displayErrorToGui(e: Error) {
    const errorHandler = new GenericErrorHandler();
    errorHandler.handleError(e);
  }

  async function createPaymentIntent() {
    try {
      if (!ristopub) {
        displayErrorToGui(new Error("Missing ristopub"));
        return undefined;
      }
      const res = await createInStructureStripePaymentIntentMutation({
        variables: {
          input: {
            customerEmail: getCustomerEmail() as string,
            tableName,
            items: _.map(getItems(), (i: CartItem) => {
              return {
                qty: i.getQty(),
                productId: i.getProduct().id,
                modifiers: i.getOptionsGroupedByModifier().map((o) => {
                  return {
                    modifierId: o.modifierId,
                    choices: o.options.map((o) => {
                      return {
                        id: o.choiceId,
                        qty: o.choiceQty,
                      };
                    }),
                  };
                }),
              };
            }),
            ristopubId: ristopub.id,
            customerNotes: getCustomerNotes(),
            cartId: getCartId() as string,
            customerPhone: getCustomerPhone(),
            guestUserId,
          },
        },
      });

      return {
        clientSecret: _.get(
          res.data,
          "createInStructureStripePaymentIntent.clientSecret",
        ) as string,
        virtualTableId: _.get(
          res.data,
          "createInStructureStripePaymentIntent.virtualTable.id",
        ) as string,
      };
    } catch (e) {
      displayErrorToGui(e as Error);
      throw e;
    }
  }

  function redirectPostPayment() {
    const cartId = getCartId();
    if (!cartId || !ristopub) throw new Error("Missing cart id or ristopub");
    toInStructureOrderDetailScreen(
      cartId,
      ristopub.id as string,
      tableName,
      true,
    );
  }

  if (loadingSavedPaymentMethodId)
    return (
      <MainLayout withHeader={true}>
        <ListLoading />
      </MainLayout>
    );

  const buttonDisabled = !isValidCustomerEmail();

  function onEmailValidationFailed() {
    if (!emailRef.current) return console.error("Missing emailRef");

    const newErrorState = errorState.clone();
    newErrorState.enableError("email");
    setErrorState(newErrorState);
    emailRef.current.scrollIntoView();
  }

  function clearErrorIfNeeded(key: string) {
    if (errorState.hasError(key)) {
      const newErrorState = errorState.clone();
      newErrorState.clearError(key);
      setErrorState(newErrorState);
    }
  }

  return (
    <MainLayout screenTitle={"Completa il pagamento"} withHeader={true}>
      <div className={["p-4 bg-white overflow-hidden rounded-t-lg"].join(" ")}>
        <>
          <InsertEmail
            onType={() => {
              clearErrorIfNeeded("email");
            }}
            hasError={errorState.hasError("email")}
            ref={emailRef}
            className={"mb-6"}
          />
          {ristopub ? (
            <Elements stripe={stripePromise}>
              <PaymentMethodSection
                payButtonDisabled={buttonDisabled}
                payWithCashButtonDisabled={buttonDisabled}
                payWithStripeGaProps={{
                  gaCategory: "order_flow_in_structure",
                  gaLabel: "order_flow_in_structure_pay",
                }}
                savedStripePaymentMethodLast4={last4digits}
                cashLoading={completingOrder}
                completeOrderWithCash={payWithCash}
                payWithCashGaProps={{
                  gaCategory: "order_flow_in_structure",
                  gaLabel: "order_flow_in_structure_complete_order",
                }}
                savedStripePaymentMethodId={savedStripePaymentMethodId}
                ristopub={ristopub}
                redirectPostPayment={redirectPostPayment}
                getStripePaymentIntentClientSecret={createPaymentIntent}
                emailRequired={true}
                onEmailValidationFailed={onEmailValidationFailed}
              />
            </Elements>
          ) : null}
        </>
      </div>
    </MainLayout>
  );
}
