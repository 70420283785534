import { useForgetStripePaymentMethods } from "./useForgetStripePaymentMethods";
import { SwippyButton } from "../ui/SwippyButton/SwippyButton";
import { useGetSavedStripePaymentMethodId } from "../StripeCheckoutForm/useGetSavedStripePaymentMethodId";
import React from "react";
import { GenericErrorHandler } from "../GenericErrorHandler/GenericErrorHandler";
import { LoadingIcon } from "../ui/LoadingIcong";

export function ForgetStripePaymentMethodsButton() {
  const { forgetStripePaymentMethods, forgettingStripePaymentMethods } =
    useForgetStripePaymentMethods();
  const { savedStripePaymentMethodId } = useGetSavedStripePaymentMethodId();
  if (!savedStripePaymentMethodId) return null;

  async function forgetPaymentMethods() {
    try {
      await forgetStripePaymentMethods();
      window.location.reload();
    } catch (e) {
      const genericErrorHandler = new GenericErrorHandler();
      genericErrorHandler.handleError(e as Error);
      throw e;
    }
  }

  return (
    <button
      className={
        "font-bold flex items-center justify-center text-sm px-0 py-0 mt-2 w-full"
      }
      onClick={forgetPaymentMethods}
    >
      {forgettingStripePaymentMethods ? (
        <span className={"mr-2 text-swippy-orange flex"}>
          <LoadingIcon size={12} />
        </span>
      ) : null}
      <span
        className={
          forgettingStripePaymentMethods
            ? "text-gray-400"
            : "text-swippy-orange"
        }
      >
        Dimentica metodo di pagamento
      </span>
    </button>
  );
}
