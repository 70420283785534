import React from "react";

type PropTypes = {
  onClick?: () => void;
  className?: string;
};

export function ChevronRightIcon(props: PropTypes) {
  return (
    <svg
      onClick={props.onClick}
      className={[props.className || ""].join(" ")}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 5l7 7-7 7"
      />
    </svg>
  );
}
