import React from "react";
import scrollToElement from "scroll-to-element";

export function TestScreen() {
  return (
    <div
      style={{
        minHeight: 2000,
      }}
      className={"max-w-md mx-auto"}
    >
      <button
        onClick={() => {
          scrollToElement(".el", {
            duration: 1000,
            offset: 0,
          });
        }}
      >
        To element
      </button>

      <div style={{ marginTop: 1500 }} className={"el"}>
        Ciao
      </div>
    </div>
  );
}
