import { useHistory } from "react-router-dom";

export function useToAppHomeScreen() {
  const history = useHistory();

  function toAppHomeScreen() {
    history.push("/");
  }

  return {
    toAppHomeScreen,
  };
}
