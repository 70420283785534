export class OrderItemVariationChoice {
  constructor(
    public readonly choiceName: string,
    public readonly choicePrice: number = 0,
    public readonly choiceQty: number = 1,
  ) {}

  public getTotal(): number {
    return this.choiceQty * this.choicePrice;
  }
}
