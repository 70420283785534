import React from "react";
import { useToChooseTakeawayTimeScreen } from "./useToChooseTakeawayTimeScreen";
import { useToTakeawayMenuScreen } from "./useToTakeawayMenuScreen";
import { useToTakeawayCheckoutScreen } from "./useToTakeawayCheckoutScreen";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { TakeawaySummaryComponent } from "./TakeawaySummaryComponent";

export function TakeAwayOrderSummaryScreen() {
  const { toChooseTakeawayTimeScreen } = useToChooseTakeawayTimeScreen();

  const { toTakeawayMenu } = useToTakeawayMenuScreen();

  const { ristopubId } = useCurrentRistopubId();

  function toMenu() {
    toTakeawayMenu(ristopubId);
  }

  function goBack() {
    toChooseTakeawayTimeScreen();
  }

  const { toTakeawayCheckoutScreen } = useToTakeawayCheckoutScreen();

  function toCheckout() {
    toTakeawayCheckoutScreen(ristopubId);
  }

  return (
    <TakeawaySummaryComponent
      onBack={goBack}
      toMenu={toMenu}
      toCheckout={toCheckout}
    />
  );
}
