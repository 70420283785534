import { useHistory, useParams } from "react-router-dom";
import { useOrderByCartId } from "./useOrderByCartId";
import React, { useEffect } from "react";
import { MainLayout } from "../../MainLayout/MainLayout";
import { OrderDetail } from "./OrderDetail";
import { useOrderChangedListener } from "./useOrderChangedListener";
import { FullPageLoading } from "../../Loading/FullPageLoading";
import { useOrderLastSeen } from "../OrderLastSeenCtx/useOrderLastSeen";
import { useQueryParams } from "../../useQueryParams/useQueryParams";
import { useToAppHomeScreen } from "../../AppHomeScreen/useToAppHomeScreen";

export function OrderDetailScreen() {
  const { cartId } = useParams<{
    cartId: string;
    ristopubId: string;
  }>();

  const { toAppHomeScreen } = useToAppHomeScreen();
  const history = useHistory();
  const queryParams = useQueryParams();

  const { updateLastSeenOf } = useOrderLastSeen();

  const { order, loading, refetch } = useOrderByCartId(cartId, {
    pollInterval: 60 * 1000,
  });

  useEffect(() => {
    if (!order) return;
    updateLastSeenOf(order);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  async function refetchOrder() {
    await refetch();
  }

  useOrderChangedListener(order ? order.id : undefined, refetchOrder);

  if (loading) return <FullPageLoading withHeader={true} />;

  function goBack() {
    if (queryParams.get("fromOrdersList") === "1") {
      history.goBack();
    } else {
      toAppHomeScreen();
    }
  }

  return (
    <MainLayout goBack={goBack} withHeader={true}>
      {order ? <OrderDetail disableClick={true} order={order} /> : null}
    </MainLayout>
  );
}
