import { geocodeByAddress } from "react-places-autocomplete";
import _ from "lodash";
import { Address } from "../Cart/classes/Address/Address";

export class AddressStringToAddressConverter {
  private extractComponentByType(
    addressComponents: google.maps.GeocoderAddressComponent[],
    typeName: string,
  ): string | undefined {
    const ac = _.find(addressComponents, (ac) => ac.types.includes(typeName));

    return ac?.long_name;
  }

  private extractCity(
    addressComponents: google.maps.GeocoderAddressComponent[],
  ): string | undefined {
    return this.extractComponentByType(
      addressComponents,
      "administrative_area_level_3",
    );
  }

  private extractProvince(
    addressComponents: google.maps.GeocoderAddressComponent[],
  ): string | undefined {
    return this.extractComponentByType(
      addressComponents,
      "administrative_area_level_2",
    );
  }
  private extractPostalCode(
    addressComponents: google.maps.GeocoderAddressComponent[],
  ): string | undefined {
    return this.extractComponentByType(addressComponents, "postal_code");
  }
  private extractStreet(
    addressComponents: google.maps.GeocoderAddressComponent[],
  ): string | undefined {
    return this.extractComponentByType(addressComponents, "route");
  }
  private extractStreetNumber(
    addressComponents: google.maps.GeocoderAddressComponent[],
  ): string | undefined {
    return this.extractComponentByType(addressComponents, "street_number");
  }
  private extractCountry(
    addressComponents: google.maps.GeocoderAddressComponent[],
  ): string | undefined {
    return this.extractComponentByType(addressComponents, "country");
  }

  public async getAddress(address?: string): Promise<Address> {
    const resAddress = Address.create({});

    if (!address) return resAddress;

    const googleGeocoded = await geocodeByAddress(address);

    const firstRes = _.first(googleGeocoded);
    if (!firstRes) return resAddress;

    console.debug("firstRes.address_components", firstRes.address_components);

    resAddress.country = this.extractCountry(firstRes.address_components);
    resAddress.province = this.extractProvince(firstRes.address_components);
    resAddress.address = this.extractStreet(firstRes.address_components);
    resAddress.streetNumber = this.extractStreetNumber(
      firstRes.address_components,
    );
    resAddress.postalCode = this.extractPostalCode(firstRes.address_components);
    resAddress.city = this.extractCity(firstRes.address_components);

    return resAddress;
  }
}
