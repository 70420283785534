import { Badge } from "antd";
import React, { useRef } from "react";
import styles from "./SwippyMenu.module.scss";
import { MenuItem } from "./MenuItem/MenuItem";
import { useToOrders } from "./useToOrders";
import { SwippyLogotype } from "./SwippyLogotype/SwippyLogotype";
import { useLast10DaysOrdersCtx } from "../../Order/Last10DaysOrdersCtx/useLast10DaysOrdersCtx";
import _ from "lodash";
import { useOrderLastSeen } from "../../Order/OrderLastSeenCtx/useOrderLastSeen";
import { CloseIcon } from "../../ui/CloseIcon";
import { ShopIcon } from "../../ui/ShopIcon";
import { ActiveRouteIdFactory } from "../classes/ActiveRouteIdFactory";
import { useLocation } from "react-router-dom";
import { useAuthCtx } from "../../Auth/useAuthCtx";
import { useToLogout } from "../../Auth/useToLogout";

type PropTypes = {
  opened: boolean;
  onClose?: () => void;
};

export function SwippyMenu(props: PropTypes) {
  const { toOrders } = useToOrders();
  const { orders } = useLast10DaysOrdersCtx();
  const { isChangedSinceLastSeen } = useOrderLastSeen();
  const { user } = useAuthCtx();
  const { toLogout } = useToLogout();
  const notSeenOrders = _.filter(orders, (o) => isChangedSinceLastSeen(o));

  const location = useLocation();
  const { current: activeIdFactory } = useRef(
    new ActiveRouteIdFactory(location),
  );
  if (!props.opened) return null;

  const activeRoute = activeIdFactory.create();

  function goToOrders() {
    toOrders();
    if (props.onClose) props.onClose();
  }

  return (
    <div
      className={[styles.wrapper, "max-w-md ml-auto mr-auto z-30"].join(" ")}
    >
      <div className={styles.header}>
        <SwippyLogotype />
        <CloseIcon className={"cursor-pointer"} onClick={props.onClose} />
      </div>
      <div className={[styles.items, "mt-4 space-y-4"].join(" ")}>
        <MenuItem
          selected={
            activeRoute === "orders" || activeRoute === "order-detail-page"
          }
          icon={<ShopIcon />}
          onClick={goToOrders}
          label={
            <Badge
              offset={["10", "0"]}
              showZero={false}
              count={notSeenOrders.length}
            >
              <span>Ordini</span>
            </Badge>
          }
        />
        {user ? (
          <MenuItem
            icon={<ShopIcon />}
            onClick={toLogout}
            label={<span>Esci</span>}
          />
        ) : null}
      </div>
    </div>
  );
}
