import { AvailabilityType } from "./AvailabilityType";
import moment, { Moment } from "moment-timezone";
import { gql, useQuery } from "@apollo/client";
import { useCurrentRistopubId } from "../../useCurrentRistopubId/useCurrentRistopubId";
import { ClosedDatesDecorator } from "./ClosedDatesDecorator";

const GET_CLOSED_DATES = gql`
  query getClosedDates($input: GetClosedDatesInput!) {
    getClosedDates(input: $input) {
      dates
    }
  }
`;

export function useClosedDates(
  type: AvailabilityType,
  currentMonthDate?: Moment,
) {
  const startDate = currentMonthDate
    ? moment(currentMonthDate).utc().startOf("month").subtract(8, "days")
    : moment();
  const endDate = currentMonthDate
    ? moment(currentMonthDate).utc().endOf("month").add(8, "days")
    : moment();
  const { ristopubId } = useCurrentRistopubId();
  const { data, loading } = useQuery<
    {
      getClosedDates: {
        dates: string[];
      };
    },
    {
      input: {
        endDate: string;
        ristopubId: string;
        startDate: string;
        type: AvailabilityType;
      };
    }
  >(GET_CLOSED_DATES, {
    variables: {
      input: {
        ristopubId,
        type: type,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
    },
    skip: !currentMonthDate,
  });

  return {
    closedDatesDecorator: new ClosedDatesDecorator(
      data?.getClosedDates.dates.map((d) => moment(d)),
    ),
    loading,
  };
}
