import { gql, useMutation } from "@apollo/client";

const ACCEPT_DATE_CHANGE_MUTATION = gql`
  mutation acceptOrderDateChange($input: AcceptOrderDateChangeInput!) {
    acceptOrderDateChange(input: $input) {
      id
      __typename
      orders {
        id
        orderStatus {
          id
          proposedDateChange
        }
        __typename
      }
    }
  }
`;
const CANCEL_ORDER_MUTATION = gql`
  mutation customerCancelOrder($input: CustomerCancelOrderInput!) {
    customerCancelOrder(input: $input) {
      id
      __typename
      orders {
        id
        orderStatus {
          id
          proposedDateChange
        }
        __typename
      }
    }
  }
`;

export function useChangeOrderStatusMutations() {
  const [cancelOrderMutation] = useMutation(CANCEL_ORDER_MUTATION);
  const [acceptDateChangeMutation] = useMutation(ACCEPT_DATE_CHANGE_MUTATION);

  return {
    cancelOrderMutation,
    acceptDateChangeMutation,
  };
}
