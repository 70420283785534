export class CartItemSelectedOption {
  constructor(
    public readonly modifierId: string,
    public readonly choiceId: string,
    public readonly modifierName: string,
    public readonly choiceName: string,
    public readonly choicePrice: number = 0,
    public choiceQty: number = 1,
  ) {}

  public getKey(): string {
    return `modifierId:${this.modifierId}-choiceId:${this.choiceId}-qty:${this.choiceQty}`;
  }

  public getTotalPrice(): number {
    return this.choicePrice * this.choiceQty;
  }

  public increaseQty(): void {
    this.choiceQty++;
  }

  decreaseQty() {
    this.choiceQty--;
  }
}
