import { useHistory } from "react-router-dom";

export function useToTakeawayCheckoutScreenWithQrCode() {
  const history = useHistory();

  function toTakeawayCheckoutScreenWithQrCode(
    ristopubId: string,
    qrCodeId: string,
  ) {
    history.push(
      `/ristopub/${ristopubId}/qrcode/${qrCodeId}/takeaway/checkout`,
    );
  }

  return {
    toTakeawayCheckoutScreenWithQrCode,
  };
}
