import { Image } from "./Image";
import { ProductModifier } from "./ProductModifier";

type CreateProductInput = {
  id: string;
  name: string;
  price?: number;
  description?: string;
  image?: string;
  pos: number;
  categoryId: string;
  subTitle?: string;
  modifiers?: ProductModifier[];
};

export class Product {
  private constructor(
    public id: string,
    public name: string,
    public pos: number,
    public readonly categoryId: string,
    public price?: number,
    public description?: string,
    private image?: Image,
    public readonly subTitle?: string,
    public readonly modifiers: ProductModifier[] = [],
  ) {}

  public static create({
    id,
    name,
    price,
    description,
    image,
    pos,
    categoryId,
    subTitle,
    modifiers,
  }: CreateProductInput) {
    const imgObj = image ? new Image(image) : undefined;

    return new Product(
      id,
      name,
      pos,
      categoryId,
      price,
      description,
      imgObj,
      subTitle,
      modifiers,
    );
  }

  public getImageSrc() {
    return this.image ? this.image.src : undefined;
  }

  public hasModifiers(): boolean {
    return this.modifiers.length > 0;
  }
}
