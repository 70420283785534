import { gql, useMutation } from "@apollo/client";
import { VirtualTableDTO } from "../Order/VirtualTableDTO";
import { AddOrderItemInput } from "../Order/AddOrderItemInput";

const CREATE_IN_STRUCTURE_STRIPE_PAYMENT_INTENT_MUTATION = gql`
  mutation createInStructureStripePaymentIntent(
    $input: CreateInStructureStripePaymentIntentInput!
  ) {
    createInStructureStripePaymentIntent(input: $input) {
      clientSecret
      virtualTable {
        id
      }
    }
  }
`;

export function useCreateInStructureStripePaymentIntent() {
  const [createInStructureStripePaymentIntentMutation, { loading }] =
    useMutation<
      {
        createInStructureStripePaymentIntent: {
          clientSecret: string;
          virtualTable: VirtualTableDTO;
        };
      },
      {
        input: {
          cartId: string;
          customerEmail: string;
          customerNotes?: string;
          customerPhone?: string;
          guestUserId: string;
          items: AddOrderItemInput[];
          ristopubId: string;
          selfPickup?: boolean;
          tableName: string;
        };
      }
    >(CREATE_IN_STRUCTURE_STRIPE_PAYMENT_INTENT_MUTATION);

  return {
    createInStructureStripePaymentIntentMutation,
    creatingInStructureStripePaymentIntent: loading,
  };
}
