import PhoneInput from "react-phone-number-input";
import React from "react";
import styles from "./SwippyPhoneInput.module.scss";

type PropTypes = {
  value?: string;
  onChange?: (newPhone: string) => void;
  hasError?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
};

export function SwippyPhoneInput(props: PropTypes) {
  return (
    <PhoneInput
      autoFocus={props.autoFocus}
      placeholder={props.placeholder}
      international={false}
      defaultCountry={"IT"}
      style={{
        height: 40,
      }}
      className={[
        "border-2 rounded-full px-3 py-1",
        styles.swippyPhoneInput,
        "swippyPhoneInput",
        props.hasError ? "border-red-500" : "border-gray-300",
      ].join(" ")}
      onChange={(v) => {
        if (props.onChange) props.onChange(v);
      }}
      value={props.value || ""}
    />
  );
}
