import { gql, useQuery } from "@apollo/client";
import { useRef } from "react";
import { QrCodeDataMapper } from "./QrCodeDataMapper";
import { QrCodeDTO } from "./QrCodeDTO";

const GET_QR_CODE_QUERY = gql`
  query getQrCode($input: GetQrCodeInput!) {
    getQrCode(input: $input) {
      enableDeliveryToLocation
      enablePickup
      id
      name
      ristopubId
      type
      enableDelivery
      enableTakeaway
    }
  }
`;

export function useQrCode(qrCodeId: string) {
  const { current: dataMapper } = useRef(new QrCodeDataMapper());

  const { data, loading } = useQuery<
    {
      getQrCode: QrCodeDTO;
    },
    {
      input: {
        qrCodeId: string;
      };
    }
  >(GET_QR_CODE_QUERY, {
    variables: {
      input: {
        qrCodeId: qrCodeId,
      },
    },
  });

  return {
    qrCode: data?.getQrCode ? dataMapper.toEntity(data.getQrCode) : undefined,
    loading,
  };
}
