import React, { useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import { useRedirectUrlController } from "./useRedirectUrlController";

export function LogoutScreen() {
  const { redirect } = useRedirectUrlController();
  useEffect(() => {
    signOut(getAuth())
      .then(() => {
        if (redirect) window.location.href = redirect;
        else window.location.href = "/";
      })
      .catch((e) => {
        throw e;
      });
  }, [redirect]);
  return <p>Logging out...</p>;
}
