import React from "react";
import { MobileTouchFeedbackWrapper } from "../../../ui/MobileTouchFeedbackWrapper/MobileTouchFeedbackWrapper";

type PropTypes = {
  label: string | React.ReactNode;
  onClick?: () => void;
  icon: React.ReactNode;
  selected?: boolean;
};

export function MenuItem(props: PropTypes) {
  return (
    <MobileTouchFeedbackWrapper>
      {({ touchHover }) => {
        return (
          <div
            onClick={props.onClick}
            className={[
              "bg-white font-bold rounded cursor-pointer  border-2  p-2 flex items-center justify-center text-gray-600",
              props.selected || touchHover ? "bg-gray-200 " : "border-gray-200",
            ].join(" ")}
          >
            {props.icon}
            <span className={"text-base ml-1"}>{props.label}</span>
          </div>
        );
      }}
    </MobileTouchFeedbackWrapper>
  );
}
