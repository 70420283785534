import { gql, useMutation } from "@apollo/client";
import { VirtualTableDTO } from "../Order/VirtualTableDTO";
import { AddressDTO } from "../Cart/classes/Address/AddressDTO";
import { AddOrderItemInput } from "../Order/AddOrderItemInput";
import { useQrCodeId } from "../QrCode/useQrCodeId";

const CREATE_DELIVERY_PI_MUTATION = gql`
  mutation createDeliveryStripePaymentIntent(
    $input: CreateDeliveryStripePaymentIntentInput!
  ) {
    createDeliveryStripePaymentIntent(input: $input) {
      clientSecret
      virtualTable {
        id
      }
    }
  }
`;

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

type CreateDeliveryPaymentIntentInput = {
  deliveryAddress: Omit<Required<AddressDTO>, "streetNumber">;
  deliveryDate: Date;
  customerPhone: string;
  customerName: string;
  customerNotes?: string;
  items: AddOrderItemInput[];
  ristopubId: string;
  guestUserId: string;
  cartId: string;
  customerEmail: string;
  qrCodeId?: string;
};

export function useCreateDeliveryPaymentIntent() {
  const { qrcodeId } = useQrCodeId();

  const [createDeliveryPiMutation, loading] = useMutation<
    {
      createDeliveryStripePaymentIntent: {
        clientSecret: string;
        virtualTable: VirtualTableDTO;
      };
    },
    {
      input: CreateDeliveryPaymentIntentInput;
    }
  >(CREATE_DELIVERY_PI_MUTATION);

  async function createDeliveryPaymentIntent(
    input: CreateDeliveryPaymentIntentInput,
  ) {
    const res = await createDeliveryPiMutation({
      variables: {
        input: {
          qrCodeId: qrcodeId,
          deliveryAddress: input.deliveryAddress,
          deliveryDate: input.deliveryDate,
          customerPhone: input.customerPhone,
          customerName: input.customerName,
          customerNotes: input.customerNotes,
          items: input.items,
          ristopubId: input.ristopubId,
          guestUserId: input.guestUserId,
          cartId: input.cartId,
          customerEmail: input.customerEmail,
        },
      },
    });

    return {
      clientSecret: res.data?.createDeliveryStripePaymentIntent.clientSecret,
      virtualTable: res.data?.createDeliveryStripePaymentIntent.virtualTable,
    };
  }

  return {
    createDeliveryPaymentIntent,
    loading,
  };
}
