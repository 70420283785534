import { useCurrentRistopubSlug } from "./useCurrentRistopubSlug";
import { useRistopubBySlug } from "./useRistopubBySlug";
import { FullPageLoading } from "../Loading/FullPageLoading";
import React, { useEffect } from "react";
import { useToChooseShippingMethodScreen } from "../ChooseShippingMethodScreen/useToChooseShippingMethodScreen";
import { NotFoundScreen } from "../NotFoundScreen";

export function RistopubSlugTakeawayAndDeliveryScreen() {
  const { ristopubSlug } = useCurrentRistopubSlug();
  const { ristopub, loading } = useRistopubBySlug(ristopubSlug);

  const { toChooseShippingMethodScreen } = useToChooseShippingMethodScreen();

  useEffect(() => {
    if (!ristopub) return;
    toChooseShippingMethodScreen(ristopub.id);
  }, [ristopub ? ristopub.id : undefined]);

  if (!loading && !ristopub) return <NotFoundScreen />;

  return <FullPageLoading />;
}
