import { gql, useApolloClient } from "@apollo/client";
import { useState } from "react";
import { Address } from "./classes/Address/Address";
import { AddressDTO } from "./classes/Address/AddressDTO";

const GET_SHIPPING_COST_QUERY = gql`
  query getShippingCost($input: GetShippingCostInput!) {
    getShippingCost(input: $input) {
      shippingCost
    }
  }
`;

export function useGetShippingCostQuery() {
  const [loading, setLoading] = useState(false);

  const client = useApolloClient();

  async function getShippingCost(
    ristopubId: string,
    orderAmount: number,
    address: Address,
  ) {
    setLoading(true);
    try {
      const res = await client.query<
        {
          getShippingCost: {
            shippingCost: number;
          };
        },
        {
          input: {
            address: AddressDTO;
            orderAmount: number;
            ristopubId: string;
          };
        }
      >({
        query: GET_SHIPPING_COST_QUERY,
        variables: {
          input: {
            address: {
              address: address.getStreetWithStreetNumber(),
              city: address.city,
              country: address.country,
              postalCode: address.postalCode,
              province: address.province,
            },
            ristopubId,
            orderAmount,
          },
        },
      });

      return res.data.getShippingCost.shippingCost || 0;
    } finally {
      setLoading(false);
    }
  }

  return {
    getShippingCost,
    loading,
  };
}
