import { useCurrentCart } from "../Cart/useCurrentCart";
import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { useCreateTakeawayStripePaymentIntent } from "./useCreateTakeawayStripePaymentIntent";
import { useGetSavedStripePaymentMethodId } from "../StripeCheckoutForm/useGetSavedStripePaymentMethodId";
import { useGuestUserId } from "../OrderSummaryScreen/useGuestUserId";
import { useToOrderDetail } from "./useToOrderDetail";
import React, { useMemo, useRef, useState } from "react";
import { useCompleteTakeawayOrderWithCashPaymentFlow } from "./useCompleteTakeawayOrderWithCashPaymentFlow";
import { ErrorState } from "../ErrorState";
import { GenericErrorHandler } from "../GenericErrorHandler/GenericErrorHandler";
import _ from "lodash";
import { CartItem } from "../Cart/classes/CartItem";
import { MainLayout } from "../MainLayout/MainLayout";
import { ListLoading } from "../Loading/ListLoading";
import { CantOrderForPastTakeawayDateModal } from "../Order/CantOrderForPastTakeawayDateModal";
import { InsertEmail } from "../Order/InsertEmail";
import { InsertPhone } from "../Order/InsertPhone";
import { Elements } from "@stripe/react-stripe-js";
import { createStripePromise } from "../stripePromise";
import { PaymentMethodSection } from "../StripeCheckoutForm/PaymentMethodSection";
import { useQrCodeId } from "../QrCode/useQrCodeId";

type Props = {};

export function TakeawayCheckoutComponent(props: Props) {
  const { qrcodeId } = useQrCodeId();

  const {
    getCustomerPhone,
    getCustomerNotes,
    getCustomerName,
    getItems,
    getCartId,
    getCustomerEmail,
    getSelectedDueDate,
    isInPlace,
    isValidCustomerPhone,
    isValidCustomerEmail,
  } = useCurrentCart();
  const { ristopub } = useCurrentRistopub();
  const { createTakeawayStripePaymentIntentMutation } =
    useCreateTakeawayStripePaymentIntent();
  const {
    savedStripePaymentMethodId,
    loadingSavedPaymentMethodId,
    last4digits,
  } = useGetSavedStripePaymentMethodId();
  const { guestUserId } = useGuestUserId();

  const { toOrderDetail } = useToOrderDetail();
  const [showPastDateModal, setShowPastDateModal] = useState(false);

  const {
    completingOrder: completingOrderWithCash,
    completeTakeawayOrderWithCashPaymentFlow,
  } = useCompleteTakeawayOrderWithCashPaymentFlow();
  const [errorState, setErrorState] = useState<ErrorState>(new ErrorState());

  const emailRef = useRef<HTMLDivElement>(null);
  const phoneRef = useRef<HTMLDivElement>(null);

  const stripeAccId = ristopub?.ownerStripeAccountId;

  const stripePromise = useMemo(() => {
    if (!stripeAccId) return null;
    return createStripePromise(stripeAccId);
  }, [stripeAccId]);

  function onEmailValidationFailed() {
    if (!emailRef.current) return console.error("Missing emailRef");

    const newErrorState = errorState.clone();
    newErrorState.enableError("email");
    setErrorState(newErrorState);
    emailRef.current.scrollIntoView();
  }

  function onMobileValidationFailed() {
    if (!phoneRef.current) return console.error("Missing phoneRef");

    const newErrorState = errorState.clone();
    newErrorState.enableError("mobile");
    setErrorState(newErrorState);
    phoneRef.current.scrollIntoView();
  }

  function displayErrorToGui(e: Error) {
    const errorHandler = new GenericErrorHandler();
    errorHandler.handleError(e);
  }

  async function createPaymentIntent() {
    try {
      if (!ristopub) {
        displayErrorToGui(new Error("Missing ristopub"));
        return undefined;
      }
      const res = await createTakeawayStripePaymentIntentMutation({
        variables: {
          input: {
            qrCodeId: qrcodeId,
            isInPlace,
            customerName: getCustomerName(),
            customerEmail: getCustomerEmail() as string,
            items: _.map(getItems(), (i: CartItem) => {
              return {
                qty: i.getQty(),
                productId: i.getProduct().id,
                modifiers: i.getOptionsGroupedByModifier().map((o) => {
                  return {
                    modifierId: o.modifierId,
                    choices: o.options.map((o) => {
                      return {
                        id: o.choiceId,
                        qty: o.choiceQty,
                      };
                    }),
                  };
                }),
              };
            }),
            ristopubId: ristopub.id,
            customerNotes: getCustomerNotes(),
            cartId: getCartId() as string,
            customerPhone: getCustomerPhone() as string,
            guestUserId,
            dueDate: getSelectedDueDate()?.toDate() as Date,
          },
        },
      });

      return {
        clientSecret: _.get(
          res.data,
          "createTakeawayStripePaymentIntent.clientSecret",
        ) as string,
        virtualTableId: _.get(
          res.data,
          "createTakeawayStripePaymentIntent.virtualTable.id",
        ) as string,
      };
    } catch (e) {
      displayErrorToGui(e as Error);
      throw e;
    }
  }

  function redirectPostPayment() {
    const cartId = getCartId();
    if (!cartId || !ristopub) throw new Error("Missing cart id or ristopub");
    toOrderDetail(cartId, ristopub.id, true);
  }

  if (loadingSavedPaymentMethodId)
    return (
      <MainLayout withHeader={true}>
        <ListLoading />
      </MainLayout>
    );

  function openPastDateModalIfNeeded() {
    if (ristopub?.isBefore(getSelectedDueDate())) {
      setShowPastDateModal(true);
      return true;
    } else {
      return false;
    }
  }

  async function completeOrder() {
    if (openPastDateModalIfNeeded()) return;
    await completeTakeawayOrderWithCashPaymentFlow();
  }

  function clearErrorIfNeeded(key: string) {
    if (errorState.hasError(key)) {
      const newErrorState = errorState.clone();
      newErrorState.clearError(key);
      setErrorState(newErrorState);
    }
  }

  const buttonDisabled = !isValidCustomerPhone() || !isValidCustomerEmail();

  return (
    <MainLayout screenTitle={"Completa il pagamento"} withHeader={true}>
      <CantOrderForPastTakeawayDateModal
        visible={showPastDateModal}
        onClose={() => setShowPastDateModal(false)}
      />

      <div className={["bg-white rounded-t-lg p-4"].join(" ")}>
        <>
          <InsertEmail
            onType={() => {
              clearErrorIfNeeded("email");
            }}
            hasError={errorState.hasError("email")}
            ref={emailRef}
            className={"mb-6"}
          />

          <InsertPhone
            hasError={errorState.hasError("mobile")}
            onType={() => {
              clearErrorIfNeeded("mobile");
            }}
            ref={phoneRef}
            label={
              "Ti contatteremo in caso di comunicazioni riguardanti il tuo ordine"
            }
            className={"mb-6"}
          />

          {ristopub ? (
            <Elements stripe={stripePromise}>
              <PaymentMethodSection
                payButtonDisabled={buttonDisabled}
                payWithCashButtonDisabled={buttonDisabled}
                payWithStripeGaProps={{
                  gaCategory: "takeaway_order_flow",
                  gaLabel: "takeaway_order_flow_pay",
                }}
                payWithCashGaProps={{
                  gaCategory: "takeaway_order_flow",
                  gaLabel: "takeaway_order_flow__checkout_complete_order",
                }}
                cashLoading={completingOrderWithCash}
                completeOrderWithCash={completeOrder}
                savedStripePaymentMethodLast4={last4digits}
                savedStripePaymentMethodId={savedStripePaymentMethodId}
                redirectPostPayment={redirectPostPayment}
                ristopub={ristopub}
                getStripePaymentIntentClientSecret={createPaymentIntent}
                emailRequired={true}
                mobileRequired={true}
                onMobileValidationFailed={onMobileValidationFailed}
                onEmailValidationFailed={onEmailValidationFailed}
              />
            </Elements>
          ) : null}
        </>
      </div>
    </MainLayout>
  );
}
