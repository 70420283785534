import { Input } from "antd";
import { InputProps } from "antd/lib/input/Input";
import React from "react";
import styles from "./SwippyInput.module.scss";

type PropTypes = InputProps & {
  hasError?: boolean;
};
export const SwippyInput = React.forwardRef<Input, PropTypes>(
  ({ hasError, ...props }: PropTypes, ref) => {
    const inputClasses: string[] = [
      styles.swippyInput,
      "leading-none border-2 hover:border-swippy-orange focus:border-swippy-orange rounded-full",
      hasError ? "border-red-500" : "border-gray-300",
    ];
    if (props.className) inputClasses.push(props.className);
    return <Input ref={ref} {...props} className={inputClasses.join(" ")} />;
  },
);
