import { OrderItemDTO } from "./OrderItemDTO";
import { OrderItem } from "./OrderItem";
import { ProductDataMapper } from "../../RistopubMenu/Product/ProductDataMapper";
import { OrderItemVariationDataMapper } from "./OrderItemVariationDataMapper";

export class OrderItemDataMapper {
  constructor(
    private productDataMapper: ProductDataMapper,
    private orderItemVariationDataMapper: OrderItemVariationDataMapper = new OrderItemVariationDataMapper(),
  ) {}

  toEntity(d: OrderItemDTO): OrderItem {
    return OrderItem.create({
      id: d.id,
      product: this.productDataMapper.toEntity(d.product),
      qty: d.qty,
      variations: d.variations?.map((v) =>
        this.orderItemVariationDataMapper.toEntity(v),
      ),
    });
  }
}
