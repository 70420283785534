import { RistopubDTO } from "./RistopubDTO";
import { Ristopub } from "./Ristopub";
import { RistopubTakeawaySlotDataMapper } from "./RistopubTakeawaySlotDataMapper";
import _ from "lodash";

export class RistopubDataMapper {
  constructor(private takeAwaySlotDataMapper: RistopubTakeawaySlotDataMapper) {}

  toEntity(d: RistopubDTO): Ristopub {
    return Ristopub.create({
      id: d.id,
      enableTakeaway: d.enableTakeaway,
      featuredImageSrc: d.featuredImageSrc,
      minimumDeliveryOrderValue: d.minimumDeliveryOrderValue,
      minimumTakeawayOrderValue: d.minimumTakeawayOrderValue,
      enableDelivery: d.enableDelivery,
      enableCashPayments: d.enableCashPayments,
      ownerStripeAccountId: d.ownerStripeAccountId,
      enableInAppPayments: d.enableInAppPayments,
      name: d.name,
      cutoffInMinutes: d.cutoffInMinutes,
      minimumOrderValueForFreeShipping: d.minimumOrderValueForFreeShipping,
      takeawaySlots: _.map(d.takeawaySlots, (s) =>
        this.takeAwaySlotDataMapper.toEntity(s),
      ),
    });
  }
}
