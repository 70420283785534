import React, { useRef, useState } from "react";
import { SwippyButton } from "../ui/SwippyButton/SwippyButton";
import { SwippyTextarea } from "../ui/SwippyTextarea/SwippyTextarea";
import { Input } from "antd";
import { useCurrentCart } from "../Cart/useCurrentCart";

import styles from "./AddOrderNotes.module.scss";

export function AddOrderNotes() {
  const { setCustomerNotes, getCustomerNotes } = useCurrentCart();
  const notes = getCustomerNotes();
  const [showOrderNotesTextArea, setShowOrderNotesTextArea] = useState(
    notes && notes.length > 0,
  );
  const textAreaRef = useRef<Input>(null);

  function enableOrderNotesTextArea() {
    setShowOrderNotesTextArea(true);
  }

  return (
    <div>
      {!showOrderNotesTextArea ? (
        <SwippyButton
          className={"text-base"}
          gaCategory={"order_flow"}
          gaLabel={"want_to_add_order_notes"}
          type={"link"}
          onClick={enableOrderNotesTextArea}
        >
          Aggiungi richieste speciali
        </SwippyButton>
      ) : null}
      {showOrderNotesTextArea ? (
        <div>
          <SwippyTextarea
            className={styles.textArea}
            onChange={(e) => setCustomerNotes(e.target.value)}
            defaultValue={notes}
            autoFocus={true}
            placeholder={
              "Scrivi qui le tue note speciali per l'ordine (max 200 caratteri)"
            }
            maxLength={200}
            ref={textAreaRef}
          />
        </div>
      ) : null}
    </div>
  );
}
