import { Product } from "../Product/Product";

type CreateCategoryInput = {
  id: string;
  name: string;
  description?: string;
  products?: Product[];
  pos: number;
};

export class Category {
  private constructor(
    public id: string,
    public name: string,
    public pos: number,
    private products: Product[] = [],
    public description?: string,
  ) {}

  public getProducts(): Product[] {
    return this.products;
  }

  public static create({
    id,
    name,
    products,
    description,
    pos,
  }: CreateCategoryInput) {
    return new Category(id, name, pos, products, description);
  }
}
