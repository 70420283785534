import { useQrCodeId } from "./useQrCodeId";
import { useQrCode } from "./useQrCode";
import { FullPageLoading } from "../Loading/FullPageLoading";
import React, { useEffect } from "react";
import { QrCode } from "./QrCode";
import { useToInStructureOrderMenuScreen } from "../InStructureOrders/useToInStructureOrderMenuScreen";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useToInStructureSelfPickupMenuScreen } from "../InStructureOrders/useToInStructureSelfPickupMenuScreen";
import { useToInStructureChoosePickupMethodScreen } from "../InStructureOrders/useToInStructureChoosePickupMethodScreen";
import { QrCodeType } from "./QrCodeType";
import { useToChooseShippingMethodWithQrCode } from "../ChooseShippingMethodScreen/useToChooseShippingMethodWithQrCode";
import { useToTakeawayMenuScreenWithQrCode } from "../Takeaway/useToTakeawayMenuScreenWithQrCode";
import { useToDeliveryInsertAddressScreenWithQrCode } from "../Delivery/useToDeliveryInsertAddressScreenWithQrCode";

export function QrCodeScreen() {
  const { qrcodeId } = useQrCodeId();

  const { qrCode, loading } = useQrCode(qrcodeId);
  const { ristopubId } = useCurrentRistopubId();

  const qrCodeId = qrCode ? qrCode.id : undefined;

  const { toInStructureOrderMenuScreen } = useToInStructureOrderMenuScreen();
  const { toInStructureSelfPickupMenuScreen } =
    useToInStructureSelfPickupMenuScreen();
  const { toInStructurePickupMethodScreen } =
    useToInStructureChoosePickupMethodScreen();

  const { toChooseShippingMethodWithQrCode } =
    useToChooseShippingMethodWithQrCode();
  const { toTakeawayMenuScreenWithQrCode } =
    useToTakeawayMenuScreenWithQrCode();
  const { toDeliveryInsertAddressScreenWithQrCode } =
    useToDeliveryInsertAddressScreenWithQrCode();

  function redirectInLocoQrCode(qrCode: QrCode) {
    if (qrCode.enablePickup && qrCode.enableDeliveryToLocation) {
      toInStructurePickupMethodScreen(ristopubId, qrCode.name);
    } else if (qrCode.enablePickup) {
      toInStructureSelfPickupMenuScreen(ristopubId, qrCode.name);
    } else if (qrCode.enableDeliveryToLocation) {
      toInStructureOrderMenuScreen(ristopubId, qrCode.name);
    } else {
      //TODO Caso in cui non supporto nulla, mostro errore
    }
  }

  function redirectDeliveryAndTakeawayQrCode(qrCode: QrCode) {
    if (qrCode.enableDelivery && qrCode.enableTakeaway) {
      toChooseShippingMethodWithQrCode(ristopubId, qrCode.id);
    } else if (qrCode.enableTakeaway) {
      toTakeawayMenuScreenWithQrCode(ristopubId, qrCode.id);
    } else if (qrCode.enableDelivery) {
      toDeliveryInsertAddressScreenWithQrCode(ristopubId, qrCode.id);
    } else {
      //TODO Caso in cui non supporto nulla, mostro errore
    }
  }

  function redirectByQrCode(qrCode: QrCode) {
    switch (qrCode.type) {
      case QrCodeType.DELIVERY_AND_TAKEAWAY:
        return redirectDeliveryAndTakeawayQrCode(qrCode);
      case QrCodeType.IN_LOCO:
        return redirectInLocoQrCode(qrCode);
    }
  }

  useEffect(() => {
    if (!qrCode) return;
    redirectByQrCode(qrCode);
  }, [qrCodeId]);

  if (loading) return <FullPageLoading />;

  return null;
}
