import React from "react";
import { Address } from "../Cart/classes/Address/Address";
import { AddressDataMapper } from "../Cart/classes/Address/AddressDataMapper";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useToChooseShippingMethodScreen } from "../ChooseShippingMethodScreen/useToChooseShippingMethodScreen";
import { useToDeliveryMenuScreen } from "./useToDeliveryMenuScreen";
import { DeliveryInsertAddressComponent } from "./DeliveryInsertAddressComponent";

export function DeliveryInsertAddressScreen() {
  const { ristopubId } = useCurrentRistopubId();

  const { toChooseShippingMethodScreen } = useToChooseShippingMethodScreen();

  const { toDeliveryMenu } = useToDeliveryMenuScreen();

  function toChooseShippingMethod() {
    toChooseShippingMethodScreen(ristopubId);
  }

  function toMenu() {
    toDeliveryMenu(ristopubId);
  }

  return (
    <DeliveryInsertAddressComponent
      onBack={toChooseShippingMethod}
      toMenuScreen={toMenu}
    />
  );
}
