import React from "react";
import styles from "./ChooseAvailabilityItem.module.scss";
import { safeGtag } from "../../safeGtag/safeGtag";
import { MobileTouchFeedbackWrapper } from "../../ui/MobileTouchFeedbackWrapper/MobileTouchFeedbackWrapper";

type PropTypes = {
  time: string;
  onClick?: () => void;
  selected?: boolean;
};

function track(gaAction = "click", gaCategory?: string, gaLabel?: string) {
  if (!gaCategory && !gaLabel) return;
  safeGtag("event", gaAction, {
    event_category: gaCategory,
    event_label: gaLabel,
  });
}

export function TimeSelectorListItem(props: PropTypes) {
  return (
    <MobileTouchFeedbackWrapper>
      {({ touchHover }) => {
        const classes = [
          "border h-12 cursor-pointer border-swippy-orange rounded flex items-center justify-center p1 px-2",
          touchHover || props.selected
            ? "bg-swippy-orange text-white"
            : "text-swippy-orange",
        ];

        return (
          <div
            onClick={
              props.onClick
                ? () => {
                    if (!props.selected)
                      track(
                        "click",
                        "order_flow",
                        "select_availability_" + props.time,
                      );
                    if (props.onClick) props.onClick();
                  }
                : undefined
            }
            className={classes.join(" ")}
          >
            <span className={styles.time}>{props.time}</span>
          </div>
        );
      }}
    </MobileTouchFeedbackWrapper>
  );
}
