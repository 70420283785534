import React from "react";
import { DeliveryOrderSummaryComponent } from "./DeliveryOrderSummaryComponent";
import { useToDeliveryChooseTimeScreenWithQrCode } from "./useToDeliveryChooseTimeScreenWithQrCode";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useQrCodeId } from "../QrCode/useQrCodeId";
import { useToDeliveryCheckoutScreenWithQrCode } from "./useToDeliveryCheckoutScreenWithQrCode";
import { useToDeliveryMenuScreenWithQrCode } from "./useToDeliveryMenuScreenWithQrCode";

export function DeliverySummaryScreenWithQrCode() {
  const { ristopubId } = useCurrentRistopubId();
  const { qrcodeId } = useQrCodeId();

  const { toDeliveryChooseTimeScreenWithQrCode } =
    useToDeliveryChooseTimeScreenWithQrCode();

  const { toDeliveryCheckoutScreenWithQrCode } =
    useToDeliveryCheckoutScreenWithQrCode();

  const { toDeliveryMenuScreenWithQrCode } =
    useToDeliveryMenuScreenWithQrCode();

  function toChooseTimeScreen() {
    toDeliveryChooseTimeScreenWithQrCode(ristopubId, qrcodeId);
  }

  function toCheckoutScreen() {
    toDeliveryCheckoutScreenWithQrCode(ristopubId, qrcodeId);
  }

  function toMenuScreen() {
    toDeliveryMenuScreenWithQrCode(ristopubId, qrcodeId);
  }

  return (
    <DeliveryOrderSummaryComponent
      onBack={toChooseTimeScreen}
      toCheckoutScreen={toCheckoutScreen}
      toMenuScreen={toMenuScreen}
    />
  );
}
