import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { OrderDTO } from "../OrderDTO";
import _ from "lodash";
import { OrderDataMapper } from "../OrderDataMapper";
import { VirtualTableDataMapper } from "../VirtualTableDataMapper";
import { RistopubDataMapper } from "../../Ristopub/RistopubDataMapper";
import { RistopubTakeawaySlotDataMapper } from "../../Ristopub/RistopubTakeawaySlotDataMapper";

const GET_ORDER_BY_ID_QUERY = gql`
  query getOrderByCartIdOrOrderId($input: GetOrderByCartIdOrOrderIdInput!) {
    getOrderByCartIdOrOrderId(input: $input) {
      id
      customerNotes
      cartId
      friendlyOrderId
      statusChangedAt
      dueDate
      type
      deliveryCost
      deliveryDate
      deliveryAddress {
        address
        city
        country
        province
        postalCode
      }
      virtualTable {
        id
        customerPhone {
          phone
          __typename
        }
        customerName
        __typename
      }
      selfPickup
      orderStatus {
        id
        proposedDateChange
        __typename
      }
      __typename
      orderItems {
        id
        qty
        product {
          id
          description
          name
          price
          image {
            src
          }
        }
        total
        variations {
          modifierName
          choices {
            choiceName
            choicePrice
            choiceQty
          }
        }
        __typename
      }
    }
  }
`;

const orderDataMapper = new OrderDataMapper(
  new VirtualTableDataMapper(
    new RistopubDataMapper(new RistopubTakeawaySlotDataMapper()),
  ),
);

export function useOrderByCartId(
  cartId: string,
  options: QueryHookOptions = {},
) {
  const { data, loading, refetch } = useQuery(GET_ORDER_BY_ID_QUERY, {
    ...options,

    variables: {
      input: {
        cartIdOrOrderId: cartId,
      },
    },
  });

  const orderDTO: OrderDTO = _.get(data, "getOrderByCartIdOrOrderId");

  return {
    order: orderDTO ? orderDataMapper.toEntity(orderDTO) : undefined,
    loading,
    refetch,
  };
}
