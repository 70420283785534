import { useCurrentCart } from "../Cart/useCurrentCart";
import React, { useRef } from "react";
import _ from "lodash";
import { CartItemToOrderDetailItemMapper } from "../Cart/classes/CartItemToOrderDetailItemMapper";
import { OrderDetailItemsRenderer } from "../Order/OrderDetailItemsRenderer";

export function OrderSummary() {
  const { getItems, decreaseCartItemQty, increaseCartItemQty } =
    useCurrentCart();
  const items = getItems();

  const { current: ciToDetailItemMapper } = useRef(
    new CartItemToOrderDetailItemMapper(),
  );

  const detailItems = _.map(items, (i) =>
    ciToDetailItemMapper.toOrderDetailItem(i),
  );

  return (
    <div>
      <OrderDetailItemsRenderer
        onDecreaseQtyClicked={(cartItemId) => decreaseCartItemQty(cartItemId)}
        onIncreaseQtyClicked={(cartItemId) => increaseCartItemQty(cartItemId)}
        showModifyQtyButtons={true}
        items={detailItems}
      />
    </div>
  );
}
