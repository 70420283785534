import { useContext } from "react";
import { Last10DaysCtx } from "./Last10DaysOrdersCtx";

export function useLast10DaysOrdersCtx() {
  const { orders, loading, refetch } = useContext(Last10DaysCtx);

  return {
    orders,
    loading,
    refetch,
  };
}
