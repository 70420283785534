import { useHistory } from "react-router-dom";
import { useQueryParams } from "../useQueryParams/useQueryParams";
import { preserveInPlaceQueryParamIfSet } from "../RistopubMenu/preserveInPlaceQueryParamIfSet";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";

export function useToChooseTakeawayTimeScreen() {
  const { ristopubId } = useCurrentRistopubId();
  const history = useHistory();
  const query = useQueryParams();
  function toChooseTakeawayTimeScreen(replace = false) {
    if (replace) {
      history.replace(
        preserveInPlaceQueryParamIfSet(
          `/ristopub/${ristopubId}/takeawaytime`,
          query,
        ),
      );
    } else {
      history.push(
        preserveInPlaceQueryParamIfSet(
          `/ristopub/${ristopubId}/takeawaytime`,
          query,
        ),
      );
    }
  }

  return {
    toChooseTakeawayTimeScreen,
  };
}
