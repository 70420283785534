import React from "react";
import styles from "./AppHomeScreen.module.scss";
import { MainLayout } from "../MainLayout/MainLayout";
import { QrcodeOutlined } from "@ant-design/icons";

export function AppHomeScreen() {
  return (
    <MainLayout hideBackButton={true} withHeader={true}>
      <div className={[styles.wrapper, "p-4"].join(" ")}>
        <h1 className={styles.title}>Inquadra il codice QR</h1>
        <h2 className={styles.subTitle}>
          o clicca sul link che ti ha fornito il locale
        </h2>

        <div className={styles.qrCodeWrapper}>
          <QrcodeOutlined className={styles.qrCodeIcon} />
        </div>

        <p className={styles.expl}>
          Generalmente trovi il codice QR su ogni tavolo per effettuare un
          ordine da tavolo.
          <br />
          Oppure potresti trovarlo esposto all'interno o all'esterno del locale
          per gli ordini da asporto
        </p>
      </div>
    </MainLayout>
  );
}
