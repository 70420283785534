import { useCurrentCart } from "../Cart/useCurrentCart";
import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { useCreateInStructureStripePaymentIntent } from "./useCreateInStructureStripePaymentIntent";
import { useGetSavedStripePaymentMethodId } from "../StripeCheckoutForm/useGetSavedStripePaymentMethodId";
import { useToInStructureOrderDetailScreen } from "./useToInStructureOrderDetailScreen";
import { useCompleteInStructureOrderWithCashPaymentFlow } from "./useCompleteInStructureOrderWithCashPaymentFlow";
import { useGuestUserId } from "../OrderSummaryScreen/useGuestUserId";
import { GenericErrorHandler } from "../GenericErrorHandler/GenericErrorHandler";
import _ from "lodash";
import { CartItem } from "../Cart/classes/CartItem";
import { MainLayout } from "../MainLayout/MainLayout";
import { ListLoading } from "../Loading/ListLoading";
import { Elements } from "@stripe/react-stripe-js";
import { createStripePromise } from "../stripePromise";
import { PaymentMethodSection } from "../StripeCheckoutForm/PaymentMethodSection";
import React, { useMemo, useRef, useState } from "react";
import { useToInStructureSelfPickupSummaryScreen } from "./useToInStructureSelfPickupSummaryScreen";
import { useCurrentTableName } from "./useCurrentTableName";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { InsertPhone } from "../Order/InsertPhone";
import { InsertEmail } from "../Order/InsertEmail";
import { ErrorState } from "../ErrorState";

export function InStructureSelfPickupCheckoutScreen() {
  const {
    getCustomerPhone,
    getCustomerNotes,
    getItems,
    getCartId,
    getCustomerEmail,
    isValidCustomerEmail,
    isValidCustomerPhone,
  } = useCurrentCart();
  const { ristopubId } = useCurrentRistopubId();
  const { ristopub } = useCurrentRistopub();
  const { createInStructureStripePaymentIntentMutation } =
    useCreateInStructureStripePaymentIntent();
  const [errorState, setErrorState] = useState<ErrorState>(new ErrorState());

  const { toInStructureSelfPickupSummaryScreen } =
    useToInStructureSelfPickupSummaryScreen();

  const { tableName } = useCurrentTableName();
  const {
    savedStripePaymentMethodId,
    loadingSavedPaymentMethodId,
    last4digits,
  } = useGetSavedStripePaymentMethodId();
  const { toInStructureOrderDetailScreen } =
    useToInStructureOrderDetailScreen();

  const { completingOrder, completeInStructureOrderWithCashPaymentFlow } =
    useCompleteInStructureOrderWithCashPaymentFlow();
  const stripeAccId = ristopub?.ownerStripeAccountId;

  const stripePromise = useMemo(() => {
    if (!stripeAccId) return null;
    return createStripePromise(stripeAccId);
  }, [stripeAccId]);

  const { guestUserId } = useGuestUserId();
  function displayErrorToGui(e: Error) {
    const errorHandler = new GenericErrorHandler();
    errorHandler.handleError(e);
  }

  const emailRef = useRef<HTMLDivElement>(null);
  const phoneRef = useRef<HTMLDivElement>(null);

  function onEmailValidationFailed() {
    if (!emailRef.current) return console.error("Missing emailRef");

    const newErrorState = errorState.clone();
    newErrorState.enableError("email");
    setErrorState(newErrorState);
    emailRef.current.scrollIntoView();
  }

  function onMobileValidationFailed() {
    if (!phoneRef.current) return console.error("Missing phoneRef");

    const newErrorState = errorState.clone();
    newErrorState.enableError("mobile");
    setErrorState(newErrorState);
    phoneRef.current.scrollIntoView();
  }

  function clearErrorIfNeeded(key: string) {
    if (errorState.hasError(key)) {
      const newErrorState = errorState.clone();
      newErrorState.clearError(key);
      setErrorState(newErrorState);
    }
  }

  async function completePaymentWithCash() {
    await completeInStructureOrderWithCashPaymentFlow(true);
  }

  async function createPaymentIntent() {
    try {
      if (!ristopub) {
        displayErrorToGui(new Error("Missing ristopub"));
        return undefined;
      }
      const res = await createInStructureStripePaymentIntentMutation({
        variables: {
          input: {
            selfPickup: true,
            customerEmail: getCustomerEmail() as string,
            tableName,
            items: _.map(getItems(), (i: CartItem) => {
              return {
                qty: i.getQty(),
                productId: i.getProduct().id,
                modifiers: i.getOptionsGroupedByModifier().map((o) => {
                  return {
                    modifierId: o.modifierId,
                    choices: o.options.map((o) => {
                      return {
                        id: o.choiceId,
                        qty: o.choiceQty,
                      };
                    }),
                  };
                }),
              };
            }),
            ristopubId: ristopub.id,
            customerNotes: getCustomerNotes(),
            cartId: getCartId() as string,
            customerPhone: getCustomerPhone(),
            guestUserId,
          },
        },
      });

      return {
        clientSecret: _.get(
          res.data,
          "createInStructureStripePaymentIntent.clientSecret",
        ) as string,
        virtualTableId: _.get(
          res.data,
          "createInStructureStripePaymentIntent.virtualTable.id",
        ) as string,
      };
    } catch (e) {
      displayErrorToGui(e as Error);
      throw e;
    }
  }

  function goBack() {
    toInStructureSelfPickupSummaryScreen(ristopubId, tableName);
  }

  function redirectPostPayment() {
    const cartId = getCartId();
    if (!cartId || !ristopub) throw new Error("Missing cart id or ristopub");
    toInStructureOrderDetailScreen(
      cartId,
      ristopub.id as string,
      tableName,
      true,
    );
  }

  if (loadingSavedPaymentMethodId)
    return (
      <MainLayout withHeader={true}>
        <ListLoading />
      </MainLayout>
    );

  const buttonDisabled = !isValidCustomerEmail() || !isValidCustomerPhone();

  return (
    <MainLayout
      screenTitle={"Completa il pagamento"}
      goBack={goBack}
      withHeader={true}
    >
      <div className={["bg-white overflow-hidden rounded-t-lg p-4"].join(" ")}>
        {ristopub ? (
          <Elements stripe={stripePromise}>
            <>
              <InsertEmail
                onType={() => {
                  clearErrorIfNeeded("email");
                }}
                hasError={errorState.hasError("email")}
                ref={emailRef}
                className={"mb-6"}
              />

              <InsertPhone
                onType={() => {
                  clearErrorIfNeeded("mobile");
                }}
                ref={phoneRef}
                hasError={errorState.hasError("mobile")}
                label={
                  "Riceverai un messaggio quando il tuo ordine sarà pronto per essere ritirato"
                }
                className={"mb-6"}
              />

              <PaymentMethodSection
                savedStripePaymentMethodLast4={last4digits}
                payButtonDisabled={buttonDisabled}
                payWithCashButtonDisabled={buttonDisabled}
                payWithStripeGaProps={{
                  gaCategory: "instructure_order_flow_self",
                  gaLabel: "instructure_order_flow_self_pay",
                }}
                cashLoading={completingOrder}
                completeOrderWithCash={completePaymentWithCash}
                payWithCashGaProps={{
                  gaCategory: "instructure_order_flow_self",
                  gaLabel: "instructure_order_flow_self_complete_order",
                }}
                savedStripePaymentMethodId={savedStripePaymentMethodId}
                ristopub={ristopub}
                redirectPostPayment={redirectPostPayment}
                emailRequired={true}
                mobileRequired={true}
                onMobileValidationFailed={onMobileValidationFailed}
                onEmailValidationFailed={onEmailValidationFailed}
                getStripePaymentIntentClientSecret={createPaymentIntent}
              />
            </>
          </Elements>
        ) : null}
      </div>
    </MainLayout>
  );
}
