import { useLast10DaysOrdersCtx } from "../../Order/Last10DaysOrdersCtx/useLast10DaysOrdersCtx";
import _ from "lodash";

export function useOpenedOrders() {
  const { orders } = useLast10DaysOrdersCtx();

  const openedOrders = _.filter(orders, (o) => o.isInAnOpenedState());

  return {
    openedOrders,
  };
}
