import { useContext } from "react";
import { CartCtx, CartCtxType } from "./CartCtx";
import { Product } from "../RistopubMenu/Product/Product";
import moment from "moment-timezone";
import { useQueryParams } from "../useQueryParams/useQueryParams";
import validator from "email-validator";
import { CartItem } from "./classes/CartItem";
import _ from "lodash";

export function useCurrentCart() {
  const {
    cart,
    markCurrentCartAsOrdered,
    addToCart,
    removeFromCart,
    setSelectedTime,
    setCustomerNotes,
    setCustomerName,
    setCustomerPhone,
    setCustomerEmail,
    setCartAddress,
    setDeliverySelectedTime,
    setDeliveryCost,
    emptyCart,
    setShippingMethod,
    addToCartByCartItem,
    setSelectedDate,
    refreshingShippingCost,
    decreaseCartItemQty,
    increaseCartItemQty,
    updateCartItem,
    toggleOptionByCartItem,
  } = useContext<CartCtxType>(CartCtx);

  function getQtyOf(product: Product) {
    return cart.getQtyOf(product);
  }

  const query = useQueryParams();

  const isInPlace = query.get("inPlace") === "1";

  function getSelectedDueDate() {
    if (isInPlace) return moment().add(5, "minutes");

    const time = getSelectedTimeObj();
    if (!time) throw new Error("Missing takeaway time");

    return time.getMoment(getSelectedDate());
  }

  function isValidCustomerPhone() {
    const phoneNumber = getCustomerPhone();
    return phoneNumber && phoneNumber.length >= 7;
  }

  function getSelectedDeliveryDate() {
    const time = getDeliverySelectedTimeObj();

    if (!time) throw new Error("Missing delivery time");

    return time.getMoment(getSelectedDate());
  }

  function getTotalItemQty() {
    return cart.getTotalItemQty();
  }

  function getCustomerEmail() {
    return cart.getCustomerEmail();
  }

  function getTotalPrice() {
    return cart.getTotalPrice();
  }

  function getDeliveryAddress() {
    return cart.address;
  }

  function getCartId() {
    return cart.id;
  }

  function getCustomerNotes() {
    return cart.getCustomerNotes();
  }

  function getSelectedTime() {
    const time = cart.getSelectedTime();
    return time ? time.formatTime() : undefined;
  }
  function getDeliverySelectedTime() {
    const time = cart.getDeliverySelectedTime();
    return time ? time.formatTime() : undefined;
  }

  function getSelectedTimeObj() {
    return cart.getSelectedTime();
  }

  function getDeliverySelectedTimeObj() {
    return cart.getDeliverySelectedTime();
  }

  function getItems() {
    return cart.getItems();
  }

  function getDeliveryCost() {
    return cart.getDeliveryCost();
  }

  function getCustomerName() {
    return cart.getCustomerName();
  }

  function getCustomerPhone() {
    return cart.getCustomerPhone();
  }

  function getVirtualTableId() {
    return cart.getVirtualTableId();
  }

  function getShippingMethod() {
    return cart.getShippingMethod();
  }

  function getSelectedDate() {
    return cart.getSelectedDate();
  }

  function getTotalItemPrice() {
    return cart.getTotalItemPrice();
  }

  function isValidCustomerEmail() {
    const email = getCustomerEmail();
    return email && validator.validate(email);
  }

  function isRefreshingShippingCost(): boolean {
    return refreshingShippingCost;
  }

  function itemsInCategoryCount(categoryId: string): number {
    return cart.itemsInCategoryCount(categoryId);
  }

  function hasOption(cartItemId: string, modifierId: string, optionId: string) {
    return cart.hasOption(cartItemId, modifierId, optionId);
  }

  function cartItemHasAtLeastOneOptionOfModifier(
    cartItemId: string,
    modifierId: string,
  ) {
    return cart.cartItemHasAtLeastOneOptionOfModifier(cartItemId, modifierId);
  }

  function getCartItemQty(cartItemId: string) {
    return cart.getCartItemQty(cartItemId);
  }

  function getCartItemById(cartItemId: string): CartItem | undefined {
    return _.cloneDeep(cart.getCartItemById(cartItemId));
  }

  function getCartItemsOfProduct(product: Product): CartItem[] {
    return cart.getItemsOfProduct(product);
  }

  return {
    getCartItemsOfProduct,
    getCartItemById,
    isValidCustomerEmail,
    getTotalItemPrice,
    getSelectedDate,
    getQtyOf,
    addToCart,
    removeFromCart,
    getTotalItemQty,
    getTotalPrice,
    setSelectedTime,
    getSelectedTime,
    getItems,
    setCustomerNotes,
    getCustomerNotes,
    setCustomerName,
    getCustomerName,
    getSelectedTimeObj,
    getCartId,
    setCustomerPhone,
    getCustomerPhone,
    getVirtualTableId,
    markCurrentCartAsOrdered,
    setCustomerEmail,
    getCustomerEmail,
    getSelectedDueDate,
    isInPlace,
    setCartAddress,
    getDeliveryAddress,
    setSelectedDate,
    setDeliverySelectedTime,
    getDeliverySelectedTime,
    getDeliverySelectedTimeObj,
    getSelectedDeliveryDate,
    getDeliveryCost,
    setDeliveryCost,
    emptyCart,
    setShippingMethod,
    getShippingMethod,
    isValidCustomerPhone,
    isRefreshingShippingCost,
    itemsInCategoryCount,
    hasOption,
    decreaseCartItemQty,
    increaseCartItemQty,
    toggleOptionByCartItem,
    cartItemHasAtLeastOneOptionOfModifier,
    addToCartByCartItem,
    getCartItemQty,
    updateCartItem,
  };
}
