import _ from "lodash";
import { Address } from "../Cart/classes/Address/Address";

export class AddressFromComponentsExtractor {
  private extractComponentByType(
    addressComponents: google.maps.GeocoderAddressComponent[],
    typeName: string,
  ): string | undefined {
    const ac = _.find(addressComponents, (ac) => ac.types.includes(typeName));

    return ac?.long_name;
  }

  private extractCity(
    addressComponents: google.maps.GeocoderAddressComponent[],
  ): string | undefined {
    return this.extractComponentByType(
      addressComponents,
      "administrative_area_level_3",
    );
  }

  private extractProvince(
    addressComponents: google.maps.GeocoderAddressComponent[],
  ): string | undefined {
    return this.extractComponentByType(
      addressComponents,
      "administrative_area_level_2",
    );
  }
  private extractPostalCode(
    addressComponents: google.maps.GeocoderAddressComponent[],
  ): string | undefined {
    return this.extractComponentByType(addressComponents, "postal_code");
  }
  private extractStreet(
    addressComponents: google.maps.GeocoderAddressComponent[],
  ): string | undefined {
    return this.extractComponentByType(addressComponents, "route");
  }
  private extractStreetNumber(
    addressComponents: google.maps.GeocoderAddressComponent[],
  ): string | undefined {
    return this.extractComponentByType(addressComponents, "street_number");
  }
  private extractCountry(
    addressComponents: google.maps.GeocoderAddressComponent[],
  ): string | undefined {
    return this.extractComponentByType(addressComponents, "country");
  }

  public toAddress(
    addressComponents: google.maps.GeocoderAddressComponent[] = [],
  ): Address {
    const street = this.extractStreet(addressComponents);
    const streetNumber = this.extractStreetNumber(addressComponents);

    const streetParts: string[] = [];

    if (!!street && street.length > 0) {
      streetParts.push(street);
      if (!!streetNumber && streetNumber.length > 0) {
        streetParts.push(streetNumber);
      }
    }

    return Address.create({
      address: streetParts.join(" "),
      streetNumber: streetNumber,
      postalCode: this.extractPostalCode(addressComponents),
      city: this.extractCity(addressComponents),
      province: this.extractProvince(addressComponents),
      country: this.extractCountry(addressComponents),
    });
  }
}
