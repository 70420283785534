import { MainLayout } from "../MainLayout/MainLayout";
import React from "react";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useToTakeawayMenuScreen } from "../Takeaway/useToTakeawayMenuScreen";
import { useCurrentCart } from "../Cart/useCurrentCart";
import { useToDeliveryInsertAddressScreen } from "../Delivery/useToDeliveryInsertAddressScreen";
import { CartShippingMethod } from "../Cart/classes/CartDTO";
import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { ListLoading } from "../Loading/ListLoading";
import { RistopubTopImage } from "../Ristopub/RistopubTopImage";
import { useToDeliveryMenuScreen } from "../Delivery/useToDeliveryMenuScreen";
import { OutlinedButton } from "../ui/OutlinedButton";
import { ChooseShippingMethodComponent } from "./ChooseShippingMethodComponent";
import { useToDeliveryInsertAddressScreenWithQrCode } from "../Delivery/useToDeliveryInsertAddressScreenWithQrCode";
import { useQrCodeId } from "../QrCode/useQrCodeId";
import { useToTakeawayMenuScreenWithQrCode } from "../Takeaway/useToTakeawayMenuScreenWithQrCode";

export function ChooseShippingMethodScreenWithQrCode() {
  const { ristopubId } = useCurrentRistopubId();
  const { qrcodeId } = useQrCodeId();

  const { toDeliveryInsertAddressScreenWithQrCode } =
    useToDeliveryInsertAddressScreenWithQrCode();

  const { toTakeawayMenuScreenWithQrCode } =
    useToTakeawayMenuScreenWithQrCode();

  function toDelivery() {
    toDeliveryInsertAddressScreenWithQrCode(ristopubId, qrcodeId);
  }

  function toTakeaway() {
    toTakeawayMenuScreenWithQrCode(ristopubId, qrcodeId);
  }

  return (
    <ChooseShippingMethodComponent
      toDelivery={toDelivery}
      toTakeaway={toTakeaway}
    />
  );
}
