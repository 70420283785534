import Url from "url-parse";
import QueryString from "querystring";

export function preserveInPlaceQueryParamIfSet(
  link: string,
  currentQueryParams: URLSearchParams,
) {
  const url = new Url(link, true);

  const query = url.query;
  const inPlace = currentQueryParams.get("inPlace");
  if (inPlace && inPlace === "1") {
    query.inPlace = "1";
    url.set("query", query);
  }

  const queryString = QueryString.stringify(query);

  return `${url.pathname}${
    queryString && queryString.length ? `?${queryString}` : ""
  }`;
}
