import React from "react";
import styles from "./EmptyCart.module.scss";
import { SwippyButton } from "../ui/SwippyButton/SwippyButton";
import { Warning } from "../ui/Warning";

type PropTypes = {
  onClick?: () => void;
};

export function EmptyCart(props: PropTypes) {
  return (
    <div className={"flex flex-col p-4"}>
      <Warning
        title={"Il tuo carrello è vuoto"}
        copy={"Devi scegliere almeno un prodotto per procedere con l'ordine"}
      />
      <div
        className={
          "fixed bottom-0 left-0 right-0 p-4 mt-auto max-w-md ml-auto mr-auto"
        }
      >
        <SwippyButton
          onClick={props.onClick}
          className={[
            "font-bold w-full h-12 flex justify-center items-center",
          ].join(" ")}
          type={"primary"}
        >
          Torna alla lista dei prodotti
        </SwippyButton>
      </div>
    </div>
  );
}
