import { MainLayout } from "../MainLayout/MainLayout";
import { ListLoading } from "../Loading/ListLoading";
import React, { PropsWithChildren } from "react";
import { RistopubCtx } from "./RistopubCtx";
import { useFetchCurrentRistopub } from "./useFetchCurrentRistopub";

type PropTypes = PropsWithChildren<{}>;

export function RistopubContextProvider(props: PropTypes) {
  const { loadingCurrentRistopub, ristopub } = useFetchCurrentRistopub();

  if (loadingCurrentRistopub)
    return (
      <MainLayout>
        <ListLoading />
      </MainLayout>
    );

  return (
    <RistopubCtx.Provider
      value={{
        ristopub,
        loadingCurrentRistopub,
      }}
    >
      {props.children || null}
    </RistopubCtx.Provider>
  );
}
