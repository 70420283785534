import { Product } from "../../RistopubMenu/Product/Product";
import { CartItem } from "./CartItem";
import _ from "lodash";
import { Time } from "../../Ristopub/Time";
import shortid from "shortid";
import moment, { Moment } from "moment-timezone";
import { Address } from "./Address/Address";
import { CartShippingMethod } from "./CartDTO";

type CreateCartItem = {
  items?: Record<string, CartItem>;
  selectedTime?: string;
  deliverySelectedTime?: string;
  customerNotes?: string;
  customerPhone?: string;
  customerName?: string;
  deliveryCost?: number;
  customerEmail?: string;
  id?: string;
  virtualTableId?: string;
  address?: Address;
  shippingMethod?: CartShippingMethod;
  selectedDate?: Moment;
};
export class Cart {
  private constructor(
    public id: string | undefined,
    private items: Record<string, CartItem> = {},
    private selectedTime?: Time,
    private deliverySelectedTime?: Time,
    private customerNotes?: string,
    private customerName?: string,
    private customerPhone?: string,
    private virtualTableId?: string,
    private customerEmail?: string,
    private deliveryCost?: number,
    public address = Address.create({}),
    private shippingMethod?: CartShippingMethod,
    private selectedDate: Moment = moment(),
  ) {
    if (!this.id) this.id = shortid.generate();
  }

  public itemsInCategoryCount(categoryId: string): number {
    const itemsInCat = _.groupBy(this.items, (i) => i.getProduct().categoryId);
    return _.reduce(
      itemsInCat[categoryId],
      (sum, i) => {
        return sum + i.getQty();
      },
      0,
    );
  }

  public setSelectedDate(newDate: Moment) {
    this.selectedDate = newDate;
  }

  public getSelectedDate() {
    return this.selectedDate;
  }

  public getDeliveryCost() {
    return this.deliveryCost || 0;
  }
  public setDeliveryCost(deliveryCost: number) {
    return (this.deliveryCost = deliveryCost);
  }

  public getCustomerEmail() {
    return this.customerEmail;
  }

  public setCustomerEmail(e?: string) {
    this.customerEmail = e;
  }

  public emptyCart() {
    this.setDeliveryCost(0);
    this.items = {};
    this.setSelectedDate(moment());
    this.selectedTime = undefined;
    this.deliverySelectedTime = undefined;
  }

  public getVirtualTableId() {
    return this.virtualTableId;
  }

  public setVirtualTableId(virtualTableId?: string) {
    this.virtualTableId = virtualTableId;
  }

  public setCustomerPhone(customerPhone?: string) {
    this.customerPhone = customerPhone;
  }

  public getCustomerPhone() {
    return this.customerPhone;
  }

  public setCustomerNotes(customerNotes?: string) {
    this.customerNotes = customerNotes;
  }

  public setCustomerName(customerName?: string) {
    this.customerName = customerName;
  }

  public getCustomerName() {
    return this.customerName;
  }

  public getCustomerNotes() {
    return this.customerNotes;
  }

  public setSelectedTime(time: Time) {
    this.selectedTime = time;
  }

  public getSelectedTime() {
    return this.selectedTime;
  }
  public setDeliverySelectedTime(time: Time) {
    this.deliverySelectedTime = time;
  }

  public getDeliverySelectedTime() {
    return this.deliverySelectedTime;
  }

  public getQtyOf(product: Product) {
    return _.reduce(
      this.items,
      (sum, i) => {
        if (i.getProduct().id !== product.id) return sum;
        return sum + i.getQty();
      },
      0,
    );
  }

  public getTotalItemPrice() {
    return _.reduce(
      this.getItems(),
      (sum, i) => {
        return sum + i.getTotalPrice();
      },
      0,
    );
  }

  public getTotalPrice() {
    return this.getTotalItemPrice() + this.getDeliveryCost();
  }

  public getTotalItemQty() {
    return _.reduce(
      this.getItems(),
      (sum, i) => {
        return sum + i.getQty();
      },
      0,
    );
  }

  private deleteItem(product: Product) {
    delete this.items[product.id];
  }

  private static getTimeObj(
    selectedTime?: string,
    selectedDate: Moment = moment(),
  ) {
    if (typeof selectedTime !== "string") return undefined;
    // const now = moment()
    // if (selTimeObj && selTimeObj.getMoment(selectedDate).isBefore(now)) return undefined
    return Time.create({
      time: selectedTime,
    });
  }

  public static create({
    items,
    customerEmail,
    selectedDate,
    shippingMethod,
    deliverySelectedTime,
    address,
    selectedTime,
    deliveryCost,
    customerNotes,
    customerName,
    id,
    customerPhone,
    virtualTableId,
  }: CreateCartItem) {
    const selTimeObj = this.getTimeObj(selectedTime, selectedDate);

    const deliverySelectedTimeObj = this.getTimeObj(
      deliverySelectedTime,
      selectedDate,
    );

    return new Cart(
      id,
      items,
      selTimeObj,
      deliverySelectedTimeObj,
      customerNotes,
      customerName,
      customerPhone,
      virtualTableId,
      customerEmail,
      deliveryCost,
      address,
      shippingMethod,
      selectedDate,
    );
  }

  public setShippingMethod(shippingMethod: CartShippingMethod) {
    this.shippingMethod = shippingMethod;
  }
  public getShippingMethod(): CartShippingMethod | undefined {
    return this.shippingMethod;
  }

  public hasProduct(product: Product) {
    return !!this.getItem(product);
  }

  private getItem(product: Product) {
    return this.items[product.id];
  }

  private addItem(item: CartItem) {
    this.items[item.getProduct().id] = item;
  }

  public addToCart(product: Product, qty: number = 1) {
    if (this.hasProduct(product)) {
      this.getItem(product).increaseQty(qty);
    } else {
      const item = CartItem.create({
        product,
        qty,
      });
      this.addItem(item);
    }
  }

  public getCartItemById(cartItemId: string) {
    return _.find(this.items, (i) => i.id === cartItemId);
  }

  public updateCartItem(cartItem: CartItem) {
    const oldCartItem = this.getCartItemById(cartItem.id);
    if (!oldCartItem) throw new Error(`CartItem ${cartItem.id} is not in cart`);
    this.items[oldCartItem.getKey()] = cartItem;
  }

  private getCartItemByKey(key: string) {
    return _.find(this.items, (i) => i.getKey() === key);
  }

  public addToCartByCartItem(cartItem: CartItem) {
    const oldCartItem = this.getCartItemByKey(cartItem.getKey());
    if (oldCartItem) oldCartItem.increaseQty(cartItem.getQty());
    else this.items[cartItem.getKey()] = _.cloneDeep(cartItem);
  }

  public hasOption(
    cartItemId: string,
    modifierId: string,
    choiceId: string,
  ): boolean {
    const item = this.getCartItemById(cartItemId);
    if (!item) return false;
    return item.hasOption(modifierId, choiceId);
  }

  public increaseCartItemQty(cartItemId: string) {
    const item = this.getCartItemById(cartItemId);
    item?.increaseQty(1);
  }
  public decreaseCartItemQty(cartItemId: string) {
    const item = this.getCartItemById(cartItemId);
    item?.decreaseQty(1);

    if ((item?.getQty() ?? 0) <= 0) this.deleteCartItem(cartItemId);
  }

  public toggleOptionByCartItem(
    cartItemId: string,
    modifierId: string,
    optionId: string,
    modifierName: string,
    choiceName: string,
  ) {
    const item = this.getCartItemById(cartItemId);
    item?.toggleOption(modifierId, optionId, modifierName, choiceName);
  }

  public cartItemHasAtLeastOneOptionOfModifier(
    cartItemId: string,
    modifierId: string,
  ) {
    const item = this.getCartItemById(cartItemId);
    return !!item?.hasAtLeastOneOptionOfModifier(modifierId);
  }

  public removeFromCart(product: Product, qty: number = 1) {
    if (this.hasProduct(product)) {
      this.getItem(product).decreaseQty(qty);
      if (this.getItem(product).getQty() <= 0) this.deleteItem(product);
    }
  }

  public getItems() {
    return this.items;
  }

  isDelivery(): boolean {
    return this.shippingMethod === CartShippingMethod.DELIVERY;
  }

  public getCartItemQty(cartItemId: string): number {
    const ci = this.getCartItemById(cartItemId);
    return ci?.getQty() ?? 0;
  }

  public getItemsOfProduct(product: Product): CartItem[] {
    return _.filter(this.items, (i) => i.getProduct().id === product.id);
  }

  private deleteCartItem(cartItemId: string) {
    this.items = _.reduce(
      this.items,
      (res: Record<string, CartItem>, ci, productId) => {
        if (ci.id !== cartItemId) res[productId] = ci;
        return res;
      },
      {},
    );
  }
}
