import { useHistory } from "react-router-dom";
import { preserveInPlaceQueryParamIfSet } from "../RistopubMenu/preserveInPlaceQueryParamIfSet";
import { useQueryParams } from "../useQueryParams/useQueryParams";

export function useToChooseShippingMethodScreen() {
  const history = useHistory();

  const queryParams = useQueryParams();

  function toChooseShippingMethodScreen(ristopubId: string) {
    history.push(
      preserveInPlaceQueryParamIfSet(
        `/ristopub/${ristopubId}/chooseshippingmethod`,
        queryParams,
      ),
    );
  }

  return {
    toChooseShippingMethodScreen,
  };
}
