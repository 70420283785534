import React from "react";
import { PropsWithChildren } from "react";
import { useAuthCtx } from "./useAuthCtx";
import { useRedirectUrlController } from "./useRedirectUrlController";

export function AuthGuard({
  children,
}: PropsWithChildren<unknown>): JSX.Element {
  const { user } = useAuthCtx();
  const { appendRedirectUrl } = useRedirectUrlController();
  if (!user) {
    window.location.href = appendRedirectUrl("/login");
    return <></>;
  }
  return <>{children}</>;
}
