type Choice = {
  choiceId: string;
  choiceName: string;
  choiceQty: number;
};

export class VariationOption {
  constructor(
    public readonly modifierId: string,
    public readonly modifierName: string,
    public readonly choices: Choice[] = [],
  ) {}

  public renderChoiceString(): string {
    return this.choices.map((c) => c.choiceName).join(" - ");
  }
}
