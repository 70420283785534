import { ButtonProps } from "antd/es/button";
import styles from "./SwippyButton.module.scss";
import { Button } from "antd";
import React, { useState } from "react";
import { safeGtag } from "../../safeGtag/safeGtag";

type PropTypes = ButtonProps & {
  gaCategory?: string;
  gaLabel?: string;
  gaAction?: string;
};

function track(gaAction = "click", gaCategory?: string, gaLabel?: string) {
  if (!gaCategory && !gaLabel) return;
  safeGtag("event", gaAction, {
    event_category: gaCategory,
    event_label: gaLabel,
  });
}

export function SwippyButton(props: PropTypes = {}) {
  const classes = [styles.swippyButton, "swippyButton flex items-center"];
  const [touchHover, setTouchHover] = useState(false);
  if (props.className) classes.push(props.className);

  const { gaLabel, gaCategory, gaAction, ...propsWithoutGa } = props;

  if (touchHover) classes.push("hover");

  if (props.type === "text") classes.push("bg-transparent border-0");

  return (
    <Button
      {...propsWithoutGa}
      onTouchStart={(e) => setTouchHover(true)}
      onTouchEnd={(e) => setTouchHover(false)}
      onMouseEnter={(e) => setTouchHover(true)}
      onMouseLeave={(e) => setTouchHover(false)}
      onClick={
        props.onClick
          ? (e) => {
              track(gaAction, gaCategory, gaLabel);
              if (props.onClick) props.onClick(e);
            }
          : undefined
      }
      className={classes.join(" ")}
    >
      {props.children}
    </Button>
  );
}
