import { SwippyPhoneInput } from "../ui/SwippyPhoneInput";
import React from "react";
import { useCurrentCart } from "../Cart/useCurrentCart";

const PHONE_KEY = "phone";

function savePhoneToLocalStorage(phone: string) {
  window.localStorage.setItem(PHONE_KEY, phone);
}

type Props = {
  className?: string;
  label: string;
  hasError?: boolean;
  onType?: () => void;
};

const InsertPhone = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { getCustomerPhone, setCustomerPhone } = useCurrentCart();

  return (
    <div
      ref={ref}
      className={["flex flex-col", props.className ?? ""].join(" ")}
    >
      <label className={"font-bold"}>
        Il tuo numero di cellulare <span className={"text-red-600"}>*</span>
      </label>
      <span className={"text-sm mb-1"}>{props.label}</span>
      <SwippyPhoneInput
        hasError={props.hasError}
        value={getCustomerPhone()}
        onChange={(v) => {
          if (props.onType) props.onType();
          setCustomerPhone(v);
          savePhoneToLocalStorage(v);
        }}
      />
    </div>
  );
});

export { InsertPhone };
