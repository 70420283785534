import { useHistory, useParams } from "react-router-dom";

export function useToInStructureOrderMenuScreen() {
  const history = useHistory();
  function toInStructureOrderMenuScreen(
    ristopubId: string,
    tableName: string,
    replace?: boolean,
  ) {
    const url = `/ristopub/${ristopubId}/table/${tableName}/menu`;
    if (replace) history.replace(url);
    else history.push(url);
  }

  return {
    toInStructureOrderMenuScreen,
  };
}
