import { Address } from "./Address";
import { AddressDTO } from "./AddressDTO";
import { IAddressDataMapper } from "./IAddressDataMapper";

export class AddressDataMapper implements IAddressDataMapper {
  toEntity(d: AddressDTO): Address {
    return Address.create({
      address: d.address,
      city: d.city,
      country: d.country,
      province: d.province,
      postalCode: d.postalCode,
      streetNumber: d.streetNumber,
    });
  }

  toDto(address: Address): AddressDTO {
    return {
      country: address.country,
      province: address.province,
      postalCode: address.postalCode,
      city: address.city,
      address: address.address,
      streetNumber: address.streetNumber,
    };
  }
}
