import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useCurrentCart } from "../Cart/useCurrentCart";
import { useCurrentTableName } from "./useCurrentTableName";
import { useCompleteInStructureOrderWithCashPaymentFlow } from "./useCompleteInStructureOrderWithCashPaymentFlow";
import { MainLayout } from "../MainLayout/MainLayout";
import { EmptyCart } from "../OrderSummaryScreen/EmptyCart";
import { RistopubTopImage } from "../Ristopub/RistopubTopImage";
import styles from "./InStructureOrderSummaryScreen.module.scss";
import { OrderSummary } from "../OrderSummaryScreen/OrderSummary";
import { AddOrderNotes } from "../OrderSummaryScreen/AddOrderNotes";
import { OrderMetaDetails } from "../Order/OrderDetailScreen/OrderMetaDetails";
import { NextStepButton } from "../OrderButton/NextStepButton/NextStepButton";
import React from "react";
import { useToInStructureSelfPickupMenuScreen } from "./useToInStructureSelfPickupMenuScreen";
import { useToInStructureSelfPickupCheckoutScreen } from "./useToInStructureSelfPickupCheckoutScreen";

export function InStructureSelfPickupSummaryScreen() {
  const { ristopubId } = useCurrentRistopubId();
  const { getTotalItemQty, getTotalPrice, getCustomerNotes } = useCurrentCart();
  const { tableName } = useCurrentTableName();

  const { toInStructureSelfPickupMenuScreen } =
    useToInStructureSelfPickupMenuScreen();

  const { toInStructureSelfPickupCheckoutScreen } =
    useToInStructureSelfPickupCheckoutScreen();

  function goBack() {
    toInStructureSelfPickupMenuScreen(ristopubId, tableName);
  }

  function toCheckout() {
    toInStructureSelfPickupCheckoutScreen(ristopubId, tableName);
  }

  const totalItemQty = getTotalItemQty();
  if (totalItemQty <= 0)
    return (
      <MainLayout withHeader={true} goBack={goBack}>
        <EmptyCart onClick={goBack} />
      </MainLayout>
    );

  return (
    <MainLayout
      screenTitle={"Riepilogo ordine"}
      goBack={goBack}
      withHeader={true}
    >
      <div className={["pb-20"].join(" ")}>
        <div className={"p-1"}>
          <OrderSummary />
        </div>

        <div className={"flex flex-col px-4 pb-20"}>
          <div className={"mb-2 mt-3"}>
            <AddOrderNotes />
          </div>

          <OrderMetaDetails
            customerNotes={getCustomerNotes()}
            total={getTotalPrice()}
            tableName={tableName}
          />
          <div
            className={
              "fixed bottom-0 right-0 left-0 px-4 py-2 flex flex-col ml-auto mr-auto max-w-md"
            }
          >
            <NextStepButton
              onClick={toCheckout}
              gaProps={{
                gaCategory: "instructure_order_flow_self",
                gaLabel: "instructure_order_flow_self_to_checkout",
              }}
              label={"Vai alla cassa"}
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
