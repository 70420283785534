import { useCategoriesWithProducts } from "./useCategoriesWithProducts";
import React, {
  createRef,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ListLoading } from "../Loading/ListLoading";
import { Category } from "./Category/Category";
import _ from "lodash";
import { CategoryItem } from "./CategoryItem";
import { CategoriesFilter } from "./CategoriesFilter";

export function NewRistopubMenu() {
  const { loading, categories } = useCategoriesWithProducts();

  const [categoriesRef, setCategoriesRef] = useState<
    Record<string, RefObject<HTMLDivElement>>
  >({});

  const [selectedCategory, setSelectedCategory] = useState<Category>();

  const catLength = categories.length;

  const maxIntersectionRatioRef = useRef<Record<string, number>>({});

  useEffect(() => {
    const newRef = _.cloneDeep(categoriesRef);
    categories.forEach((c) => {
      newRef[c.id] = newRef[c.id] || createRef<HTMLDivElement>();
    });
    setCategoriesRef(newRef);
  }, [catLength]);

  const catsById = useMemo(
    () => _.keyBy(categories, (c) => c.id),
    [categories],
  );

  if (loading)
    return (
      <div className={"flex-1 flex items-center justify-center"}>
        <ListLoading />
      </div>
    );

  function scrollToCategory(c: Category) {
    const ref = categoriesRef[c.id];
    if (!ref || !ref.current) throw new Error(`Missing category ${c.id} ref`);
    ref.current.scrollIntoView();
    document.body.scrollTop -= 52;
  }

  function updateSelectedCat() {
    const maxCatId = getMaxCategoryIntersection();

    if (!maxCatId) return;
    const newCat = catsById[maxCatId];
    if (newCat) setSelectedCategory(newCat);
  }

  function updateSelectedCategoryIfNeeded(
    c: Category,
    intersectionRatio: number,
  ) {
    if (intersectionRatio === maxIntersectionRatioRef.current[c.id]) return;
    maxIntersectionRatioRef.current[c.id] = intersectionRatio;
    _.debounce(updateSelectedCat, 0)();
  }

  function getMaxCategoryIntersection(): string | undefined {
    return _.reduce(
      maxIntersectionRatioRef.current,
      (maxId: string | undefined, v, categoryId) => {
        const currentVal = maxId
          ? maxIntersectionRatioRef.current[maxId] ?? 0
          : 0;
        const maxIdPos = maxId ? catsById[maxId].pos : Infinity;
        if (v > currentVal) return categoryId;
        else if (v === currentVal) {
          const catPos = catsById[categoryId].pos;
          return catPos < maxIdPos ? categoryId : maxId;
        } else {
          return maxId;
        }
      },
      undefined,
    );
  }
  return (
    <div>
      <div className={"sticky -top-1 bg-white mt-5"}>
        <CategoriesFilter
          selectedCategory={selectedCategory}
          onClick={scrollToCategory}
          categories={categories}
        />
      </div>
      {categories.map((c, index) => {
        return (
          <div
            key={c.id}
            className={[index < categories.length - 1 ? "mb-8" : ""].join(" ")}
          >
            <CategoryItem
              onIntersectionChange={updateSelectedCategoryIfNeeded}
              category={c}
              ref={categoriesRef[c.id]}
            />
          </div>
        );
      })}
    </div>
  );
}
