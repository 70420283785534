import { useLocation } from "react-router-dom";
import { decode, encode } from "js-base64";
const redirectUrlParamName = "r";

export function useRedirectUrlController() {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const encodedRedirect = searchParams.get(redirectUrlParamName);
  function appendRedirectUrl(url: string): string {
    if (!url) throw new Error("No url provided");
    const redirectUrl = new URL(url, window.location.origin);
    redirectUrl.searchParams.set(
      redirectUrlParamName,
      encode(window.location.href),
    );
    return redirectUrl.toString();
  }

  return {
    appendRedirectUrl,
    redirect: encodedRedirect ? decode(encodedRedirect) : undefined,
  };
}
