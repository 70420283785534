import { Variation } from "./Variation";
import ReactDOM from "react-dom";
import React from "react";
import { ProductVariationList } from "./ProductVariationList";
import { CloseIcon } from "../../ui/CloseIcon";
import { Product } from "../Product/Product";

type Props = {
  product: Product;
  variations: Variation[];
  visible?: boolean;
  onCancel?: () => void;
};

export function EditVariationsModal(props: Props) {
  return props.visible
    ? ReactDOM.createPortal(
        <div
          className={
            "bg-black overflow-y-scroll max-w-md ml-auto mr-auto fixed z-40 inset-0 bg-opacity-70 pt-20"
          }
        >
          <div
            className={
              "bg-white min-h-full h-auto rounded-t-md w-full flex flex-col rounded-t-3xl"
            }
          >
            <div className={"flex justify-between p-3"}>
              <h2 className={"mb-0 font-bold text-xl"}>{props.product.name}</h2>
              <CloseIcon
                onClick={props.onCancel}
                className={"cursor-pointer w-4 h-4 text-gray-400"}
              />
            </div>
            <div className={""}>
              <ProductVariationList
                onVariationsListEmptied={props.onCancel}
                variations={props.variations}
              />
            </div>
          </div>
        </div>,
        document.body,
      )
    : null;
}
