import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { CartCtxProvider } from "../Cart/CartCtxProvider";
import { RistopubContextProvider } from "../Ristopub/RistopubContextProvider";

type PropTypes = {
  children?: React.ReactNode;
  path?: string;
} & RouteProps;

export function RouteWithCartAndRistopub(props: PropTypes) {
  return (
    <Route {...props} path={props.path}>
      <RistopubContextProvider>
        <CartCtxProvider>{props.children || null}</CartCtxProvider>
      </RistopubContextProvider>
    </Route>
  );
}
