import { Order } from "../Order";
import { useCurrentRistopub } from "../../Ristopub/useCurrentRistopub";
import React, { useState } from "react";
import moment from "moment-timezone";
import { SwippyButton } from "../../ui/SwippyButton/SwippyButton";
import { preserveInPlaceQueryParamIfSet } from "../../RistopubMenu/preserveInPlaceQueryParamIfSet";
import { useHistory } from "react-router-dom";
import { useChangeOrderStatusMutations } from "./useChangeOrderStatusMutations";
import { useQueryParams } from "../../useQueryParams/useQueryParams";
import { Info } from "../../ui/Info";

type PropTypes = {
  order: Order;
};

export function OrderStatusChangeDateActions(props: PropTypes) {
  const { ristopub } = useCurrentRistopub();
  const [accepting, setAccepting] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const history = useHistory();
  const proposedDateChange = props.order.status.proposedDateChange as string;
  const ristopubName = ristopub ? ristopub.name : undefined;
  const { acceptDateChangeMutation, cancelOrderMutation } =
    useChangeOrderStatusMutations();
  const query = useQueryParams();
  async function accept() {
    if (!ristopub) return;
    setAccepting(true);
    try {
      await acceptDateChangeMutation({
        variables: {
          input: {
            virtualTableId: props.order.virtualTable?.id,
            cartId: props.order.cartId,
            ristopubId: ristopub.id,
          },
        },
      });
    } finally {
      setAccepting(false);
    }
  }

  async function reject() {
    if (!ristopub) return;
    setRejecting(true);
    try {
      await cancelOrderMutation({
        variables: {
          input: {
            virtualTableId: props.order.virtualTable?.id,
            cartId: props.order.cartId,
            ristopubId: ristopub.id,
          },
        },
      });

      history.push(
        preserveInPlaceQueryParamIfSet(`/ristopub/${ristopub.id}/`, query),
      );
    } finally {
      setRejecting(false);
    }
  }

  return (
    <Info
      title={props.order.formatStatus()}
      copy={
        <>
          <p className={"text-sm"}>
            {ristopubName} ti propone il ritiro alle{" "}
            {moment(proposedDateChange).format("HH:mm [del] DD MMM YYYY")}.
            <br />
            Vuoi accettare?
            <br />
            (Se rifiuti dovrai rifare l' ordine cambiando l' orario)
            <br />
            {props.order.isInAppPaidProposedDateChangesState() ? (
              <span>Rifiutando verrai rimborsato in automatico</span>
            ) : null}
          </p>
          <p className={"flex items-center justify-between m-0"}>
            <SwippyButton
              className={"text-blue-600 h-auto"}
              gaCategory={"order_flow"}
              gaLabel={"accept_date_change"}
              onClick={accept}
              loading={accepting}
              type={"link"}
            >
              Accetta
            </SwippyButton>
            <SwippyButton
              className={"text-blue-600 h-auto"}
              gaCategory={"order_flow"}
              gaLabel={"reject_date_change"}
              onClick={reject}
              loading={rejecting}
              type={"link"}
            >
              Rifiuta
            </SwippyButton>
          </p>
        </>
      }
    />
  );

  // <div className={styles.wrapper}>
  //
  // </div>
}
