import { gql, useQuery } from "@apollo/client";
import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { useGuestUserId } from "../OrderSummaryScreen/useGuestUserId";
import _ from "lodash";

const GET_SAVED_STRIPE_PAYMENT_METHOD_ID = gql`
  query getSavedStripePaymentMethodId(
    $input: GetSavedStripePaymentMethodIdInput!
  ) {
    getSavedStripePaymentMethodId(input: $input) {
      paymentMethodId
      last4digits
      __typename
    }
  }
`;

export function useGetSavedStripePaymentMethodId() {
  const { ristopub, loadingCurrentRistopub } = useCurrentRistopub();
  const { guestUserId } = useGuestUserId();

  const { data, loading } = useQuery(GET_SAVED_STRIPE_PAYMENT_METHOD_ID, {
    skip: !ristopub,
    variables: {
      input: {
        guestUserId: guestUserId,
        ristopubId: ristopub?.id,
      },
    },
    fetchPolicy: "network-only",
  });

  return {
    savedStripePaymentMethodId: _.get(
      data,
      "getSavedStripePaymentMethodId.paymentMethodId",
    ) as string | undefined,
    last4digits: _.get(data, "getSavedStripePaymentMethodId.last4digits") as
      | string
      | undefined,
    loadingSavedPaymentMethodId: loading || loadingCurrentRistopub,
  };
}
