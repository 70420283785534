import React from "react";
import { useCurrentCart } from "../Cart/useCurrentCart";
import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { formatPrice } from "../formatPrice/formatPrice";

export function DeliveryFreeShippingAdvice() {
  const { ristopub } = useCurrentRistopub();
  const { getTotalItemPrice } = useCurrentCart();

  if (typeof ristopub?.minimumOrderValueForFreeShipping !== "number")
    return null;

  if (getTotalItemPrice() >= ristopub?.minimumOrderValueForFreeShipping)
    return null;

  return (
    <div
      className={"rounded text-sm text-green-500 text-center whitespace-nowrap"}
    >
      Consegna gratuita per ordini da{" "}
      {formatPrice(ristopub?.minimumOrderValueForFreeShipping)} €
    </div>
  );
}
