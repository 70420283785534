import { useCurrentCart } from "../Cart/useCurrentCart";
import { MainLayout } from "../MainLayout/MainLayout";
import { RistopubTopImage } from "../Ristopub/RistopubTopImage";
import { NextStepButton } from "../OrderButton/NextStepButton/NextStepButton";
import React, { useState } from "react";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useToInStructureSelfPickupSummaryScreen } from "./useToInStructureSelfPickupSummaryScreen";
import { useCurrentTableName } from "./useCurrentTableName";
import { NewRistopubMenu } from "../RistopubMenu/NewRistopubMenu";
import { WarnToast } from "../ui/WarnToast";

export function InStructureSelfPickupMenuScreen() {
  const { ristopubId } = useCurrentRistopubId();

  const { tableName } = useCurrentTableName();
  const [triggerEmptyCartWarn, setTriggerEmptyCartWarn] = useState(false);
  const { toInStructureSelfPickupSummaryScreen } =
    useToInStructureSelfPickupSummaryScreen();

  const { getTotalItemQty } = useCurrentCart();

  function toSummary() {
    toInStructureSelfPickupSummaryScreen(ristopubId, tableName);
  }

  const itemQty = getTotalItemQty();

  function onClickNext() {
    if (itemQty <= 0) {
      setTriggerEmptyCartWarn(!triggerEmptyCartWarn);
    } else {
      toSummary();
    }
  }
  return (
    <MainLayout screenTitle={"Menu"} hideBackButton withHeader={true}>
      <WarnToast
        trigger={triggerEmptyCartWarn}
        copy={
          "Il tuo carrello è vuoto.<br/>Clicca su un prodotto per aggiungerlo al carrello"
        }
        timeoutInSeconds={5}
      />
      <RistopubTopImage offsetTop={45}>
        <div
          className={["flex flex-col rounded-t-3xl flex-1 bg-white pb-20"].join(
            " ",
          )}
        >
          <NewRistopubMenu />
          <div
            className={[
              "fixed bottom-0 right-0 left-0 px-4 py-2 flex flex-col ml-auto mr-auto max-w-md",
            ].join(" ")}
          >
            <NextStepButton
              label={"Vedi il carrello"}
              onClick={onClickNext}
              gaProps={{
                gaLabel: "instructure_self_order_flow_to_order_summary",
                gaCategory: "instructure_order_flow_self",
              }}
            />
          </div>
        </div>
      </RistopubTopImage>
    </MainLayout>
  );
}
