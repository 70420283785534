import React, { useEffect } from "react";
import { Variation } from "./Variation";
import { ItemQtyControlButton } from "./ItemQtyControlButton";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useCurrentCart } from "../../Cart/useCurrentCart";
import { Warning } from "../../ui/Warning";
import { DeleteIcon } from "./DeleteIcon";

type Props = {
  variations: Variation[];
  onVariationsListEmptied?: () => void;
};

export function ProductVariationList(props: Props) {
  const { decreaseCartItemQty, increaseCartItemQty } = useCurrentCart();

  function decreaseQty(v: Variation) {
    decreaseCartItemQty(v.cartItemId as string);
  }
  function increaseQty(v: Variation) {
    increaseCartItemQty(v.cartItemId as string);
  }

  const variationsLength = props.variations.length;

  useEffect(() => {
    if (variationsLength <= 0 && props.onVariationsListEmptied)
      props.onVariationsListEmptied();
  }, [variationsLength]);

  return (
    <div>
      {variationsLength <= 0 ? (
        <div className={"p-3"}>
          <Warning
            title={"Nessuna scelta personalizzata"}
            copy={`Clicca sulla x in alto a destra e configura questo prodotto come preferisci 😊`}
          />
        </div>
      ) : null}
      {props.variations.map((v, vIndex) => {
        return (
          <div
            key={vIndex}
            className={[
              "border-b overflow-hidden border-gray-100",
              vIndex === 0 ? "border-t" : "",
            ].join(" ")}
          >
            <div className={"p-3"}>
              <h3 className={"mb-0 font-bold"}>Variazione {vIndex + 1}</h3>
              <div>
                {v.options.map((o, oIndex) => {
                  return (
                    <div
                      key={oIndex}
                      className={[oIndex < v.options.length - 1 ? "" : ""].join(
                        " ",
                      )}
                    >
                      {/*<h3 className={"mb-0 font-bold"}>{o.modifierName}</h3>*/}
                      <p className={"text-sm flex flex-col text-gray-500 mb-0"}>
                        {o.choices.map((c) => {
                          return (
                            <span key={c.choiceId}>
                              {c.choiceQty > 1 ? (
                                <span className={"mr-1"}>x{c.choiceQty}</span>
                              ) : null}
                              {c.choiceName}
                            </span>
                          );
                        })}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            {v.cartItemId ? (
              <div className={"px-3 pb-3 border-t border-gray-100"}>
                <div
                  className={"flex flex-row justify-between items-center mt-2"}
                >
                  <ItemQtyControlButton
                    onClick={() => {
                      decreaseQty(v);
                    }}
                  >
                    {<MinusOutlined />}
                  </ItemQtyControlButton>
                  <span className={"text-swippy-orange font-bold text-xl"}>
                    x{v.qty}
                  </span>
                  <ItemQtyControlButton
                    onClick={() => {
                      increaseQty(v);
                    }}
                  >
                    <PlusOutlined />
                  </ItemQtyControlButton>
                </div>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}
