import { useHistory } from "react-router-dom";
import { useRedirectUrlController } from "./useRedirectUrlController";

export function useToLogout() {
  const { appendRedirectUrl } = useRedirectUrlController();
  function toLogout() {
    window.location.href = appendRedirectUrl("/logout");
  }

  return {
    toLogout,
  };
}
