import { QrCodeDTO } from "./QrCodeDTO";
import { QrCode } from "./QrCode";

export class QrCodeDataMapper {
  toEntity(d: QrCodeDTO): QrCode {
    return new QrCode(
      d.id,
      d.ristopubId,
      d.name,
      d.enableDeliveryToLocation,
      d.enablePickup,
      d.type,
      d.enableDelivery,
      d.enableTakeaway,
    );
  }
}
