import React, { useRef, useState } from "react";
import {
  CardCvcElement,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  PaymentIntent,
  StripeCardElementOptions,
  StripeError,
} from "@stripe/stripe-js";
import "./CardElements.css";
import { useCurrentCart } from "../Cart/useCurrentCart";
import { Ristopub } from "../Ristopub/Ristopub";
import { message } from "antd";
import { useSaveStripePaymentMethodId } from "./useSaveStripePaymentMethodId";
import { useGuestUserId } from "../OrderSummaryScreen/useGuestUserId";
import { CloseOutlined } from "@ant-design/icons";
import { GaProps } from "../safeGtag/GaProps";
import { ShieldIcon } from "../ui/ShieldIcon";
import { Checkbox } from "../ui/Checkbox";
import { NextStepButton } from "../OrderButton/NextStepButton/NextStepButton";
import { Warning } from "../ui/Warning";
import { ForgetStripePaymentMethodsButton } from "../ForgetStripePaymentMethodsButton/ForgetStripePaymentMethodsButton";

const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
  classes: {
    base: [
      "cardElementBase StripeElement rounded-full border-gray-300 hover:border-swippy-orange focus:border-swippy-orange border-2",
    ].join(" "),
  },
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

type PropTypes = {
  getStripePaymentIntentClientSecret: () => Promise<
    | {
        clientSecret: string;
        virtualTableId: string;
      }
    | undefined
  >;
  ristopub: Ristopub;
  redirectPostPayment: () => void;
  savedStripePaymentMethodId?: string;
  payWithCashGaProps?: GaProps;
  payWithStripeGaProps?: GaProps;
  completeOrderWithCash?: () => void;
  cashLoading?: boolean;
  payButtonDisabled?: boolean;
  payWithCashButtonDisabled?: boolean;
  savedStripePaymentMethodLast4?: string;
  mobileRequired?: boolean;
  emailRequired?: boolean;
  onMobileValidationFailed?: () => void;
  onEmailValidationFailed?: () => void;
};

enum PaymentMethod {
  STRIPE = "STRIPE",
  CASH = "CASH",
}

export function PaymentMethodSection(props: PropTypes) {
  const stripe = useStripe();
  const elements = useElements();

  const { getCustomerEmail, isValidCustomerEmail, isValidCustomerPhone } =
    useCurrentCart();
  const [rememberCardForFutureUse, setRememberCardForFutureUse] =
    useState(true);
  const [paying, setPaying] = useState(false);
  const { markCurrentCartAsOrdered, getCartId } = useCurrentCart();

  const { guestUserId } = useGuestUserId();

  const creditCardWrapperRef = useRef<HTMLDivElement>(null);

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    props.ristopub.enableInAppPayments
      ? PaymentMethod.STRIPE
      : PaymentMethod.CASH,
  );

  function clearErrors() {
    message.destroy();
  }

  function displayErrorToGui(e: Error) {
    message
      .error({
        icon: null,
        content: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ margin: 0, textAlign: "left" }}>
              Ooops qualcosa ha impedito il completamento del pagamento.
              <br />
              Errore: {e.message}
            </p>
            <CloseOutlined
              onClick={clearErrors}
              style={{ color: "white", marginLeft: 4 }}
            />
          </div>
        ),
        duration: 0,
        className: "errorMessageAntd",
      })
      .then(() => {});
  }

  const { saveStripePaymentMethodId } = useSaveStripePaymentMethodId();

  async function saveCard() {
    if (!stripe || !elements) {
      throw new Error("Stripe.js has not yet loaded");
    }

    if (!rememberCardForFutureUse) return;
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      throw new Error("Missing card element");
    }

    const stripePaymentMethod = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (!stripePaymentMethod.paymentMethod)
      throw new Error(stripePaymentMethod.error?.message);

    await saveStripePaymentMethodId(
      getCustomerEmail() as string,
      props.ristopub.id,
      guestUserId,
      stripePaymentMethod.paymentMethod.id,
    );
  }

  async function getClientSecretAndVirtualTableId() {
    const data = await props.getStripePaymentIntentClientSecret();
    if (!data) {
      throw new Error(
        "Data not returned from getStripePaymentIntentClientSecret",
      );
    }

    const { clientSecret, virtualTableId } = data;
    if (!clientSecret) {
      throw new Error("Missing payment intent client secret");
    }

    return {
      clientSecret,
      virtualTableId,
    };
  }

  function handleConfirmPaymentResult(
    result: { paymentIntent?: PaymentIntent; error?: StripeError },
    virtualTableId: string,
  ) {
    if (result.error) {
      console.log(result.error.message);
      throw new Error(result.error.message);
    }

    if ((result.paymentIntent as PaymentIntent).status === "succeeded") {
      const cartId = getCartId();
      if (!cartId) {
        throw new Error("Missing cart id");
      }

      markCurrentCartAsOrdered(virtualTableId);

      props.redirectPostPayment();
    } else {
      setPaying(false);
    }
  }

  async function pay() {
    clearErrors();
    try {
      if (props.savedStripePaymentMethodId) await payWithSavedCard();
      else await payWithoutSavedCard();
    } catch (e) {
      setPaying(false);
      displayErrorToGui(e as Error);
      console.error(e);
    }
  }

  async function payWithSavedCard() {
    if (!props.savedStripePaymentMethodId) return;
    if (!stripe || !elements) {
      throw new Error("Stripe.js has not yet loaded");
    }

    setPaying(true);
    const { clientSecret, virtualTableId } =
      await getClientSecretAndVirtualTableId();

    const cvcElement = elements.getElement(CardCvcElement);
    if (!cvcElement) {
      setPaying(false);
      return displayErrorToGui(new Error("Card Cvc element not found"));
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: props.savedStripePaymentMethodId,
      payment_method_options: {
        card: {
          cvc: cvcElement,
        },
      },
    });

    handleConfirmPaymentResult(result, virtualTableId);
  }

  async function payWithoutSavedCard() {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    if (!isValidCustomerEmail()) return;

    setPaying(true);

    if (rememberCardForFutureUse) await saveCard();

    const { clientSecret, virtualTableId } =
      await getClientSecretAndVirtualTableId();

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setPaying(false);
      return displayErrorToGui(new Error("Card element not found"));
    }
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    handleConfirmPaymentResult(result, virtualTableId);
  }

  const disabledCardInput = paymentMethod !== PaymentMethod.STRIPE;

  function getGaProps(): GaProps | undefined {
    switch (paymentMethod) {
      case PaymentMethod.STRIPE:
        return props.payWithStripeGaProps;
      case PaymentMethod.CASH:
        return props.payWithCashGaProps;
    }
  }

  function getButtonLabel(): string {
    switch (paymentMethod) {
      case PaymentMethod.STRIPE:
        return "Paga";
      case PaymentMethod.CASH:
        return "Conferma ordine";
    }
  }

  function validate(): boolean {
    if (props.mobileRequired && !isValidCustomerPhone()) {
      if (props.onMobileValidationFailed) props.onMobileValidationFailed();

      return false;
    }

    if (props.emailRequired && !isValidCustomerEmail()) {
      if (props.onEmailValidationFailed) props.onEmailValidationFailed();

      return false;
    }

    return true;
  }

  function onClickButton() {
    if (!validate()) return;

    switch (paymentMethod) {
      case PaymentMethod.STRIPE: {
        if (props.ristopub.enableInAppPayments) {
          return pay();
        } else {
          throw new Error(
            `Payment method is stripe but ${props.ristopub.name} do not allow in app payments`,
          );
        }
      }
      case PaymentMethod.CASH: {
        if (props.ristopub.enableCashPayments) {
          return props.completeOrderWithCash
            ? props.completeOrderWithCash()
            : console.error(`Can't complete with cash`);
        } else {
          throw new Error(
            `Payment method is cash but ${props.ristopub.name} do not allow cash payments`,
          );
        }
      }
    }
  }

  function buttonDisabled() {
    switch (paymentMethod) {
      case PaymentMethod.STRIPE:
        return props.payButtonDisabled;
      case PaymentMethod.CASH:
        return props.payWithCashButtonDisabled;
    }
  }

  const buttonLoading = paying || props.cashLoading;

  if (
    !props.ristopub.enableInAppPayments &&
    !props.ristopub.enableCashPayments
  ) {
    return (
      <div>
        <label className={"font-bold"}>
          Metodo di pagamento <span className={"text-red-500"}>*</span>
        </label>
        <Warning
          title={`Nessun metodo di pagamento supportato`}
          copy={`Sembra che ${props.ristopub.name} si sia sbagliato e abbia disabilitato tutti i metodi di pagamento. Puoi contattare il gestore del locale e informarlo del probabile errore`}
        />
      </div>
    );
  }

  return (
    <div className={"flex flex-col pb-20"}>
      <label className={"font-bold"}>
        Metodo di pagamento <span className={"text-red-500"}>*</span>
      </label>

      {props.ristopub.enableInAppPayments ? (
        <div className={["border rounded border-gray-200 p-3 mt-1"].join(" ")}>
          <Checkbox
            onClick={() => setPaymentMethod(PaymentMethod.STRIPE)}
            checked={paymentMethod === PaymentMethod.STRIPE}
            className={""}
            label={`Carta di credito`}
            subLabel={
              props.savedStripePaymentMethodId
                ? `Termina con ${props.savedStripePaymentMethodLast4}`
                : ""
            }
          />

          <div
            ref={creditCardWrapperRef}
            className={[
              "mt-2",
              paymentMethod !== PaymentMethod.STRIPE ? "opacity-50" : "",
            ].join(" ")}
          >
            <div className={"flex items-center mb-2"}>
              <ShieldIcon className={"text-green-600 h-5 w-5 mr-2"} />
              <p className={["text-sm mb-0"].join(" ")}>
                Pagamento sicuro con{" "}
                <a
                  className={"text-swippy-orange"}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  href={"https://stripe.com/it"}
                >
                  Stripe
                </a>
              </p>
            </div>

            {props.savedStripePaymentMethodId ? (
              <CardCvcElement
                onChange={clearErrors}
                options={CARD_ELEMENT_OPTIONS}
              />
            ) : (
              <CardElement
                onChange={clearErrors}
                options={CARD_ELEMENT_OPTIONS}
              />
            )}
            {!props.savedStripePaymentMethodId ? (
              <Checkbox
                checkedClasses={"bg-green-600 text-white"}
                disabled={disabledCardInput}
                className="mt-2"
                labelClassName={"text-sm"}
                checked={rememberCardForFutureUse}
                onChange={(v) => setRememberCardForFutureUse(v)}
                label={"Ricorda questa carta per usi futuri"}
              />
            ) : (
              <ForgetStripePaymentMethodsButton />
            )}
          </div>
        </div>
      ) : null}

      {props.ristopub.enableCashPayments ? (
        <div className={"border rounded border-gray-200 p-3 mt-2"}>
          <Checkbox
            onClick={() => setPaymentMethod(PaymentMethod.CASH)}
            checked={paymentMethod === PaymentMethod.CASH}
            className={""}
            label={"In contanti"}
          />
        </div>
      ) : null}

      <div
        className={
          "fixed max-w-md ml-auto mr-auto px-4 py-2 bottom-0 right-0 left-0"
        }
      >
        <NextStepButton
          // disabled={buttonDisabled()}
          onClick={onClickButton}
          gaProps={getGaProps()}
          label={getButtonLabel()}
          loading={buttonLoading}
        />
      </div>
    </div>
  );
}
