import { IApolloCredentials } from "./IApolloCredentials";
import { ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getAuth } from "firebase/auth";

export class GuestAuthCredentials implements IApolloCredentials {
  getHash(): string {
    return "guest-auth-credentials";
  }

  injectAuth(link: ApolloLink): ApolloLink {
    return setContext(async (_, { headers }) => {
      const token = await getAuth().currentUser?.getIdToken(true);
      return {
        headers: {
          ...(headers ?? {}),
          ...(token
            ? {
                "x-guest-auth": token,
              }
            : {}),
        },
      };
    }).concat(link);
  }
}
