import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";
import { useCurrentRistopub } from "../Ristopub/useCurrentRistopub";
import { MainLayout } from "../MainLayout/MainLayout";
import { ListLoading } from "../Loading/ListLoading";
import { RistopubTopImage } from "../Ristopub/RistopubTopImage";
import React from "react";
import { useToInStructureOrderMenuScreen } from "./useToInStructureOrderMenuScreen";
import { useCurrentTableName } from "./useCurrentTableName";
import { useToInStructureSelfPickupMenuScreen } from "./useToInStructureSelfPickupMenuScreen";
import { OutlinedButton } from "../ui/OutlinedButton";

export function ChooseInStructurePickupMethodScreen() {
  const { ristopubId } = useCurrentRistopubId();
  const { tableName } = useCurrentTableName();
  const { ristopub, loadingCurrentRistopub } = useCurrentRistopub();

  const { toInStructureOrderMenuScreen } = useToInStructureOrderMenuScreen();

  const { toInStructureSelfPickupMenuScreen } =
    useToInStructureSelfPickupMenuScreen();

  function toDeliveryToLocation() {
    toInStructureOrderMenuScreen(ristopubId, tableName);
  }

  function toSelfPickup() {
    toInStructureSelfPickupMenuScreen(ristopubId, tableName);
  }

  if (loadingCurrentRistopub)
    return (
      <MainLayout hideBackButton withHeader={true}>
        <ListLoading />
      </MainLayout>
    );

  if (!ristopub?.enableDelivery && !ristopub?.enableTakeaway) return null;

  return (
    <MainLayout hideBackButton withHeader={true}>
      <RistopubTopImage>
        <div
          style={{
            backgroundColor: "white",
          }}
          className={"p-4 rounded-t-3xl flex flex-1 flex-col"}
        >
          <h3 className={"text-gray-900 text-center font-bold mt-4 mb-8"}>
            Come vuoi ricevere l'ordine?
          </h3>
          <div className={"px-6 flex flex-col"}>
            <OutlinedButton onClick={toDeliveryToLocation}>
              <span>Lo aspetto qui</span>
            </OutlinedButton>
            <OutlinedButton className={"mt-6"} onClick={toSelfPickup}>
              <span>Ritiro io al banco</span>
            </OutlinedButton>
          </div>
        </div>
      </RistopubTopImage>

      {/*<div className={styles.wrapper}>*/}

      {/*    */}

      {/*</div>*/}
    </MainLayout>
  );
}
