import "url-search-params-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-phone-number-input/style.css";
import App from "./App";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import "moment/locale/it";
import it from "date-fns/locale/it";
import { registerLocale } from "react-datepicker";
import { initFirebase } from "./firebase";
initFirebase();
registerLocale("it", it);
moment.locale("it");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
