import { gql, useQuery } from "@apollo/client";
import { Moment } from "moment-timezone";
import { AvailabilityType } from "./AvailabilityType";
import { useCurrentRistopubId } from "../../useCurrentRistopubId/useCurrentRistopubId";
import { RistopubAvailabilityDTO } from "../RistopubDTO";
import _ from "lodash";
import { useRef } from "react";
import { AvailabilityDataMapper } from "./AvailabilityDataMapper";

const GET_AVAILABILITY = gql`
  query getRistopubAvailability($input: GetRistopubAvailabilityInput!) {
    getRistopubAvailability(input: $input) {
      availability {
        start {
          time
        }

        end {
          time
        }
      }
    }
  }
`;

export function useRistopubAvailability(type: AvailabilityType, date?: Moment) {
  const { current: dataMapper } = useRef(new AvailabilityDataMapper());

  const { ristopubId } = useCurrentRistopubId();

  const { data, loading } = useQuery<{
    getRistopubAvailability: {
      availability: RistopubAvailabilityDTO[];
    };
  }>(GET_AVAILABILITY, {
    skip: !date,
    variables: {
      input: {
        date: date?.hours(12).toDate(),
        ristopubId,
        type: type,
      },
    },
  });

  return {
    availabilities: _.sortBy(
      _.map(data?.getRistopubAvailability.availability, (av) => {
        return dataMapper.toEntity(av, date as Moment);
      }),
      (av) => {
        return av.start.getMoment(date).valueOf();
      },
    ),
    loading,
  };
}
