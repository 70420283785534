import { useHistory } from "react-router-dom";

export function useToDeliveryInsertAddressScreen() {
  const history = useHistory();
  function toDeliveryInsertAddressScreen(ristopubId: string, replace = false) {
    if (replace) {
      history.replace(`/ristopub/${ristopubId}/delivery/insertaddress`);
    } else {
      history.push(`/ristopub/${ristopubId}/delivery/insertaddress`);
    }
  }

  return {
    toDeliveryInsertAddressScreen,
  };
}
