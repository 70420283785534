import React, { forwardRef, MutableRefObject, useEffect, useRef } from "react";
import { Category } from "./Category/Category";
import { NewProductsList } from "./ProductsList/NewProductsList";

export const CategoryItem = forwardRef<
  HTMLDivElement,
  {
    category: Category;
    onIntersectionChange: (c: Category, intersectionRation: number) => void;
  }
>((props, ref) => {
  const myRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const threshold: number[] = [];
    for (let i = 0; i <= 1; i = i + 0.01) {
      threshold.push(i);
    }

    const observer = new IntersectionObserver(
      (v) => {
        const firstEntry = v[0];
        if (!firstEntry) return;
        if (!firstEntry.isIntersecting)
          props.onIntersectionChange(props.category, 0);
        else
          props.onIntersectionChange(
            props.category,
            firstEntry.intersectionRatio,
          );
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: threshold,
      },
    );

    observer.observe(myRef.current as HTMLDivElement);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return (
    <div
      ref={(node) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        myRef.current = node;
        if (typeof ref === "function") {
          ref(node);
        } else if (ref) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (ref as MutableRefObject<HTMLDivElement>).current = node;
        }
      }}
      key={props.category.id}
    >
      <div className={"p-2"}>
        <h3 className={"mb-2 font-bold text-xl"}>{props.category.name}</h3>
        {props.category.description ? (
          <p className={"text-sm mb-2"}>{props.category.description}</p>
        ) : null}
      </div>
      <div className={""}>
        <NewProductsList products={props.category.getProducts()} />
        {/*<ProductsList products={props.category.getProducts()}/>*/}
      </div>
    </div>
  );
});
