import { ICategoryDataMapper } from "./ICategoryDataMapper";
import { Category } from "./Category";
import { CategoryDTO } from "./CategoryDTO";
import { IProductDataMapper } from "../Product/IProductDataMapper";
import _ from "lodash";

export class CategoryDataMapper implements ICategoryDataMapper {
  constructor(private productDataMapper: IProductDataMapper) {}

  toDto(e: Category): CategoryDTO {
    return {
      pos: e.pos,
      id: e.id,
      name: e.name,
      description: e.description,
      products: _.map(e.getProducts(), (p) => this.productDataMapper.toDto(p)),
    };
  }

  toEntity(d: CategoryDTO): Category {
    return Category.create({
      id: d.id,
      pos: d.pos || 0,
      name: d.name,
      description: d.description,
      products: _.sortBy(
        _.map(d.products, (p) => this.productDataMapper.toEntity(p)),
        (p) => p.pos,
      ),
    });
  }
}
