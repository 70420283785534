import { useCurrentCart } from "../Cart/useCurrentCart";
import React from "react";
import { MainLayout } from "../MainLayout/MainLayout";
import { ChooseAvailability } from "../ChooseAvailability";
import { AvailabilityType } from "../Ristopub/Availability/AvailabilityType";
import { NextStepButton } from "../OrderButton/NextStepButton/NextStepButton";

type Props = {
  onBack: () => void;
  toSummary: () => void;
};

export function TakeawayChooseTimeComponent(props: Props) {
  const { setSelectedTime, getSelectedTime } = useCurrentCart();

  return (
    <MainLayout
      goBack={props.onBack}
      screenTitle={"Quando vuoi ritirare l'ordine?"}
      withHeader={true}
    >
      <div className={"flex flex-col flex-1 p-4 rounded-t-lg bg-white"}>
        <div className={"mb-2 h-96 flex flex-col"}>
          {/*<label className={"mb-2 text-base font-bold"}>Quando vuoi ritirare l'ordine?</label>*/}
          <ChooseAvailability
            type={AvailabilityType.TAKEAWAY}
            selectedTime={getSelectedTime()}
            onTimeSelected={(time) => setSelectedTime(time)}
            dateLabel={"Giorno del ritiro"}
            timeLabel={"Orario del ritiro"}
          />
        </div>
        <div
          className={[
            "mt-2 fixed bottom-0 left-0 right-0 mr-auto ml-auto max-w-md p-4",
          ].join(" ")}
        >
          <NextStepButton
            label={"Vedi il carrello"}
            disabled={!getSelectedTime()}
            onClick={props.toSummary}
            gaProps={{
              gaLabel:
                "takeaway_order_flow_choose_availability_to_order_summary",
              gaCategory: "takeaway_order_flow",
            }}
          />
        </div>
      </div>
    </MainLayout>
  );
}
