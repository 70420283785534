import React from "react";
import { formatPrice } from "../../formatPrice/formatPrice";
import { Moment } from "moment-timezone";
import { Address } from "../../Cart/classes/Address/Address";
import { InformationList, ListItem } from "../../ui/InformationList";

type PropTypes = {
  customerPhone?: string;
  customerNotes?: string;
  customerName?: string;
  customerEmail?: string;
  tableName?: string;
  total?: number;
  dueDate?: Moment;
  deliveryDate?: Moment;
  address?: Address;
  deliveryCost?: number;
  friendlyOrderId?: number;
  refreshingShippingCost?: boolean;
};

export function OrderMetaDetails(props: PropTypes) {
  const items: ListItem[] = [];

  if (props.tableName) {
    items.push({
      title: "Tavolo",
      value: props.tableName,
    });
  }
  if (props.friendlyOrderId) {
    items.push({
      title: "Numero ordine",
      value: props.friendlyOrderId.toString(),
    });
  }
  if (props.address) {
    items.push({
      title: "Indirizzo",
      value: props.address.toString(),
    });
  }
  if (props.customerName) {
    items.push({
      title: "Nome",
      value: props.customerName,
    });
  }
  if (props.customerPhone) {
    items.push({
      title: "Telefono",
      value: props.customerPhone,
    });
  }
  if (props.customerEmail) {
    items.push({
      title: "Email",
      value: props.customerEmail,
    });
  }
  if (props.customerNotes) {
    items.push({
      title: "Note",
      value: props.customerNotes,
    });
  }
  if (props.dueDate) {
    items.push({
      title: "Data ritiro",
      value: props.dueDate.format("HH:mm [del] DD MMM YYYY"),
    });
  }
  if (props.deliveryDate) {
    items.push({
      title: "Data consegna",
      value: props.deliveryDate.format("HH:mm [del] DD MMM YYYY"),
    });
  }
  if (props.deliveryCost !== undefined) {
    items.push({
      title: "Costi di consegna",
      value:
        props.deliveryCost <= 0
          ? "Gratis"
          : `${formatPrice(props.deliveryCost)} €`,
    });
  }
  if (props.total !== undefined) {
    items.push({
      title: "Totale",
      value: `${formatPrice(props.total)} €`,
    });
  }

  return <InformationList listItems={items} />;
}
