import { IDataMapper } from "../../interfaces/IDataMapper";
import { CartDTO } from "./CartDTO";
import { Cart } from "./Cart";
import { CartItemDataMapper } from "./CartItemDataMapper";
import _ from "lodash";
import { CartItem } from "./CartItem";
import { CartItemDTO } from "./CartItemDTO";
import { AddressDataMapper } from "./Address/AddressDataMapper";
import moment from "moment-timezone";

export class CartDataMapper implements IDataMapper<CartDTO, Cart> {
  constructor(
    private cartItemDataMapper: CartItemDataMapper,
    private addressDataMapper: AddressDataMapper = new AddressDataMapper(),
  ) {}

  toDto(e: Cart): CartDTO {
    const time = e.getSelectedTime();
    const deliverySelectedTime = e.getDeliverySelectedTime();

    return {
      shippingMethod: e.getShippingMethod(),
      deliveryCost: e.getDeliveryCost(),
      deliverySelectedTime: deliverySelectedTime
        ? deliverySelectedTime.toJSON()
        : undefined,
      customerEmail: e.getCustomerEmail(),
      customerNotes: e.getCustomerNotes(),
      selectedTime: time ? time.toJSON() : undefined,
      items: _.reduce(
        e.getItems(),
        (res: Record<string, CartItemDTO>, i, key) => {
          res[key] = this.cartItemDataMapper.toDto(i);
          return res;
        },
        {},
      ),
      customerName: e.getCustomerName(),
      id: e.id,
      customerPhone: e.getCustomerPhone(),
      virtualTableId: e.getVirtualTableId(),
      address: this.addressDataMapper.toDto(e.address),
      selectedDate: e.getSelectedDate().toISOString(),
    };
  }

  toEntity(d: CartDTO): Cart {
    return Cart.create({
      shippingMethod: d.shippingMethod,
      deliveryCost: d.deliveryCost,
      deliverySelectedTime: d.deliverySelectedTime,
      selectedTime: d.selectedTime,
      customerNotes: d.customerNotes,
      items: _.reduce(
        d.items,
        (res: Record<string, CartItem>, i, key) => {
          res[key] = this.cartItemDataMapper.toEntity(i);
          return res;
        },
        {},
      ),
      customerName: d.customerName,
      id: d.id,
      customerPhone: d.customerPhone,
      virtualTableId: d.virtualTableId,
      customerEmail: d.customerEmail,
      address: d.address
        ? this.addressDataMapper.toEntity(d.address)
        : undefined,
      selectedDate:
        d.selectedDate && moment(d.selectedDate).isAfter(moment())
          ? moment(d.selectedDate)
          : undefined,
    });
  }
}
