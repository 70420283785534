import React from "react";
import "antd/dist/antd.css";
import "./App.scss";
import { ApolloProvider } from "@apollo/client";
import { RootComponent } from "./RootComponent/RootComponent";
import { getApolloClientSingleton } from "./apolloClient/getApolloClientSingleton";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { GuestAuthCredentials } from "./apolloClient/credentials/GuestAuthCredentials";

Sentry.init({
  dsn: "https://fb579aa4949d4fc8963470550c0b6ac3@o482948.ingest.sentry.io/5533918",
  integrations: [new Integrations.BrowserTracing()],
  release: process.env.REACT_APP_SENTRY_RELEASE,
  enabled: process.env.REACT_APP_SENTRY_RELEASE !== "test-release",
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

function App() {
  return (
    <ApolloProvider
      client={getApolloClientSingleton(new GuestAuthCredentials())}
    >
      <RootComponent />
    </ApolloProvider>
  );
}

export default App;
