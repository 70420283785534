import { OrderDetailItemVariation } from "./OrderDetailItemVariation";
import { OrderDetailItemVariationChoice } from "./OrderDetailItemVariationChoice";
import _ from "lodash";

export class OrderDetailItem {
  constructor(
    public readonly itemName: string,
    public readonly itemQty: number,
    public readonly itemPrice: number,
    public readonly variations: OrderDetailItemVariation[] = [],
    public readonly cartItemId?: string,
  ) {}

  public getChoicesList(): OrderDetailItemVariationChoice[] {
    return _.reduce(
      this.variations,
      (res: OrderDetailItemVariationChoice[], v) => {
        return res.concat(v.choices);
      },
      [],
    );
  }

  public getTotal(): number {
    return this.itemQty * this.itemPrice;
  }
}
