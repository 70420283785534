import { gql, useQuery } from "@apollo/client";
import { CategoryDataMapper } from "./Category/CategoryDataMapper";
import { ProductDataMapper } from "./Product/ProductDataMapper";
import { useMemo } from "react";
import { CategoryDTO } from "./Category/CategoryDTO";
import _ from "lodash";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";

const GET_PRODUCTS_QUERY = gql`
  query getCategoryWithProductsByRistopub(
    $input: GetCategoryWithProductsByRistopubInput!
  ) {
    getCategoryWithProductsByRistopub(input: $input) {
      id
      name
      description
      pos
      products {
        id
        subTitle
        modifiers {
          name
          id
          type
          ristopubId
          description
          choices {
            id
            name
            price
            maxQty
          }
          required
          maxSelectableChoices
        }
        pos
        name
        categoryId
        price
        description
        image {
          src
        }
        __typename
      }
      __typename
    }
  }
`;

export function useCategoriesWithProducts() {
  const { ristopubId } = useCurrentRistopubId();
  if (!ristopubId)
    throw new Error(`Can't use useCategoriesWithProducts without ristopubId`);

  const { data, loading } = useQuery<
    {
      getCategoryWithProductsByRistopub: CategoryDTO[];
    },
    {
      input: {
        ristopubId: string;
      };
    }
  >(GET_PRODUCTS_QUERY, {
    variables: {
      input: {
        ristopubId,
      },
    },
  });

  const dataMapper = useMemo(
    () => new CategoryDataMapper(new ProductDataMapper()),
    [],
  );

  const categories = useMemo(() => {
    return _.sortBy(
      _.map(data?.getCategoryWithProductsByRistopub, (c) =>
        dataMapper.toEntity(c),
      ),
      (c) => c.pos,
    );
  }, [data]);

  return {
    categories: categories,
    loading,
  };
}
