import { OrderStatusDTO } from "./OrderDTO";
import { VirtualTable } from "./VirtualTable";
import { OrderItem } from "./OrderItem/OrderItem";
import _ from "lodash";
import { Moment } from "moment-timezone";
import { Address } from "../Cart/classes/Address/Address";
import { OrderType } from "./OrderType";

type CreateOrderInput = {
  id: string;
  cartId?: string;
  status: OrderStatusDTO;
  virtualTable?: VirtualTable;
  orderItems?: OrderItem[];
  customerNotes?: string;
  updatedAt?: Moment;
  statusChangedAt?: Moment;
  dueDate?: Moment;
  deliveryDate?: Moment;
  deliveryAddress?: Address;
  deliveryCost?: number;
  type?: OrderType;
  friendlyOrderId?: number;
  selfPickup?: boolean;
};

export class Order {
  private constructor(
    public id: string,
    public status: OrderStatusDTO,
    public virtualTable?: VirtualTable,
    public cartId?: string,
    public orderItems: OrderItem[] = [],
    public customerNotes?: string,
    public updatedAt?: Moment,
    public statusChangedAt?: Moment,
    public dueDate?: Moment,
    public deliveryDate?: Moment,
    public deliveryAddress?: Address,
    private deliveryCost?: number,
    public type?: OrderType,
    public readonly friendlyOrderId?: number,
    public readonly selfPickup = false,
  ) {}

  public static create({
    id,
    type,
    status,
    deliveryCost,
    virtualTable,
    cartId,
    orderItems,
    customerNotes,
    updatedAt,
    statusChangedAt,
    dueDate,
    deliveryDate,
    deliveryAddress,
    friendlyOrderId,
    selfPickup,
  }: CreateOrderInput) {
    return new Order(
      id,
      status,
      virtualTable,
      cartId,
      orderItems,
      customerNotes,
      updatedAt,
      statusChangedAt,
      dueDate,
      deliveryDate,
      deliveryAddress,
      deliveryCost,
      type,
      friendlyOrderId,
      selfPickup,
    );
  }

  public isDelivery() {
    return this.type === OrderType.DELIVERY;
  }

  public isTakeaway() {
    return this.type === OrderType.TAKEAWAY;
  }
  public isInStructure() {
    return this.type === OrderType.INSTRUCTURE;
  }

  public isInAnOpenedState() {
    return (
      this.isPending() ||
      this.isAccepted() ||
      this.isInProposedDateChangeState()
    );
  }

  public getDeliveryCost() {
    return this.deliveryCost || 0;
  }

  public getTotal() {
    return (
      _.reduce(
        this.orderItems,
        (sum, i) => {
          return sum + i.getTotal();
        },
        0,
      ) + this.getDeliveryCost()
    );
  }

  public isPending() {
    return this.status.id === "PENDING";
  }

  public isPaid() {
    return this.status.id === "PAID" || this.status.id === "PAID_IN_APP";
  }

  public isAccepted() {
    return this.status.id === "ACCEPTED";
  }

  public isRejected() {
    return this.status.id === "REJECTED";
  }

  public isInProposedDateChangeState() {
    return (
      this.status.id === "OWNER_DATE_CHANGE_PROPOSED" ||
      this.status.id === "PAID_IN_APP_OWNER_DATE_CHANGE_PROPOSED"
    );
  }

  public isInAppPaidProposedDateChangesState() {
    return this.status.id === "PAID_IN_APP_OWNER_DATE_CHANGE_PROPOSED";
  }

  public isCancelled() {
    return (
      this.status.id === "CANCELLED" ||
      this.status.id === "PAID_IN_APP_CANCELLED"
    );
  }

  public isInPendingPaymentConfirmationState() {
    return this.status.id === "PENDING_PAYMENT_CONFIRMATION";
  }

  public isRefunded() {
    return this.status.id === "REFUNDED";
  }

  public isDelivered() {
    return (
      this.status.id === "DELIVERED" ||
      this.status.id === "PAID_IN_APP_DELIVERED"
    );
  }

  public formatStatus() {
    if (this.isPending()) {
      return "In attesa di conferma";
    } else if (this.isAccepted()) {
      return "Ordine accettato";
    } else if (this.isRejected()) {
      return "Ordine rifiutato";
    } else if (this.isCancelled()) {
      return "Ordine annullato";
    } else if (this.isInProposedDateChangeState()) {
      return "Cambio data proposto";
    } else if (this.isPaid()) {
      return "Ordine pagato";
    } else if (this.isInPendingPaymentConfirmationState()) {
      return "Elaborazione del pagamento";
    } else if (this.isRefunded()) {
      return "Ordine rimborsato";
    } else if (this.isDelivered()) {
      return this.selfPickup ? "Pronto per il ritiro" : "Ordine consegnato";
    } else {
      return "";
    }
  }

  public statusColor() {
    if (this.isRejected() || this.isCancelled()) {
      return "#D51C1C";
    } else if (
      this.isInProposedDateChangeState() ||
      this.isPending() ||
      this.isInPendingPaymentConfirmationState()
    ) {
      return "#DE862F";
    } else if (this.isPaid() || this.isRefunded() || this.isAccepted()) {
      return "#ff9900";
    }
  }
}
