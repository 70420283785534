import { Modal } from "antd";
import React from "react";
import { IErrorHandler } from "../interfaces/IErrorHandler";
import "./GenericErrorModal.scss";
const { error } = Modal;
export class GenericErrorHandler implements IErrorHandler {
  handleError(e: Error): void {
    error({
      okButtonProps: {
        className: "swippyErrorOkBtn",
      },
      title: "Ops, qualcosa è andato storto.",
      content: (
        <div>
          <p>Puoi segnalare questo errore agli amministratori.</p>
          <p>{e.message}</p>
        </div>
      ),
    });
  }
}
