import { useHistory, useParams } from "react-router-dom";

export function useToInStructureOrderSummaryScreen() {
  const { tableName, ristopubId } = useParams<{
    ristopubId: string;
    tableName: string;
  }>();
  const history = useHistory();
  function toInStructureOrderSummaryScreen(replace?: boolean) {
    const url = `/ristopub/${ristopubId}/table/${tableName}/ordersummary`;
    if (replace) history.replace(url);
    else history.push(url);
  }

  return {
    toInStructureOrderSummaryScreen,
  };
}
