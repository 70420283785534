import React, { useState } from "react";
import styles from "./InStructureOrderMenuScreen.module.scss";
import { MainLayout } from "../MainLayout/MainLayout";
import { NextStepButton } from "../OrderButton/NextStepButton/NextStepButton";
import { useToInStructureOrderSummaryScreen } from "./useToInStructureOrderSummaryScreen";
import { useCurrentCart } from "../Cart/useCurrentCart";
import { RistopubTopImage } from "../Ristopub/RistopubTopImage";
import { NewRistopubMenu } from "../RistopubMenu/NewRistopubMenu";
import { WarnToast } from "../ui/WarnToast";

export function InStructureMenuScreen() {
  const { getTotalItemQty } = useCurrentCart();

  const [triggerEmptyCartWarn, setTriggerEmptyCartWarn] = useState(false);
  const { toInStructureOrderSummaryScreen } =
    useToInStructureOrderSummaryScreen();

  const itemQty = getTotalItemQty();

  function onClickNext() {
    if (itemQty <= 0) {
      setTriggerEmptyCartWarn(!triggerEmptyCartWarn);
    } else {
      toInStructureOrderSummaryScreen();
    }
  }

  return (
    <MainLayout
      headerClassName={styles.headerNoBorder}
      hideBackButton
      screenTitle={"Menu"}
      withHeader={true}
    >
      <WarnToast
        trigger={triggerEmptyCartWarn}
        copy={
          "Il tuo carrello è vuoto.<br/>Clicca su un prodotto per aggiungerlo al carrello"
        }
        timeoutInSeconds={5}
      />
      <RistopubTopImage offsetTop={45}>
        <div
          className={["flex flex-col pb-20 flex-1 rounded-t-3xl bg-white"].join(
            " ",
          )}
        >
          <NewRistopubMenu />
          <div
            className={[
              "fixed bottom-0 right-0 left-0 px-4 py-2 flex flex-col ml-auto mr-auto max-w-md",
            ].join(" ")}
          >
            <NextStepButton
              label={"Vedi il carrello"}
              gaProps={{
                gaLabel: "instructure_order_flow_to_order_summary",
                gaCategory: "instructure_order_flow",
              }}
              onClick={onClickNext}
            />
          </div>
        </div>
      </RistopubTopImage>
    </MainLayout>
  );
}
