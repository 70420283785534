import { SwippyInput } from "../ui/SwippyInput/SwippyInput";
import React from "react";
import { useCurrentCart } from "../Cart/useCurrentCart";

const EMAIL_KEY = "email";

function saveEmailToLocalStorage(email: string) {
  window.localStorage.setItem(EMAIL_KEY, email);
}

type Props = {
  className?: string;
  hasError?: boolean;
  onType?: () => void;
};

const InsertEmail = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { getCustomerEmail, setCustomerEmail } = useCurrentCart();

  return (
    <div
      ref={ref}
      className={["flex flex-col", props.className ?? ""].join(" ")}
    >
      <label className={"font-bold"}>
        La tua email <span className={"text-red-600"}>*</span>
      </label>
      <span className={"text-sm mb-1"}>
        Qui invieremo la ricevuta di pagamento
      </span>
      <SwippyInput
        hasError={props.hasError}
        type={"email"}
        value={getCustomerEmail()}
        onChange={(e) => {
          if (props.onType) props.onType();
          setCustomerEmail(e.target.value);
          saveEmailToLocalStorage(e.target.value);
        }}
      />
    </div>
  );
});

export { InsertEmail };
//
// export function InsertEmail(props: Props) {
//
//
// }
