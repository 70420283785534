import { CartItem } from "./CartItem";
import { OrderDetailItem } from "../../Order/OrderDetailItem";
import { OrderDetailItemVariation } from "../../Order/OrderDetailItemVariation";
import { OrderDetailItemVariationChoice } from "../../Order/OrderDetailItemVariationChoice";

export class CartItemToOrderDetailItemMapper {
  toOrderDetailItem(ci: CartItem) {
    return new OrderDetailItem(
      ci.getProduct().name,
      ci.getQty(),
      ci.getUnitPrice(),
      ci.getOptionsGroupedByModifier().map((o) => {
        return new OrderDetailItemVariation(
          o.modifierName,
          o.options.map((o) => {
            return new OrderDetailItemVariationChoice(
              o.choiceName,
              o.choicePrice,
              o.choiceQty,
            );
          }),
        );
      }),
      ci.id,
    );
  }
}
