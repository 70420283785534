import React from "react";
import ReactDOM from "react-dom";
import { WarningIcon } from "../ui/WarningIcon";
import { useToChooseDeliveryTimeScreen } from "../Delivery/useToChooseDeliveryTimeScreen";
import { useCurrentRistopubId } from "../useCurrentRistopubId/useCurrentRistopubId";

type PropTypes = {
  visible?: boolean;
  onClose?: () => void;
};

export function CantOrderForPastDeliveryDateModal(props: PropTypes) {
  const { toChooseDeliveryTimeScreen } = useToChooseDeliveryTimeScreen();
  const { ristopubId } = useCurrentRistopubId();
  function toChooseTime() {
    toChooseDeliveryTimeScreen(ristopubId, true);
  }

  return props.visible
    ? ReactDOM.createPortal(
        <div
          className={
            "animated fadeIn fixed z-50 inset-0 bg-black bg-opacity-30 flex items-center justify-center p-2"
          }
        >
          <div className={"bg-white rounded p-4 animated fadeInUp "}>
            <div className={"flex flex-row"}>
              <div
                className={
                  "rounded-full bg-red-200 p-2 text-red-500 flex-shrink-0 mr-4 mb-auto"
                }
              >
                <WarningIcon />
              </div>

              <div className={"flex flex-col"}>
                <h3 className={"text-xl font-bold"}>
                  Hai inserito una data passata
                </h3>
                <p className={"text-gray-600"}>
                  Purtroppo non possiamo consegnarti un ordine nel passato 😔
                  <br />
                </p>
              </div>
            </div>

            <div className={"flex flex-col items-center justify-center"}>
              <button
                onClick={toChooseTime}
                className={
                  "rounded-full px-6 font-bold leading-none py-2 bg-swippy-orange text-white"
                }
              >
                Modifica data
              </button>
              <button
                onClick={props.onClose}
                className={"border-0 text-gray-600 mt-3"}
              >
                Chiudi
              </button>
            </div>
          </div>
        </div>,
        document.body,
      )
    : null;
}
