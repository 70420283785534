import { Spin } from "antd";

import React from "react";
import { LoadingIcon } from "../ui/LoadingIcong";

type PropTypes = {
  size?: number;
};
export function Loading(props: PropTypes) {
  return <Spin indicator={<LoadingIcon size={props.size || 55} />} />;
}
