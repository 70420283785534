import { useHistory } from "react-router-dom";
import { useQueryParams } from "../useQueryParams/useQueryParams";
import { preserveInPlaceQueryParamIfSet } from "../RistopubMenu/preserveInPlaceQueryParamIfSet";

export function useToInStructureOrderDetailScreen() {
  const history = useHistory();
  const query = useQueryParams();

  function toInStructureOrderDetailScreen(
    cartId: string,
    ristopubId: string,
    tableName: string,
    replace: boolean = false,
  ) {
    const path = preserveInPlaceQueryParamIfSet(
      `/ristopub/${ristopubId}/table/${tableName}/order/${cartId}?fromInStructureMenu=1`,
      query,
    );

    if (replace) history.replace(path);
    else history.push(path);
  }

  return {
    toInStructureOrderDetailScreen,
  };
}
