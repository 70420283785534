import { RistopubTakeawaySlot } from "./RistopubTakeawaySlot";
import moment, { Moment } from "moment-timezone";

type CreateRistopubInput = {
  id: string;
  name: string;
  ownerStripeAccountId?: string;
  featuredImageSrc?: string;
  cutoffInMinutes: number;
  takeawaySlots?: RistopubTakeawaySlot[];

  enableInAppPayments?: boolean;
  enableDelivery?: boolean;
  enableTakeaway?: boolean;
  enableCashPayments?: boolean;
  minimumDeliveryOrderValue?: number;
  minimumTakeawayOrderValue?: number;
  minimumOrderValueForFreeShipping?: number;
};

export class Ristopub {
  private constructor(
    public id: string,
    public name: string,
    private takeawaySlots: RistopubTakeawaySlot[] = [],
    private cutoffInMinutes: number,

    public readonly enableInAppPayments?: boolean,
    public readonly enableCashPayments?: boolean,
    public readonly enableDelivery?: boolean,
    public ownerStripeAccountId?: string,
    public minimumDeliveryOrderValue?: number,
    public minimumTakeawayOrderValue?: number,
    public minimumOrderValueForFreeShipping?: number,
    public readonly featuredImageSrc?: string,
    public readonly enableTakeaway?: boolean,
  ) {}

  public isBefore(date: Moment) {
    return date.isBefore(
      moment().subtract(this.cutoffInMinutes || 0, "minutes"),
    );
  }

  public getTakeawaySlots() {
    return this.takeawaySlots;
  }

  public static create({
    name,
    id,
    ownerStripeAccountId,
    enableDelivery,
    minimumDeliveryOrderValue,
    minimumTakeawayOrderValue,
    minimumOrderValueForFreeShipping,
    featuredImageSrc,
    enableTakeaway,
    takeawaySlots,
    cutoffInMinutes,
    enableCashPayments,
    enableInAppPayments,
  }: CreateRistopubInput) {
    return new Ristopub(
      id,
      name,
      takeawaySlots || [],
      cutoffInMinutes,
      enableInAppPayments,
      enableCashPayments,
      enableDelivery,
      ownerStripeAccountId,
      minimumDeliveryOrderValue,
      minimumTakeawayOrderValue,
      minimumOrderValueForFreeShipping,
      featuredImageSrc,
      enableTakeaway,
    );
  }

  public getCutoffInMinutes() {
    return this.cutoffInMinutes;
  }
}
