import React from "react";
import { Order } from "../Order";
import { OrderStatusChangeDateActions } from "./OrderStatusChangeDateActions";
import { Success } from "../../ui/Success";
import { Info } from "../../ui/Info";
import { useCurrentRistopub } from "../../Ristopub/useCurrentRistopub";
import { Warning } from "../../ui/Warning";

type PropTypes = {
  order: Order;
};

export function OrderStatusAffix(props: PropTypes) {
  const { ristopub } = useCurrentRistopub();

  function renderInfoBox() {
    if (props.order.isAccepted() || props.order.isPaid()) {
      return (
        <Success
          title={props.order.formatStatus()}
          copy={
            props.order.selfPickup ? (
              <p className={"text-xs"}>
                Quando il tuo ordine sarà pronto riceverai un messaggio 😊
              </p>
            ) : undefined
          }
        />
      );
    } else if (props.order.isInProposedDateChangeState()) {
      return <OrderStatusChangeDateActions order={props.order} />;
    } else if (props.order.isPending()) {
      return (
        <Info
          title={props.order.formatStatus()}
          copy={
            <p className={"text-xs"}>
              {ristopub?.name} deve ancora confermare il tuo ordine
            </p>
          }
        />
      );
    } else if (props.order.isRejected() || props.order.isCancelled()) {
      return <Warning title={props.order.formatStatus()} />;
    } else {
      return <Info title={props.order.formatStatus()} />;
    }
  }

  const Component = (
    <div className={"p-2  bottom-0 left-0 right-0 max-w-md ml-auto mr-auto"}>
      {renderInfoBox()}
    </div>
  );

  return (
    <>
      {React.cloneElement(Component, {
        style: {
          visibility: "hidden",
          position: "static",
        },
      })}
      {React.cloneElement(Component, {
        style: {
          position: "fixed",
        },
      })}
    </>
  );
}
